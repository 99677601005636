import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import {styled} from '@mui/material/styles';
import React from 'react';
import {useSelector} from 'react-redux';
import {theme} from '../../styles/theme';
import {AppState, UserState, UserStatus} from '../../types/redux';
import {AppFooter} from '../AppFooter/AppFooter';
import {AppNavigation} from '../AppNavigation/AppNavigation';
import {NotificationList} from '../Notification/Notification';

const ToolbarOffset = styled('div')(({theme}) => theme.mixins.toolbar);

type AppProps = {
    children: React.ReactNode;
};

export const AppLayout: React.FC<AppProps> = ({children}) => {
    const user = useSelector<AppState, UserState>(state => state.user);
    const isLoggedIn = user.status === UserStatus.LOGGED_IN;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline />
            <AppNavigation />
            <NotificationList />
            <Box
                component={'main'}
                sx={{
                    flexGrow: 1,
                    padding: theme.spacing(3),
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                {/* mobile app bar placeholder */}
                {isLoggedIn && (
                    <Hidden smUp implementation="js">
                        <ToolbarOffset />
                    </Hidden>
                )}
                {/* page content */}
                <Box sx={{flexGrow: 1}}>{children}</Box>
                {/* app footer */}
                <AppFooter />
            </Box>
        </Box>
    );
};
