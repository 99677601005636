import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

export interface ProtectedRouteProps {
    isAuthenticated: boolean; // is user logged in
    authenticationPath: string; // path to login view
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    if (!props.isAuthenticated) {
        return <Navigate to={{pathname: props.authenticationPath}} />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
