import gql from 'graphql-tag';

export const GQ_RETRIEVE_RESORTS = gql`
    query retrieveResorts($first: Int, $after: String, $last: Int, $before: String) {
        resorts(first: $first, after: $after, last: $last, before: $before) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    locale
                    title
                    key
                    doNotList
                }
            }
        }
    }
`;
