import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import {styled} from '@mui/material/styles';
import React from 'react';
import {theme} from './theme';

type FormLabelProps = {
    component?: React.ElementType;
};
export const StyledFormLabel = styled(FormLabel)<FormLabelProps>(({theme}) => ({
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
}));

export const StyledIconButton = styled('span')(() => ({
    display: 'inline-block',
    padding: '0 4px',
    cursor: 'pointer',
}));

export const StyledDialogButton = styled(Button)(() => ({
    margin: theme.spacing(0, 2, 2, 0),
}));
