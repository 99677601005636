export const ROUTE_ARTICLE_SEARCH = '/article-search';
export const ROUTE_ARTICLE = '/article';
export const ROUTE_USERS = '/users';
export const ROUTE_USER = '/user';
export const ROUTE_APPLICATION = '/application';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_AGB = '/agb';
export const ROUTE_IMPRINT = '/impressum';
export const ROUTE_SHOPPING_CART = '/shopping-cart';
export const ROUTE_PURCHASES = '/purchases';
export const ROUTE_FAQ = '/faq';
export const ROUTE_HOME = '/';

export const SESSION_KEY_DEBUG = 'debug';
export const SESSION_KEY_APP_STATE = 'appState';
export const LOCAL_KEY_TOKEN = 'token';
