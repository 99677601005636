import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export function DeleteIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={23} height={24} {...props}>
            <path d="M17.137 3.75l-1.593-2.658A2.248 2.248 0 0 0 13.613 0H8.887c-.792 0-1.523.412-1.93 1.092L5.361 3.75H1.125C.502 3.75 0 4.252 0 4.875v.563C0 5.747.253 6 .563 6h.768l1.482 15.956A2.251 2.251 0 0 0 5.053 24h12.399a2.251 2.251 0 0 0 2.24-2.044L21.17 6h.768c.31 0 .563-.253.563-.563v-.562c0-.623-.502-1.125-1.125-1.125h-4.238zm-8.25-1.5h4.726l.9 1.5H7.986l.9-1.5zm8.56 19.5H5.053L3.591 6h15.314l-1.458 15.75z" />
        </SvgIcon>
    );
}

export function EditIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={24} {...props}>
            <path d="M23.122 2.637L21.363.88A2.99 2.99 0 0 0 19.242 0a2.99 2.99 0 0 0-2.121.878L13.629 4.37 12 6 .602 17.395.007 22.75a1.125 1.125 0 0 0 1.242 1.243l5.35-.591L18.001 12l1.628-1.628 3.492-3.492a3 3 0 0 0 0-4.243zM5.566 21.253l-3.167.35.353-3.174L13.59 7.59l1.454-1.454 2.82 2.819-1.455 1.454L5.566 21.253zM21.53 5.288l-2.075 2.076-2.82-2.819 2.077-2.076a.747.747 0 0 1 1.06 0l1.758 1.759a.75.75 0 0 1 0 1.06z" />
        </SvgIcon>
    );
}

export function ArticlePreviewIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={18} height={24} viewBox="0 0 18 24" {...props}>
            <path d="M13.5 11.625v1.313c0 .309-.253.562-.563.562H5.064a.564.564 0 0 1-.563-.563v-1.312c0-.31.253-.563.563-.563h7.875c.309 0 .562.254.562.563zM12.937 15H5.064a.564.564 0 0 0-.563.563v1.312c0 .31.253.563.563.563h7.875c.309 0 .562-.254.562-.563v-1.313a.564.564 0 0 0-.563-.562zM18 6.183V21.75A2.25 2.25 0 0 1 15.75 24H2.25A2.25 2.25 0 0 1 0 21.75V2.25A2.25 2.25 0 0 1 2.25 0h9.567c.595 0 1.167.24 1.59.66l3.932 3.934c.422.417.661.994.661 1.589zm-6-3.75V6h3.567L12 2.433zm3.75 19.317V8.25h-4.875A1.122 1.122 0 0 1 9.75 7.125V2.25h-7.5v19.5h13.5z" />
        </SvgIcon>
    );
}

export function ArticleDownloadIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={18} height={24} viewBox="0 0 18 24" {...props}>
            <path
                d="M10 10.587v5.09l1.5-1.477 1.404 1.426-3.202 3.153a1 1 0 0 1-1.456-.052l-2.96-3.028 1.429-1.398L8 15.615v-5.028h2zm7.336-5.994L13.403.66A2.25 2.25 0 0 0 11.815 0H2.25A2.257 2.257 0 0 0 0 2.254V21.75A2.25 2.25 0 0 0 2.25 24h13.497A2.253 2.253 0 0 0 18 21.75V6.186c0-.595-.242-1.171-.664-1.593zm-5.338-2.156l3.566 3.566h-3.566V2.437zM15.75 21.75H2.25V2.254h7.498v4.874c0 .624.502 1.125 1.125 1.125h4.877V21.75z"
                fillRule="nonzero"
            />
        </SvgIcon>
    );
}
