import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import {StyledContainerWrapper} from '../../styles/simplePage';

export const FaqPage: React.FC = () => {
    return (
        <StyledContainerWrapper>
            <Grid container>
                <Grid item xs={12}>
                    <h1>FAQ</h1>
                    <h2>Wie kann ich als RedakteurIn den Marketplace der RiffReporter nutzen?</h2>
                    <Typography variant="body1" gutterBottom>
                        Sie können über den Marketplace nach Beiträgen suchen, die zu Ihrem redaktionellen Angebot
                        passen, diese zu Zwecken der internen Diskussion herunterladen und Beiträge kaufen und
                        exportieren. Im Normalfall erwerben Sie ein einfaches Nutzungsrecht, aber auch der Erwerb von
                        Rechten zur Erstveröffentlichung und von Exklusivrechten ist möglich.
                    </Typography>
                    <h2>Was bedeutet „einfaches Nutzungsrecht”?</h2>
                    <Typography variant="body1" gutterBottom>
                        Grundsätzlich können Sie in unserem Marketplace eine Zweitveröffentlichung eines Beitrages
                        erwerben. Das bedeutet, dass der Artikel bereits auf RiffReporter erschienen ist. Sie erwerben
                        ein einfaches Nutzungsrecht zur einmaligen, zeitlich unbegrenzten Verwendung des Beitrages. Sie
                        erwerben ausdrücklich keine Rechte für die Weiterverbreitung auf anderen Medien oder den
                        Weiterverkauf.
                    </Typography>
                    <h2>Was bedeutet „Erstveröffentlichung” und „Exklusivveröffentlichung”?</h2>
                    <Typography variant="body1" gutterBottom>
                        Ist der Artikel noch nicht bei RiffReporter erschienen, ist es gegen ein höheres Entgelt
                        möglich, Nutzungsrechte für eine eine Erstveröffentlichung oder eine Exklusivveröffentlichung zu
                        erwerben, sofern die AutorInnen dies für einen Beitrag anbieten. Bei einer Erstveröffentlichung
                        erscheint der Artikel zuerst in Ihrem Medium. Nach Ablauf der Frist, die im Marketplace
                        angegeben ist, kann der Beitrag auch bei RiffReporter und später auch bei anderen Medien
                        erscheinen.
                        <br />
                        Bei einer Exklusivveröffentlichung erwerben Sie das alleinige Veröffentlichungsrecht. Der
                        Artikel wird dann weder bei RiffReporter erscheinen noch einem anderen Medium angeboten. Das
                        exklusive Nutzungsrecht endet nach 365 Tagen und wird dann zu einem einfachen Nutzungsrecht.
                        Nach Ablauf dieser Frist können AutorInnen Nutzungsrechte auch wieder an andere Publisher
                        vergeben.
                    </Typography>
                    <h2>Wie wird die Bezahlung abgewickelt?</h2>
                    <Typography variant="body1" gutterBottom>
                        Sie bekommen nach Kauf des Artikels eine Rechnung an die im Profil hinterlegte Rechnungsadresse.
                        Das Honorar ist innerhalb von 14 Tagen zahlbar.
                    </Typography>
                    <h2>Kann ich Texte herunterladen, bevor ich sie kaufe?</h2>
                    <Typography variant="body1" gutterBottom>
                        Ja, das ist Ihnen für redaktionelle Beratungszwecke erlaubt, etwa zur Diskussion in einer
                        Themenplanungsrunde. Bitte stellen Sie aber sicher, dass die Empfänger z.B. eines PDF wissen,
                        dass die Nutzung und Weitergabe ausschließlich darauf limitiert ist.
                    </Typography>
                    <h2>Kann ich die Texte inhaltlich bearbeiten?</h2>
                    <Typography variant="body1" gutterBottom>
                        Änderungen, die Sinn oder Aussage des Artikels betreffen, sind nicht erlaubt. Unsere Beiträge
                        sind im Gegensatz z.B. zu Agenturartikeln kein „Rohmaterial”, sondern eigenständige Werke, deren
                        Integrität für Genossenschaft und AutorInnen von großer Bedeutung ist. Diese Integrität der
                        Werke ist auch die Grundlage der Zusammenarbeit mit unseren Verlagspartnern. Sinnerhaltende
                        Aktualisierungen in begrenztem Umfang sind möglich. In Zweifelsfällen bitten wir Sie um
                        rechtzeitige Übermittlung des bearbeiteten Beitrags zur Rücksprache vor der Veröffentlichung
                        (Email an info@riffreporter.de).
                    </Typography>
                    <h2>Kann ich inhaltliche Überarbeitungen anfordern?</h2>
                    <Typography variant="body1" gutterBottom>
                        In der Startphase unseres Projekts kaufen Sie die Artikel wie gesehen und auch in der
                        publizierten Länge. Auf der Grundlage von Feedback werden wir entscheiden, ob wir honorierte
                        Überarbeitungen in der Beta-Version des „Marketplace” ermöglichen.
                    </Typography>
                    <h2>Kann ich die Texte kürzen?</h2>
                    <Typography variant="body1" gutterBottom>
                        Ja. Kürzungen sind bis zu einem Anteil von 25 Prozent des Beitrages ohne Rücksprache möglich.
                        Bei stärkeren Kürzungen muss die neue Version vor Publikation mit Angabe von eventuellen Fristen
                        an <a href="mailto:info@riffreporter.de">info@riffreporter.de</a> geschickt werden. Die AutorIn
                        ist berechtigt, bei der Kürzung entstandene inhaltliche Fehler zu korrigieren. Erfolgt keine
                        fristgerechte Antwort der AutorIn, kann die gekürzte Fassung genutzt werden. Bei Kürzungen ist
                        auf den Sinnerhalt des ursprünglichen Beitrages zu achten.
                    </Typography>
                    <h2>Wie gehe ich mit Text-Elementen um, die auf RiffReporter verweisen?</h2>
                    <Typography variant="body1" gutterBottom>
                        In der Testphase des Marketplace kann es vorkommen, dass Sie Marketing-Textelemente in den
                        Artikeln finden. Diese können Sie selbstverständlich löschen. In der Beta-Version werden diese
                        Elemente von uns im Vorfeld entfernt.
                    </Typography>
                    <h2>Wer sind die AutorInnen der Beiträge von RiffReporter?</h2>
                    <Typography variant="body1" gutterBottom>
                        Im Marketplace finden Sie Artikel von Mitgliedern der RiffReporter-Genossenschaft. Diese sind
                        professionelle JournalistInnen, die für viele verschiedene Medien berichten. Sie verpflichten
                        sich bei RiffReporter mit einem Kodex zur Einhaltung journalistischer Standards. Mehr zu den
                        AutorInnen finden Sie auf dieser{' '}
                        <a href="https://www.riffreporter.de/journalistische-mitglieder-genossenschaft/">
                            Übersichtsseite
                        </a>
                        . Benötigen Sie ein Foto der AutorIn, schreiben Sie bitte an info@riffreporter.de.
                    </Typography>
                    <h2>Was ist RiffReporter?</h2>
                    <Typography variant="body1" gutterBottom>
                        In der RiffReporter-Genossenschaft sind bereits mehr als 100 führende Journalistinnen und
                        Journalisten aus dem deutschsprachigen Raum zusammengeschlossen, die für große Verlage und
                        Sender zu wichtigen Themen wie Umwelt, Wissenschaft, Digitalisierung, Gesundheit und Kultur
                        arbeiten. Unsere Genossenschaft betreibt die Journalismusplattform{' '}
                        <a href="https://www.riffreporter.de">www.riffreporter.de</a>, vermittelt Beiträge an Verlage
                        und Sender und unterstützt Veranstalter dabei, geeignete JournalistInnen aus unseren Reihen als
                        Mitwirkende zu finden. Für unser innovatives Konzept haben wir u.a. den Grimme Online Award und
                        die Auszeichnung „Wissenschaftsjournalisten des Jahres” bekommen.
                    </Typography>
                    <h2>Wie wird die Qualität der Beiträge sichergestellt?</h2>
                    <Typography variant="body1" gutterBottom>
                        Die Mitglieder von RiffReporter sind professionelle Journalistïnnen, die sich mittels eines
                        Kodex zu journalistischen Standards verpflichten. RiffReporter hat einen Ethikausschuss, der die
                        Einhaltung des Kodex überwacht. Zudem lesen Riff-KollegInnen die Texte gegen, bevor diese bei
                        uns auf der Webseite online gehen.
                    </Typography>
                </Grid>
            </Grid>
        </StyledContainerWrapper>
    );
};
