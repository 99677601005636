import gql from 'graphql-tag';

const shoppingCartItemFields = `
    id
    price
    priceFormatted
    dateModified
    licenses {
        id
        key
        title
    }
    articleId
    purchaseb2bId
    firstPublication
    exclusivePublication
    validationResult {
        isValid
        errors
        message
    }
`;

const shoppingCartFields = `
    id
    state
    userId
    dateModified
    validationResult {
        isValid
        errors
        message
    }
    cartItems {
        ${shoppingCartItemFields}
    }
`;

export const GQ_RETRIEVE_SHOPPING_CART = gql`
    query retrieveShoppingCart($ignoreCache: Boolean, $role: [AuthenticationRoleEnum]) {
        user(ignoreCache: $ignoreCache, role: $role) {
            id
            firstName
            fullName
            openShoppingCart {
                ${shoppingCartFields}
            }
        }
    }
`;

export const GM_ADD_TO_SHOPPING_CART = gql`
    mutation addToShoppingCart(
        $shoppingCartId: ID!
        $articleId: ID!
        $firstPublication: Boolean
        $exclusivePublication: Boolean
        $licenses: [PublicationLicenseEnum]
    ) {
        addToShoppingCart(
            shoppingCartId: $shoppingCartId
            articleId: $articleId
            firstPublication: $firstPublication
            exclusivePublication: $exclusivePublication
            licenses: $licenses
        ) {
            ${shoppingCartItemFields}
        }
    }
`;

export const GM_UPDATE_SHOPPING_CART_ITEM = gql`
    mutation updateShoppingCartItem(
        $shoppingCartItemId: ID!
        $firstPublication: Boolean
        $exclusivePublication: Boolean
        $licenses: [PublicationLicenseEnum]
    ) {
        updateShoppingCartItem(
            shoppingCartItemId: $shoppingCartItemId,
            firstPublication: $firstPublication,
            exclusivePublication: $exclusivePublication,
            licenses: $licenses
        ) {
            ${shoppingCartItemFields}
        }
    }
`;

export const GM_DELETE_SHOPPING_CART_ITEM = gql`
    mutation deleteShoppingCartItem(
        $shoppingCartItemId: ID!
    ) {
        deleteShoppingCartItem(shoppingCartItemId: $shoppingCartItemId) {
            ${shoppingCartFields}
        }
    }
`;

export const GM_CHECKOUT_SHOPPING_CART = gql`
    mutation performCheckout($shoppingCartId: ID!) {
        performShoppingCartCheckout(shoppingCartId: $shoppingCartId)
    }
`;
