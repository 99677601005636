import gql from 'graphql-tag';

export const GQ_RETRIEVE_PURCHASES_B2B = gql`
    query retrievePurchasesB2B($first: Int, $after: String, $last: Int, $before: String, $ignoreCache: Boolean) {
        purchases {
            purchasesB2B(first: $first, after: $after, last: $last, before: $before, ignoreCache: $ignoreCache) {
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    cursor
                    node {
                        id
                        price
                        priceFormatted
                        datePaid
                        datePurchased
                        licenses {
                            id
                            key
                            title
                        }
                        state
                        userId
                        articleId
                        shoppingCartItemId
                        purchaseNumber
                    }
                }
            }
        }
    }
`;
