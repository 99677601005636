import {useQuery} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {useEffect, useState} from 'react';
import {PageInfo, Query} from '../../types/graphqlTypes';

/**
 * Pagination for connection types, starting with the first / the oldest entry
 * @param query
 * @param options
 * @param getConnectionFn
 * @param itemsPerPage
 */
export const useAscendingPaginatedConnectionQuery = (
    query: DocumentNode,
    options: any,
    getConnectionFn: (data: Query) => any,
    itemsPerPage = 5,
): any => {
    const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
    const [edges, setEdges] = useState<any | null>(null);
    const [cursorList, setCursorList] = useState<string[]>([]);
    const [isForwardEnabled, setIsForwardEnabled] = useState(false);
    const [isBackwardEnabled, setIsBackwardEnabled] = useState(false);

    const {loading, data, error} = useQuery(query, {
        ...options,
        variables: {
            ...options.variables,
            first: itemsPerPage,
            after: cursorList.length ? cursorList[cursorList.length - 1] : null,
        },
    });

    useEffect(() => {
        const connection = getConnectionFn(data);
        const info = connection?.pageInfo as PageInfo;
        setEdges(connection?.edges);
        setPageInfo(info);
        setIsForwardEnabled(!!cursorList.length);
        setIsBackwardEnabled(info?.hasNextPage);
    }, [data]);

    const handleForward = () => {
        if (cursorList.length) {
            // use previous end cursor as 'after' arg
            const cursorCopy = cursorList.slice();
            cursorCopy.pop();
            setCursorList(cursorCopy);
        }
    };

    const handleBackward = () => {
        if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
            // take end cursor as 'after' arg
            setCursorList([...cursorList, pageInfo.endCursor]);
        }
    };

    return {
        loading,
        error,
        edges,
        isForwardEnabled,
        isBackwardEnabled,
        handleForward,
        handleBackward,
    };
};

/**
 * Pagination for connection types, starting with the last / the newest entry
 * @param query
 * @param options
 * @param getConnectionFn
 * @param itemsPerPage
 */
export const useDescendingPaginatedConnectionQuery = (
    query: DocumentNode,
    options: any,
    getConnectionFn: (data: Query) => any,
    itemsPerPage = 5,
): any => {
    const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
    const [edges, setEdges] = useState<any | null>(null);
    const [cursorList, setCursorList] = useState<string[]>([]);
    const [isForwardEnabled, setIsForwardEnabled] = useState(false);
    const [isBackwardEnabled, setIsBackwardEnabled] = useState(false);

    const {loading, data, error} = useQuery(query, {
        ...options,
        variables: {
            ...options.variables,
            last: itemsPerPage,
            before: cursorList.length ? cursorList[cursorList.length - 1] : null,
        },
    });

    useEffect(() => {
        const connection = getConnectionFn(data);
        const info = connection?.pageInfo as PageInfo;
        setEdges(connection?.edges?.slice().reverse());
        setPageInfo(info);
        setIsForwardEnabled(!!cursorList.length);
        setIsBackwardEnabled(info?.hasPreviousPage);
    }, [data]);

    const handleForward = () => {
        if (cursorList.length) {
            // use previous start cursor as 'before' arg
            const cursorCopy = cursorList.slice();
            cursorCopy.pop();
            setCursorList(cursorCopy);
        }
    };

    const handleBackward = () => {
        if (pageInfo?.hasPreviousPage && pageInfo?.startCursor) {
            // take start cursor as 'before' arg
            setCursorList([...cursorList, pageInfo.startCursor]);
        }
    };

    return {
        loading,
        error,
        edges,
        isForwardEnabled,
        isBackwardEnabled,
        handleForward,
        handleBackward,
    };
};
