import {createInstance} from '@jonkoops/matomo-tracker-react';
import {MatomoInstance} from '@jonkoops/matomo-tracker-react/es/types';
import {useMatomo} from '@jonkoops/matomo-tracker-react/lib';
import {TrackEventParams, TrackPageViewParams, TrackSiteSearchParams} from '@jonkoops/matomo-tracker-react/lib/types';
import {log} from '../utils/logger';

let matomoInitialized = false;

export const isMatomoEnabled = (): boolean => matomoInitialized;

export function initMatomoTracking(): MatomoInstance {
    if (process.env.REACT_APP_MATOMO_BASE_URL && process.env.REACT_APP_MATOMO_SITE_ID) {
        matomoInitialized = true;

        // create instance
        return createInstance({
            urlBase: process.env.REACT_APP_MATOMO_BASE_URL ?? '',
            siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID ?? '0'),
            disabled: false,
            heartBeat: {
                active: false,
                seconds: 15,
            },
            linkTracking: true,
            configurations: {
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: 'POST',
            },
        });
    }

    return createInstance({
        urlBase: '',
        siteId: 0,
        disabled: true,
    });
}

/**
 * Custom hook that provides guarded matomo tracking functions
 */
export function useMatomoTracking(): {
    trackEvent: (params: TrackEventParams) => any;
    trackPageView: (params: TrackPageViewParams) => any;
    trackSearch: (params: TrackSiteSearchParams) => any;
} {
    const {trackEvent, trackPageView, trackSiteSearch} = useMatomo();

    return {
        trackEvent: (params: TrackEventParams) => {
            if (isMatomoEnabled()) {
                log('track event', params);
                trackEvent(params);
            }
        },
        trackPageView: (params: TrackPageViewParams) => {
            if (isMatomoEnabled()) {
                log('track page view', params);
                trackPageView(params);
            }
        },
        trackSearch: (params: TrackSiteSearchParams) => {
            if (isMatomoEnabled()) {
                log('track search', params);
                trackSiteSearch(params);
            }
        },
    };
}
