export const LIST_ARTICLES_INCREMENT = 'listArticlesIncrement';
export const LIST_ARTICLES_DECREMENT = 'listArticlesDecrement';
export const LIST_ARTICLES_RESET = 'listArticlesReset';

export const loadMoreArticles = (): Record<string, unknown> => ({
    type: LIST_ARTICLES_INCREMENT,
});

export const loadLessArticles = (): Record<string, unknown> => ({
    type: LIST_ARTICLES_DECREMENT,
});

export const resetLoadMoreArticles = (): Record<string, unknown> => ({
    type: LIST_ARTICLES_RESET,
});
