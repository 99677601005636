import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'react-redux';
import {NavLink} from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import {ProgressOverlay, ProgressState} from '../../components/ProgressOverlay/ProgressOverlay';
import {ROUTE_HOME} from '../../lib/constants';
import {applyForPublisher} from '../../lib/redux/actions/userActions';
import {useMatomoTracking} from '../../lib/tracking/matomo';
import {hasFormErrors} from '../../lib/utils/form';
import {log} from '../../lib/utils/logger';
import {StyledButton} from '../../styles/notAuthenticatedUser';
import {colors, theme} from '../../styles/theme';

interface ApplicationValues {
    name: string;
    email: string;
    publisherTypes: {key: string; selected: boolean}[];
}

const hasSelectedPublisherType = (types: {key: string; selected: boolean}[] | undefined) =>
    types === undefined ? false : types.filter(t => t.selected).length > 0;

export const ApplicationPage: React.FC = () => {
    const {t} = useTranslation();
    const [isApplicationDone, setApplicationDone] = useState(false);
    const store = useStore();
    const {trackEvent} = useMatomoTracking();

    const validationSchema = Yup.object({
        name: Yup.string().required(t('formValidation.required') ?? ''),
        email: Yup.string()
            .email()
            .required(t('formValidation.required') ?? ''),
        publisherTypes: Yup.array().test(
            'publisherTypesNotEmpty',
            t('application.mustSelectAPublisherType') ?? '',
            hasSelectedPublisherType,
        ),
    });

    // form submit handler
    const onSubmit = async (values: ApplicationValues) => {
        trackEvent({
            category: 'polymarket-application-form',
            action: 'click-event',
            name: 'Send ApplyForPublisher form',
        });
        const types = values.publisherTypes.filter(t => t.selected).map(t => t.key);
        log('handleSubmit application values', values.name, values.email, types);
        applyForPublisher(
            values.name,
            values.email,
            types,
        )(store.dispatch, store.getState).then(success => {
            setApplicationDone(success);
        });
    };

    const initialApplicationValues: ApplicationValues = {
        name: '',
        email: '',
        publisherTypes: [
            {key: t('application.publisherType.1'), selected: false},
            {key: t('application.publisherType.2'), selected: false},
            {key: t('application.publisherType.3'), selected: false},
            {key: t('application.publisherType.4'), selected: false},
            {key: t('application.publisherType.5'), selected: false},
            {key: t('application.publisherType.6'), selected: false},
            {key: t('application.publisherType.7'), selected: false},
            {key: t('application.publisherType.8'), selected: false},
            {key: t('application.publisherType.9'), selected: false},
        ],
    };

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues: initialApplicationValues,
        validationSchema,
        onSubmit,
    });

    if (hasFormErrors(errors)) {
        log('InternalForm errors', errors);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: '90vh',
                    textAlign: 'center',
                }}
            >
                <Grid item xs={12} sm={8} lg={6}>
                    <NavLink to={ROUTE_HOME}>
                        <img src={logo} alt="RiffReporter Logo" height="48" width="48" />
                    </NavLink>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12}>
                            <h1>{t('application.title')}</h1>
                            <p style={{paddingBottom: 12}}>{t('application.copy')}</p>
                            <h2>{t('application.subtitle')}</h2>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <TextField
                                onChange={handleChange}
                                error={!!errors.name}
                                value={values.name}
                                name="name"
                                label={t('application.name')}
                                type="text"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                onChange={handleChange}
                                error={!!errors.email}
                                value={values.email}
                                name="email"
                                label={t('userPublisherProfile.email')}
                                type="text"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                component="fieldset"
                                error={!!errors.publisherTypes}
                                sx={{
                                    margin: theme.spacing(2, 0, 1),
                                    textAlign: 'left',
                                }}
                            >
                                <FormLabel
                                    component="legend"
                                    sx={{
                                        margin: theme.spacing(1, 0),
                                        paddingTop: theme.spacing(2),
                                    }}
                                >
                                    {t('application.type')}
                                </FormLabel>
                                <FormGroup>
                                    {values.publisherTypes.map((type, i) => (
                                        <FormControlLabel
                                            key={type.key}
                                            control={
                                                <Checkbox
                                                    checked={type.selected}
                                                    onChange={handleChange}
                                                    name={`publisherTypes[${i}].selected`}
                                                />
                                            }
                                            label={type.key}
                                            sx={{color: errors.publisherTypes ? colors.red70 : 'inherit'}}
                                        />
                                    ))}
                                </FormGroup>
                                {!!errors.publisherTypes && (
                                    <FormHelperText>
                                        <>{errors.publisherTypes}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={hasFormErrors(errors)}
                            >
                                {t('application.send')}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ProgressOverlay
                isOpen={isApplicationDone}
                state={ProgressState.SUCCESS}
                message={t('application.doneMessage')}
            />
        </form>
    );
};
