import {styled} from '@mui/material/styles';

interface PageProps {
    maxWidth?: number;
}

export const StyledContainerWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'maxWidth',
})<PageProps>(({theme, maxWidth}) => ({
    margin: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 40,
        paddingBottom: 40,
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: 80,
        paddingRight: 80,
        maxWidth: maxWidth ?? 1100,
    },
}));
