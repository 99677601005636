import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export function UserIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={19} {...props}>
            <path d="M13.459 16.08l-.255 2.263a.594.594 0 0 0 .66.653l2.284-.252 5.171-5.118-2.689-2.66-5.171 5.113zm10.279-6.102l-1.421-1.406a.905.905 0 0 0-1.268 0l-1.567 1.551 2.692 2.66 1.568-1.55a.886.886 0 0 0-.004-1.255zm-15.342.71c2.985.003 5.408-2.394 5.404-5.348C13.797 2.434 11.34.004 8.404 0 5.419-.004 2.996 2.394 3 5.347c.004 2.906 2.46 5.337 5.396 5.34zm-.165-8.903c2.12-.097 3.863 1.629 3.765 3.726-.086 1.825-1.578 3.306-3.427 3.391C6.45 9 4.706 7.273 4.804 5.177 4.89 3.347 6.386 1.87 8.23 1.785zm-3.19 11.277c.547 0 1.435.594 3.36.594 1.938 0 2.808-.594 3.36-.594.625 0 1.207.186 1.706.494l1.29-1.277a5.02 5.02 0 0 0-2.997-.998c-1.076 0-1.594.594-3.36.594-1.766 0-2.28-.594-3.36-.594-2.782 0-5.04 2.234-5.04 4.987v.95C0 18.202.806 19 1.8 19h9.686c-.142-.542-.082-.754.034-1.782H1.8v-.95c0-1.766 1.455-3.206 3.24-3.206z" />
        </SvgIcon>
    );
}

export function SearchIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={24} {...props}>
            <path d="M23.835 21.979l-5.69-5.69a.556.556 0 0 0-.399-.165h-.619A9.723 9.723 0 0 0 19.5 9.75 9.748 9.748 0 0 0 9.749 0 9.748 9.748 0 0 0 0 9.75a9.748 9.748 0 0 0 9.75 9.749 9.723 9.723 0 0 0 6.374-2.372v.619c0 .15.061.29.164.398l5.69 5.69c.22.221.577.221.797 0l1.06-1.059a.563.563 0 0 0 0-.796zM9.75 17.249a7.498 7.498 0 0 1-7.5-7.5c0-4.143 3.356-7.5 7.5-7.5 4.143 0 7.5 3.357 7.5 7.5 0 4.144-3.357 7.5-7.5 7.5z" />
        </SvgIcon>
    );
}

export function CartIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={22} {...props}>
            <path d="M23 2.714H6.012L5.648.822A1.005 1.005 0 0 0 4.667 0H.5C.224 0 0 .228 0 .509v1.018c0 .28.224.509.5.509h3.343l2.9 15.085A2.73 2.73 0 0 0 6 19c0 1.499 1.194 2.714 2.667 2.714 1.472 0 2.666-1.215 2.666-2.714 0-.477-.123-.945-.357-1.357h6.048c-.234.412-.358.88-.357 1.357 0 1.499 1.194 2.714 2.666 2.714C20.806 21.714 22 20.5 22 19c0-.77-.315-1.463-.82-1.957l.043-.202c.136-.634-.339-1.234-.977-1.234H8.49l-.391-2.036h13.017c.47 0 .877-.333.977-.801l1.884-8.822c.135-.634-.34-1.234-.977-1.234zM8.667 20.018c-.552 0-1-.457-1-1.018a1.01 1.01 0 0 1 1-1.018c.551 0 1 .457 1 1.018a1.01 1.01 0 0 1-1 1.018zm10.666 0c-.551 0-1-.457-1-1.018a1.01 1.01 0 0 1 1-1.018c.552 0 1 .457 1 1.018a1.01 1.01 0 0 1-1 1.018zm.977-8.482H7.707L6.403 4.75h15.356l-1.45 6.786z" />
        </SvgIcon>
    );
}

export function HistoryIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={22} height={24} {...props}>
            <path d="M16.489 13.498H4.985a.38.38 0 0 0-.383.375v1.5a.38.38 0 0 0 .383.375h11.504a.38.38 0 0 0 .383-.375v-1.5a.38.38 0 0 0-.383-.375zM19.21.267l-2.085 1.467L15.276.43a2.338 2.338 0 0 0-2.689 0l-1.85 1.303L8.887.43a2.338 2.338 0 0 0-2.69 0l-1.85 1.303L2.262.267C1.313-.4 0 .262 0 1.4V22.6c0 1.115 1.299 1.809 2.262 1.134l2.085-1.472 1.85 1.303a2.338 2.338 0 0 0 2.69 0l1.85-1.303 1.85 1.303a2.338 2.338 0 0 0 2.689 0l1.85-1.303 2.085 1.467c.95.666 2.263.005 2.263-1.13V1.402c0-1.12-1.3-1.81-2.263-1.134zm-.038 20.66l-2.047-1.44-3.197 2.25-3.197-2.25-3.197 2.25-3.197-2.25-2.037 1.44V3.07l2.046 1.438 3.198-2.25 3.197 2.25 3.197-2.25 3.197 2.25 2.037-1.438v17.857zM16.489 8.249H4.985a.38.38 0 0 0-.383.374v1.5a.38.38 0 0 0 .383.375h11.504a.38.38 0 0 0 .383-.375v-1.5a.38.38 0 0 0-.383-.374z" />
        </SvgIcon>
    );
}

export function LogoutIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={19} {...props}>
            <path d="M4.5 0h3.937C8.747 0 9 .266 9 .592v1.184c0 .326-.254.592-.563.592H4.5c-1.242 0-2.25 1.061-2.25 2.369v9.474c0 1.307 1.008 2.368 2.25 2.368h3.937c.31 0 .563.266.563.592v1.184c0 .326-.254.592-.563.592H4.5c-2.484 0-4.5-2.121-4.5-4.736V4.737C0 2.122 2.016 0 4.5 0zm10.832.962l-.919.967a.607.607 0 0 0 .01.844l5.301 5.418H8.062c-.31 0-.562.266-.562.592v1.381c0 .326.253.593.562.593h11.662l-5.301 5.412a.616.616 0 0 0-.01.844l.92.967c.22.232.576.232.796 0l7.706-8.092a.615.615 0 0 0 0-.839L16.129.957a.547.547 0 0 0-.797.005z" />
        </SvgIcon>
    );
}

export function MenuIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon {...props}>
            <g fill="#505863" fillRule="nonzero">
                <path d="M19 16H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2zM17 9H1a1 1 0 1 1 0-2h16a1 1 0 0 1 0 2zM15 2H1a1 1 0 1 1 0-2h14a1 1 0 0 1 0 2z" />
            </g>
        </SvgIcon>
    );
}

export function FAQIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={22} {...props}>
            <path d="M21.333 6.786h-4V2.714C17.333 1.215 16.14 0 14.667 0h-12C1.194 0 0 1.215 0 2.714v8.822c0 1.499 1.194 2.714 2.667 2.714H4v2.205c0 .3.243.51.502.51.1 0 .203-.032.295-.101L8 15.018v1.268C8 17.785 9.194 19 10.667 19h4l4.536 2.614c.092.069.195.1.295.1.26 0 .502-.208.502-.51V19h1.333C22.806 19 24 17.785 24 16.286V9.5c0-1.5-1.194-2.714-2.667-2.714zM8.348 12.479L6 13.83v-1.617H2.667A.674.674 0 0 1 2 11.536V2.714c0-.374.3-.678.667-.678h12c.367 0 .666.304.666.678v8.822c0 .374-.299.678-.666.678h-5.86l-.46.265zM21.333 8.82c.368 0 .667.305.667.679v6.786c0 .374-.3.678-.667.678H18v1.617l-2.348-1.352-.458-.265h-4.527a.674.674 0 0 1-.667-.678V14.25h4.667c1.473 0 2.666-1.215 2.666-2.714V8.82h4zM9.044 9.08v1.35H7.865v-1.35h1.18zm.01-5.121c.227 0 .455.033.683.099a1.642 1.642 0 0 1 1.053.873c.114.228.171.504.171.828 0 .342-.07.628-.211.86-.141.23-.35.433-.626.607a3.856 3.856 0 0 1-.382.225 3.581 3.581 0 0 0-.356.207 1.02 1.02 0 0 0-.26.243.533.533 0 0 0-.1.324H7.838l.007-.203.02-.193c.018-.126.053-.248.104-.365a1.15 1.15 0 0 1 .22-.328c.096-.102.222-.195.378-.28l.31-.166a3.18 3.18 0 0 0 .379-.238c.123-.09.23-.194.32-.31a.626.626 0 0 0 .134-.392.552.552 0 0 0-.216-.464A.789.789 0 0 0 9 5.12a.725.725 0 0 0-.32.067 1.207 1.207 0 0 0-.251.162c-.075.063-.14.134-.194.212l-.144.207-.927-.648.073-.152c.156-.294.38-.529.67-.703a2.182 2.182 0 0 1 1.147-.306z" />
        </SvgIcon>
    );
}
