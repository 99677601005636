import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {colors, theme} from '../../../styles/theme';
import {ParagraphMultiMedia as DataType, ParagraphText, ParagraphTypeEnum} from '../../../types/graphqlTypes';

interface ParagraphMultiMediaProps {
    data: DataType;
}

export const ParagraphMultiMedia: React.FC<ParagraphMultiMediaProps> = ({data}) => {
    const {marketing, funding, items} = data;
    const {t} = useTranslation();

    if (marketing == true || funding == true) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                fontSize: 18,
                lineHeight: 1.8,
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                <Box
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: colors.turquoise50,
                        padding: theme.spacing(2),
                        paddingTop: theme.spacing(1),
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        '& h2': {
                            color: colors.turquoise50,
                        },
                        '& a': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    <p>
                        <em>
                            <strong>{t('article.multiMediaTitle')}</strong>
                        </em>
                    </p>
                    {items?.map((multiMediaItem, index) => {
                        const hasParagraphText = multiMediaItem?.paragraphs?.find(paragraph => {
                            return paragraph?.type === ParagraphTypeEnum.Text;
                        });
                        return hasParagraphText ? (
                            <Box key={index}>
                                {multiMediaItem?.paragraphs?.map((paragraph, index) => {
                                    return (
                                        paragraph?.type === ParagraphTypeEnum.Text && (
                                            <Box
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: (paragraph as ParagraphText)?.html,
                                                }}
                                                sx={{padding: 0, margin: 0}}
                                            />
                                        )
                                    );
                                })}
                            </Box>
                        ) : null;
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};
