import {MutationTuple, useMutation, useQuery} from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {ArticlePreviewIcon, DeleteIcon, EditIcon} from '../../assets/ShoppingCartIcons';
import {ConfirmDialog} from '../../components/ConfirmDialog/ConfirmDialog';
import {Preloader} from '../../components/Preloader/Preloader';
import {ProgressOverlay, ProgressOverlayProps, ProgressState} from '../../components/ProgressOverlay/ProgressOverlay';
import {CheckoutDialog} from '../../components/shoppingCart/CheckoutDialog/CheckoutDialog';
import {EditCartItemDialog} from '../../components/shoppingCart/EditCartItemDialog/EditCartItemDialog';
import {ROUTE_ARTICLE, ROUTE_PURCHASES} from '../../lib/constants';
import {getAuthors, GQ_RETRIEVE_ARTICLE_DETAIL} from '../../lib/services/polypublisher/article';
import {GQ_RETRIEVE_PURCHASES_B2B} from '../../lib/services/polypublisher/purchase';
import {
    GM_CHECKOUT_SHOPPING_CART,
    GM_DELETE_SHOPPING_CART_ITEM,
    GQ_RETRIEVE_SHOPPING_CART,
} from '../../lib/services/polypublisher/shoppingCart';
import {useMatomoTracking} from '../../lib/tracking/matomo';
import {decodeGlobalId, formatDateString, formatDateTimeString} from '../../lib/utils/formatString';
import {err, log} from '../../lib/utils/logger';
import {colors, theme} from '../../styles/theme';
import {
    Article,
    AuthenticationRoleEnum,
    LocaleEnum,
    Mutation,
    MutationDeleteShoppingCartItemArgs,
    MutationPerformShoppingCartCheckoutArgs,
    ShoppingCart,
    ShoppingCartItem,
} from '../../types/graphqlTypes';

const ShoppingCartItemComponent: React.FC<{
    item: ShoppingCartItem;
    shoppingCartId: string;
    handleDelete(id: string): void;
}> = ({item, shoppingCartId, handleDelete}) => {
    const {t} = useTranslation();
    const {data: articleDataRaw} = useQuery(GQ_RETRIEVE_ARTICLE_DETAIL, {
        variables: {id: item.articleId || '-1'},
    });
    const articleData = articleDataRaw?.article as Article;
    const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const {trackEvent} = useMatomoTracking();
    const authors = getAuthors(articleData);

    const priceArticle = articleData?.prices?.slice().shift();

    const handleToggleEditDialog = () => {
        if (!isEditDialogOpen) {
            trackEvent({
                category: 'polymarket-shoppingcart',
                action: 'click-event',
                name: 'Open Edit Dialog',
                value: decodeGlobalId(item.articleId)?.id,
            });
        }
        setEditDialogOpen(!isEditDialogOpen);
    };

    const handleToggleDeleteDialog = () => {
        if (!isConfirmDeleteOpen) {
            trackEvent({
                category: 'polymarket-shoppingcart',
                action: 'click-event',
                name: 'Open Delete Dialog',
                value: decodeGlobalId(item.articleId)?.id,
            });
        }
        setConfirmDeleteOpen(!isConfirmDeleteOpen);
    };

    const handleConfirmDelete = () => {
        trackEvent({
            category: 'polymarket-shoppingcart',
            action: 'click-event',
            name: 'Remove item from shopping cart',
            value: decodeGlobalId(item.articleId)?.id,
        });
        handleDelete(item.id);
        setConfirmDeleteOpen(false);
    };

    return articleData ? (
        <>
            <Paper
                sx={{
                    padding: theme.spacing(4),
                    marginBottom: theme.spacing(2),
                }}
            >
                <Grid container sx={{paddingBottom: theme.spacing(2)}}>
                    <Grid item md={12}>
                        <Typography variant="h6">
                            {articleData.preview?.headline ?? articleData.meta?.title ?? articleData.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {articleData.preview?.copy ?? articleData.meta?.description ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{marginTop: theme.spacing(2)}}>
                        <Typography variant="subtitle1">{t('article.articleDetails')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {authors.isMultiple ? t('article.authors') : t('article.author')}
                        </Typography>
                        <Typography variant="body1">{authors.namesFormatted ?? 'unbekannt'}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.characters')}
                        </Typography>
                        <Typography variant="body1">{articleData?.characters ?? t('article.noCharLength')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.datePublication')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.publication?.datePublication) ??
                                t('article.noDatePublication')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateModification')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.dateModified ?? articleData?.publication?.datePublication) ??
                                t('article.noModification')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.resort')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.resorts
                                ?.map(r => {
                                    // return !r?.doNotList ? r?.title : null
                                    return r?.title;
                                })
                                ?.join(', ') ?? t('article.noResort')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.vgwort')}
                        </Typography>
                        {articleData?.owner?.vgwort &&
                        articleData?.owner?.vgwort?.number &&
                        !articleData?.owner?.vgwort?.excluded ? (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.lastname
                                    ? articleData?.owner?.vgwort?.firstname + ' ' + articleData?.owner?.vgwort?.lastname
                                    : articleData?.owner?.fullName}
                                : {articleData?.owner?.vgwort?.number}
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.excluded
                                    ? t('article.vgwortExcluded')
                                    : t('article.vgwortNoData')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateEmbargo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.dateEmbargo ? (
                                <strong style={{color: colors.red70}}>
                                    {formatDateTimeString(articleData?.publication?.dateEmbargo)}
                                </strong>
                            ) : (
                                t('article.noDateEmbargo')
                            )}
                        </Typography>
                        {articleData?.publication?.allowOwnPrePublication && (
                            <Typography variant="body1">{t('article.allowOwnPrePublication')}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationInfo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.infoPublication ?? t('article.publicationInfoNoData')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationOther')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.otherPublication ?? t('article.publicationOtherNoData')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    spacing={2}
                >
                    <Grid item xs={12} sx={{marginTop: theme.spacing(2)}}>
                        <Typography variant="subtitle1">{t('purchase.purchaseProcessDetails')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationLicense')}
                        </Typography>
                        <Typography variant="body1" style={{paddingRight: '0.5rem'}}>
                            {item.licenses?.map(l => l?.title).join(', ') ?? 'keine Nutzungsrechte'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.specialPublicationRights')}
                        </Typography>
                        <Typography variant="body1" style={{paddingRight: '0.5rem'}}>
                            {item.exclusivePublication
                                ? t('article.exclusivePublication')
                                : item.firstPublication
                                  ? t('article.firstPublication')
                                  : t('article.none')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.priceFactor')}
                        </Typography>
                        {priceArticle?.priceFactor === null ? (
                            <Typography variant="body1">{t('article.priceFactorFixed')}</Typography>
                        ) : priceArticle?.priceFactor === 1 ? (
                            <Typography variant="body1">{t('article.priceFactorStandard')}</Typography>
                        ) : (
                            <Typography variant="subtitle1" color={'primary'}>
                                {(priceArticle?.priceFactor ?? 1) * 100 > 100
                                    ? '+ ' + ((priceArticle?.priceFactor ?? 1) * 100 - 100) + '%'
                                    : '- ' + (100 - (priceArticle?.priceFactor ?? 1) * 100) + '%'}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.price')}
                        </Typography>
                        <Typography variant="subtitle1" color={'primary'}>
                            {item.priceFormatted}
                        </Typography>
                    </Grid>
                </Grid>
                {!item.validationResult?.isValid && (
                    <Grid container sx={{paddingBottom: theme.spacing(2)}}>
                        <Typography>{item.validationResult?.message}</Typography>
                    </Grid>
                )}
                <Grid container justifyContent={'center'}>
                    <Tooltip title={t('action.delete') as string}>
                        <IconButton onClick={handleToggleDeleteDialog} color={'primary'} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('action.edit') as string}>
                        <IconButton onClick={handleToggleEditDialog} color={'primary'} size="large">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('shoppingCart.articlePreview') as string}>
                        <IconButton
                            component={Link}
                            to={`${ROUTE_ARTICLE}/${articleData.locale === LocaleEnum.DeDe ? 'de' : 'en'}/${
                                item.articleId
                            }`}
                            color={'primary'}
                            edge={'end'}
                            size="large"
                        >
                            <ArticlePreviewIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Paper>
            <ConfirmDialog
                open={isConfirmDeleteOpen}
                handleConfirm={handleConfirmDelete}
                handleCancel={handleToggleDeleteDialog}
                title={t('shoppingCart.confirmDeleteTitle')}
                message={t('shoppingCart.confirmDeleteMessage', {title: articleData.title})}
            />
            {/* TODO: dialog doesn't update correctly after editing cart item, therefor we fully add / remove it  */}
            {isEditDialogOpen && (
                <EditCartItemDialog
                    open={isEditDialogOpen}
                    shoppingCartId={shoppingCartId}
                    articleId={articleData.id}
                    articleTitle={articleData.title ?? ''}
                    cartItemData={item}
                    handleClose={handleToggleEditDialog}
                />
            )}
        </>
    ) : null;
};

export const ShoppingCartPage: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data: cartData, loading} = useQuery(GQ_RETRIEVE_SHOPPING_CART, {
        variables: {
            ignoreCache: true,
            role: [AuthenticationRoleEnum.Owner],
        },
    });
    const shoppingCart = cartData?.user?.openShoppingCart as ShoppingCart;
    const hasCartItems = shoppingCart?.cartItems && shoppingCart.cartItems.length > 0;
    const [deleteItem] =
        useMutation<MutationTuple<Mutation, MutationDeleteShoppingCartItemArgs>>(GM_DELETE_SHOPPING_CART_ITEM);
    const [checkout] =
        useMutation<MutationTuple<Mutation, MutationPerformShoppingCartCheckoutArgs>>(GM_CHECKOUT_SHOPPING_CART);
    const [isCheckoutEnabled, setCheckoutEnabled] = useState(false);
    const [isConfirmCheckoutOpen, setConfirmCheckoutOpen] = useState(false);
    const [isCheckoutOverlayOpen, setCheckoutOverlayOpen] = useState(false);
    // overlay while checkout in process
    const progressPropsLoading: ProgressOverlayProps = {
        state: ProgressState.LOADING,
        message: t('shoppingCart.checkoutInProgress'),
    };
    // overlay after checkout is done
    const progressPropsDone: ProgressOverlayProps = {
        state: ProgressState.SUCCESS,
        message: t('shoppingCart.checkoutDone'),
        handleClick(): void {
            navigate(ROUTE_PURCHASES);
        },
    };
    // overlay after checkout is done
    const progressPropsError: ProgressOverlayProps = {
        state: ProgressState.ERROR,
        message: t('shoppingCart.checkoutError'),
        handleClick(): void {
            setCheckoutOverlayOpen(false);
        },
    };
    const [progressProps, setProgressProps] = useState(progressPropsLoading);
    const {trackEvent} = useMatomoTracking();

    // enable / disable checkout button
    useEffect(() => {
        setCheckoutEnabled(shoppingCart?.validationResult?.isValid ?? false);
    }, [shoppingCart]);

    const handleToggleCheckoutDialog = (setOpen?: boolean) => {
        const newValue = setOpen ?? !isConfirmCheckoutOpen;
        if (newValue) {
            trackEvent({
                category: 'polymarket-shoppingcart',
                action: 'click-event',
                name: 'Open Checkout Dialog',
            });
        }
        setConfirmCheckoutOpen(newValue);
    };

    // delete cart item
    const handleDelete = (id: string) => {
        deleteItem({
            variables: {shoppingCartItemId: id},
        })
            .then(res => log('res', res))
            .catch(error => err('err', error));
    };

    // perform checkout after confirmation
    const handleCheckout = () => {
        trackEvent({
            category: 'polymarket-shoppingcart',
            action: 'click-event',
            name: 'Checkout shopping cart',
        });
        setConfirmCheckoutOpen(false);
        setProgressProps(progressPropsLoading);
        setCheckoutOverlayOpen(true);
        checkout({
            variables: {shoppingCartId: shoppingCart.id},
            refetchQueries: [
                {
                    query: GQ_RETRIEVE_SHOPPING_CART,
                    variables: {
                        ignoreCache: true,
                        role: [AuthenticationRoleEnum.Owner],
                    },
                },
                {query: GQ_RETRIEVE_PURCHASES_B2B, variables: {ignoreCache: true}},
            ],
        })
            .then(res => {
                // show success message and redirect to purchases
                log('res', res);
                setProgressProps(progressPropsDone);
                setTimeout(() => {
                    progressPropsDone.handleClick && progressPropsDone.handleClick();
                }, 3000);
            })
            .catch(error => {
                // show error message
                err('err', error);
                setProgressProps(progressPropsError);
            });
    };

    return (
        <div>
            <h1>{t('shoppingCart.title')}</h1>
            <div>
                {loading && <Preloader minHeight={200} />}
                {shoppingCart?.cartItems?.map(
                    item =>
                        !!item && (
                            <ShoppingCartItemComponent
                                item={item}
                                shoppingCartId={shoppingCart.id}
                                handleDelete={handleDelete}
                                key={item.id}
                            />
                        ),
                )}
                {!loading && !hasCartItems && (
                    <Typography
                        variant="h5"
                        color="textSecondary"
                        sx={{
                            padding: theme.spacing(5, 3),
                            textAlign: 'center',
                        }}
                    >
                        {t('shoppingCart.empty')}
                    </Typography>
                )}
            </div>
            <Grid container justifyContent={'center'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleToggleCheckoutDialog(true)}
                    disabled={!isCheckoutEnabled}
                    sx={{margin: theme.spacing(0, 1, 1, 0)}}
                >
                    {t('action.checkout')}
                </Button>
            </Grid>
            <ProgressOverlay isOpen={isCheckoutOverlayOpen} {...progressProps} />
            {isConfirmCheckoutOpen && (
                <CheckoutDialog
                    open={isConfirmCheckoutOpen}
                    handleClose={() => handleToggleCheckoutDialog(false)}
                    handleCheckout={handleCheckout}
                    cartData={shoppingCart}
                />
            )}
        </div>
    );
};
