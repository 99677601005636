import {$enum} from 'ts-enum-util';
import {LocaleEnum, OrderDirectionEnum, OrderTypeEnum, PublicationLicenseEnum} from '../../../types/graphqlTypes';
import {ArticleSearchAction, ArticleSearchState, RESORT_ALL} from '../../../types/redux';
import {SEARCH_ERROR, SEARCH_RESET_TERMS, SEARCH_SET_TERMS} from '../actions/searchArticleActions';

const initialState: ArticleSearchState = {
    hasError: false,
    searchTerm: '',
    dateFrom: '2016-01-01',
    dateTo: '2030-01-01',
    charactersMin: 0,
    charactersMax: 100000,
    resort: RESORT_ALL,
    // collect all PublicationLicenseEnum values and create LicenseSelection stubs
    licenses: $enum(PublicationLicenseEnum)
        .getValues()
        .map((k, i) => {
            return {
                id: i.toString(),
                selected: ['PRINT', 'WEB_FREE', 'WEB_PAYWALL'].includes(k),
                key: k,
            };
        }),
    orderType: OrderTypeEnum.Date,
    orderDirection: OrderDirectionEnum.Desc,
    localeEnum: LocaleEnum.DeDe,
    useBlacklist: true,
    useBlacklistType: 'marketplace',
};

export const searchArticleReducer = (
    state: ArticleSearchState = initialState,
    action: ArticleSearchAction,
): ArticleSearchState => {
    switch (action.type) {
        case SEARCH_SET_TERMS:
            return {hasError: false, ...action.payload};
        case SEARCH_RESET_TERMS:
            return initialState;
        case SEARCH_ERROR:
            return {...state, hasError: true};
        default:
            return state;
    }
};
