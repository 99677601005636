import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {StyledDialogButton} from '../../../styles/dialog';

export const ArticleDownloadDialog: React.FC<{open: boolean; articleId: string; handleClose(): void}> = ({
    open,
    articleId,
    handleClose,
}) => {
    const {t} = useTranslation();
    const exportBaseUrl = process.env.REACT_APP_POLYPUBLISHER_EXPORT_URL || '/articleexport';
    const {locale} = useParams<{locale: string}>();

    const handleDownloadPdf = (evt: React.MouseEvent) => {
        evt.preventDefault();
        window.open(`${exportBaseUrl}/${locale ?? 'de'}/${articleId}.pdf`, '_blank');
        handleClose();
    };

    const handleDownloadDoc = (evt: React.MouseEvent) => {
        evt.preventDefault();
        window.open(`${exportBaseUrl}/${locale ?? 'de'}/${articleId}.docx`, '_self');
        handleClose();
    };

    const handleDownloadMd = (evt: React.MouseEvent) => {
        evt.preventDefault();
        window.open(`${exportBaseUrl}/${locale ?? 'de'}/${articleId}.md`, '_self');
        handleClose();
    };

    const handleDownloadTxt = (evt: React.MouseEvent) => {
        evt.preventDefault();
        window.open(`${exportBaseUrl}/${locale ?? 'de'}/${articleId}.txt`, '_self');
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t('articleDownloadDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('articleDownloadDialog.description')}</DialogContentText>
                <StyledDialogButton color="primary" variant="contained" onClick={handleDownloadPdf}>
                    {t('articleDownloadDialog.pdf')}
                </StyledDialogButton>
                <StyledDialogButton color="primary" variant="contained" onClick={handleDownloadDoc}>
                    {t('articleDownloadDialog.doc')}
                </StyledDialogButton>
                <StyledDialogButton color="primary" variant="contained" onClick={handleDownloadMd}>
                    {t('articleDownloadDialog.md')}
                </StyledDialogButton>
                <StyledDialogButton color="primary" variant="contained" onClick={handleDownloadTxt}>
                    {t('articleDownloadDialog.txt')}
                </StyledDialogButton>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleClose} color="primary" variant="outlined" autoFocus>
                    {t('action.close')}
                </StyledDialogButton>
            </DialogActions>
        </Dialog>
    );
};
