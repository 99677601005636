import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {StyledContainerWrapper} from '../../styles/simplePage';

export const AgbPage: FC = () => {
    return (
        <StyledContainerWrapper>
            <Grid container>
                <Grid item xs={12}>
                    <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
                    <h2>
                        für die Nutzung von Beiträgen der AutorInnen von RiffReporter – die Genossenschaft für freien
                        Journalismus eG
                    </h2>
                    <h3>I. Geltungsbereich</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) finden Anwendung auf alle Verträge
                        zwischen RiffReporter – die Genossenschaft für freien Journalismus eG (im folgenden
                        „RiffReporter”), Buchtstraße 13, 28195 Bremen (Tel.: 0049 (0)421 2435 9394; E-Mail:{' '}
                        <a href="mailto:info@riffreporter.de">info@riffreporter.de</a>), Amtsgericht Charlottenburg GenR
                        819B, vertreten durch die Vorstände Tanja Krämer und Stefan Johannesberg, und ihren
                        Publisher-Vertragspartnern (im folgenden „Kunde“) über die kostenpflichtige Bereitstellung von
                        Inhalten in digitaler Form und die Vergabe von Nutzungsrechten an diesen Inhalten durch die
                        RiffReporter eG zu den unter Verwendungsarten genannten Zwecken. Entgegenstehende oder
                        abweichende Geschäftsbedingungen des Kunden werden nicht anerkannt. Dies gilt auch für den Fall,
                        dass von RiffReporter den AGB des Kunden nicht in jedem Einzelfall ausdrücklich widersprochen
                        wurde.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        (2) Die Einräumung von Nutzungsrechten an Kunden erfolgt im Auftrag und Namen der AutorInnen und
                        Mitwirkenden der Beiträge auf Grundlage des AutorInnenvertrags der RiffReporter eG in der
                        Fassung vom 24. Mai 2019.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {' '}
                        Darin räumen AutorInnen „der RiffReporter-Genossenschaft die Option ein, gegen ein gesondertes,
                        angemessenes Honorar mit Zustimmung oder auf Wunsch des Autors einfache Nutzungsrechte an dem
                        Material auch für andere Nutzungsarten zu erwerben oder an Dritte zu lizenzieren. Zusätzlich
                        eröffnet der „Marketplace” AutorInnen die Möglichkeit, Erstveröffentlichungsrechte und
                        Exklusivrechte zu definieren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Die Bezeichnung „Inhalte“ im Sinne dieser AGB umfasst ausschließlich die im Marketplace
                        hinterlegten Texte. Sie schließt Fotos und anderes Material aus dem gekauften Beitrag nicht mit
                        ein, sofern keine anderweitigen Regelungen getroffen wurden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.) Vertragspartner der RiffReporter eG ist stets der Kunde. Ist der Kunde nach dem
                        Vertragsinhalt berechtigt, die Inhalte weiteren Nutzern zur Verfügung zu stellen (z.B. durch
                        Einstellen in das Intranet), bleibt stets der Kunde gegenüber der RiffReporter eG für die
                        Einhaltung des vereinbarten Nutzungsumfangs auch durch seine eigenen Nutzer allein
                        verantwortlich.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Unter „Nutzer“ sind die Personen zu verstehen, denen der Kunde – je nach Art und Umfang der
                        im Einzelfall vereinbarten Verwendungsart – die Nutzung der Inhalte gestatten darf.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        6.) Der Verkauf von Nutzungsrechten erfolgt verbindlich über die RiffReporter-Plattform
                        „Marketplace” oder durch direkte Absprachen mit dem Vorstand der RiffReporter eG. Bei der
                        Registrierung wird der Kunde aufgrund seiner Medien-Kenndaten einer Publisher-Kategorie
                        zugeordnet. Ergeben sich wesentliche Änderungen an Kenndaten, sind diese umgehend der
                        RiffReporter eG mitzuteilen, so dass eine neue Zuordnung erfolgen kann.
                    </Typography>
                    <h3>II. Umfang der Rechteeinräumung</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Die Einräumung von Nutzungsrechten erfolgt für die beim Kauf ausgewählten Kategorien
                        (“Web-kostenfrei; Web-Bezahlschranke; Print; Radio, TV). Vor der Nutzung für weitere Kategorien
                        oder Anwendungen muss RiffReporter benachrichtigt und ggf. ein zusätzliches Nutzungsentgelt
                        entrichtet werden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Die RiffReporter eG vergibt, vorbehaltlich der Zahlung der vereinbarten Lizenzgebühr,
                        einfache, zeitlich unbegrenzte Nutzungsrechte, Erstveröffentlichungsrechte und exklusive
                        Nutzungsrechte. Exklusivität an Nutzungsrechten gilt für drei Monate. Anschließend bestehen nur
                        noch einfach Nutzungsrechte und RiffReporter eG und/oder der Autor/ die Autorin können die
                        Inhalte selbst oder anderweitig verwerten. Welche Rechte für einen Inhalt zur Verfügung stehen,
                        wird beim Kaufprozess im „Marketplace” definiert.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Erfolgt die Gewährung von Nutzungsrechten im Einzelfall nur befristet, sind Inhalte nach
                        Ablauf der vereinbarten Nutzungsdauer von Speichermedien des Kunden zu löschen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.) Zulässig ist ausschließlich die Nutzung der Inhalte in dem vereinbarten Umfang. Darüber
                        hinausgehende Nutzungen sind vom Kunden gesondert anzufragen und zu lizenzieren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Der Kunde ist nicht berechtigt, die Inhalte weiterzulizenzieren oder zu verkaufen, sofern
                        dies nicht anders vereinbart wurde. Eine Weiterlizensierung ohne Zustimmung und angemessene
                        Vergütung des Autors/der Autorin ist grundsätzlich ausgeschlossen. Erreichen den Kunden
                        Nutzungsanfragen Dritter, denen er nachkommen will, informiert er RiffReporter und den Autor/die
                        Autorin.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Der Kunde ist nicht berechtigt, die Inhalte weiterzulizenzieren oder zu verkaufen. Erreichen
                        ihn Nutzungsanfragen Dritter, verweist er diese an RiffReporter.
                    </Typography>
                    <h3>III. Bearbeitung der Inhalte</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Kunden, die rein journalistische Medien vertreten, dürfen die Inhalte im Rahmen
                        redaktioneller Überarbeitung verändern. Dies gilt insbesondere für Kürzungen oder
                        Aktualisierungen in begrenztem Umfang. Änderungen, die Sinn oder Aussage des Artikels betreffen,
                        sind nicht erlaubt.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Beiträge der AutorInnen von RiffReporter sind im Gegensatz z.B. zu Agenturartikeln kein
                        „Rohmaterial”, sondern eigenständige Werke, deren Integrität für Genossenschaft und AutorInnen
                        von großer Bedeutung ist. Diese Integrität der Werke ist auch die Grundlage der Zusammenarbeit
                        mit unseren Verlagspartnern. Bei Hinzufügungen oder Aktualisierungen durch Redakteure des Kunden
                        muss der Name oder das Kürzel des jeweiligen Redakteurs hinzugefügt werden. In Zweifelsfällen
                        übermittelt der Kunde den bearbeiteten Beitrag zur Rücksprache vor der Veröffentlichung per
                        Email an den Autor/die Autorin (E-Mail-Adresse im Impressum des Artikels auf der Webseite) und
                        an <a href="mailto:info@riffreporter.de">info@riffreporter.de</a>.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Redaktionelle Kürzungen sind journalistischen Medien bis zu einem Anteil von 25 Prozent des
                        Beitrages ohne Rücksprache möglich. Bei stärkeren redaktionellen Kürzungen muss die neue Version
                        vor Publikation mit Angabe von eventuellen Fristen an{' '}
                        <a href="mailto:info@riffreporter.de">info@riffreporter.de</a> geschickt werden. Die AutorIn ist
                        berechtigt, bei der Kürzung entstandene inhaltliche Fehler zu korrigieren. Erfolgt keine
                        fristgerechte Antwort der AutorIn, kann die gekürzte Fassung genutzt werden. Bei Kürzungen ist
                        immer auf den Sinnerhalt des ursprünglichen Beitrages zu achten.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.) Der Kunde hat keinen Anspruch auf Nachbearbeitung durch die AutorInnen der Inhalte. Wird
                        eine Nachbearbeitung (Kürzung oder inhaltliche Änderung) durch den Autor/die Autorin vom Kunden
                        gewünscht, so ist deren Ziel und eine Honorierung für den Arbeitsaufwand vor dem Kaufvorgang mit
                        dem Autor/der Autorin zu klären (Kontaktdaten im betreffenden Artikel auf der Webseite ganz
                        unten). Der Autor/die Autorin stellt dann den überarbeiteten Artikel neu zum vereinbarten Preis
                        im Marketplace ein und informiert den Kunden, der den überarbeiteten Beitrag dann im
                        Marketplace.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Kürzen journalistische Kunden Beiträge oder unterziehen sie einem normalen Redigat, so
                        begründet dies kein eigenständiges Nutzungsrecht. Die Autorin/der Autor kann die gekürzte und
                        redigierte Fassung des Beitrags unbegrenzt gemäß den eigenen Rechten weiterverwerten. Eingriffe
                        in den Text, die ein eigenes Urheberrecht des Redakteurs/der Redakteurin begründen würden, sind
                        ausnahmslos nicht gestattet.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        6.) Besondere Regeln für nicht-journalistische Kunden: Kunden von nicht-journalistischen Medien,
                        die z.B. NGOs, Stiftungen, Vereinen, Verbänden, Unternehmen, Institutionen, der Wissenschaft,
                        allgemein Kommunikation o.ä. zuzuordnen sind, dürfen Beiträge mit Ausnahme von eventuellen
                        Rechtschreibfehlern in der Vorlage grundsätzlich{' '}
                        <strong>
                            nicht verändern und nicht kürzen. Nachträgliche Änderungen oder Anpassungen des Textes sind
                            ausgeschlossen.
                        </strong>{' '}
                        Jede Kürzung des Textes muss mit dem Autor/der Autorin abgestimmt und von diesem/dieser gegen
                        ein zusätzliches Entgelt, das im Marketplace vor dem formalen Kauf eingestellt wird,
                        durchgeführt werden. Beauftragung und Austausch dazu erfolgt schriftlich mit dem
                        Autor/derAutorin der Emailadresse
                        <a href="mailto:info@riffreporter.de">info@riffreporter.de</a> in CC.{' '}
                        <strong>
                            Verstöße gegen diese Regel begründen urheber- und nutzungsrechtliche Schadensersatzansprüche
                            und führen zum Ausschluss aus dem Marketplace.
                        </strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        7.) Der Name des Autors/ der Autorin muss beim Kunden an üblicher Stelle genannt werden,
                        bevorzugt mit der Formulierung „Erika Musterfrau, RiffReporter“. Bei Online-Nutzung erfolgt an
                        geeigneter Stelle eine Verlinkung zu www.riffreporter.de.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        8.) Dem Kunden ist es auch in Marketing und Werbung untersagt, die Inhalte in einer Art und
                        Weise zu verwenden, die sinnentstellend ist oder von der bekannt ist bzw. der Kunde weiß oder
                        annehmen muss, dass sie eine politisch oder religiös extremistische und/oder
                        gewaltverherrlichende, pornografische und/oder anderweitig sittlich anstößige oder gegen die im
                        Grundgesetz verankerten Grundrechte gerichtete Ausrichtung hat.
                    </Typography>
                    <h3>IV. Verwendung von Fotos</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Der standardmäßige Kauf im Marketplace umfasst nur Nutzungsrechte an den Texten. Für
                        Bildmaterial von der Webseite riffreporter.de, das nicht von Autorinnen/Autoren selbst stammt,
                        kann grundsätzlich kein Nutzungsrecht erworben werden, da RiffReporter selbst nur über die
                        einfachen Nutzungsrechte verfügt und eine Weiterlizensierung etwa von Depositphotos oder der
                        Picture Alliance ausdrücklich verboten ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Nutzungsrechte zu erwerben ist möglich bei Bildmaterial, das der Autor/die Autorin selbst
                        angefertigt hat und für das er/sie über Urheberrechte und die Rechte zum Weiterverkauf von
                        Nutzungsrechten verfügt. Will der Kunde Nutzungsrechte an solchen Fotos erwerben, wendet er sich
                        vor dem Kauf des Artikels an den Autor/die Autorin mit CC an info@riffreporter.de, um einen
                        Preis zu vereinbaren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Der Kunde erwirbt vom Autor/von der Autorin immer nur einfache und unbefristete
                        Nutzungsrechte an dem Bildmaterial. Eine Weiterlizensierung oder ein Weiterverkauf von
                        Bildrechten durch den Kunden ist ausgeschlossen. Zuwiderhandlungen können zu urheberrechtlichen
                        Schadensersatzansprüchen führen.
                    </Typography>
                    <h3>V. Pflichten des Kunden</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Der Kunde haftet für alle aus der Nutzung der lizenzierten Inhalte resultierenden
                        Rechtsverletzungen von Rechten Dritter, insbesondere der Urheber-, Marken- und Eigentumsrechte.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Der Kunde verpflichtet sich, mit seinen Nutzern die mit ihm vereinbarten
                        Nutzungsbestimmungen und die Einhaltung der unter Ziffer II genannten Bedingungen zu
                        vereinbaren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Der Kunde ist verpflichtet, RiffReporter eG unverzüglich mitzuteilen, wenn sich die
                        ursprünglich gewählte Nutzerzahl, Nutzungsdauer, bzw. Auflagenhöhe der Publikation erhöht hat.
                        In diesem Fall kann die RiffReporter eG für zukünftige Käufe des Kunden neue Preise festlegen.
                    </Typography>
                    <h3>VI. Kosten und Zahlungsbedingungen</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Kunden hinterlegen ihre Daten und die Adresse zur Rechnungsstellung vor der Nutzung des
                        Marketplace im Bereich Profil. Es gelten die zum Zeitpunkt des Vertragsschlusses gültigen
                        Preise. Einzelheiten sind der Preisliste zu entnehmen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Die Rechnungsstellung über die Lizenzgebühren für die Bereitstellung und Nutzung der Inhalte
                        erfolgt nach Auftragserteilung durch den Kunden. Eine Rückgabe (beispielsweise bei
                        Nicht-Ausübung eines Rechts) ist ausgeschlossen. Der Rechnungsbetrag ist zahlbar innerhalb von
                        14 Tagen nach Rechnungsstellung.
                    </Typography>
                    <h3>VII. Widerrufsrecht</h3>
                    <Typography variant="body1" gutterBottom>
                        <strong>Widerrufsbelehrung/Widerrufsrecht</strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn Sie registrierter <strong>Firmenkunde</strong> sind, kommt mit dem Klicken des Kauf-Knopfes
                        ein Kaufvertrag für den betreffenden Artikel zustande, der grundsätzlich nicht widerrufen werden
                        kann. Begründete Ausnahmefälle sind der RiffReporter eG mitzuteilen, die sich eine Entscheidung
                        darüber vorbehält, ob ein Widerruf akzeptiert wird.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn Sie <strong>Verbraucher</strong> sind, haben Sie das Recht, binnen vierzehn Tagen ohne
                        Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem
                        Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in
                        Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns RiffReporter –
                        die Genossenschaft für freien Journalismus eG (im folgenden „RiffReporter”), Buchtstraße 13,
                        28195 Bremen (Tel.: 0049 (0)421 2435 9394; E-Mail:{' '}
                        <a href="mailto:info@riffreporter.de">info@riffreporter.de</a>), Amtsgericht Charlottenburg GenR
                        819B, vertreten durch die Vorstände Tanja Krämer und Stefan Johannesberg, mittels einer
                        eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren
                        Entschluss, diesen Vertrag zu widerrufen, informieren.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie können dafür das Muster-Widerrufsformular verwenden, das Sie unter dem beigefügten Link
                        finden. Die Verwendung des Formulars ist jedoch nicht vorgeschrieben. Zur Wahrung der
                        Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
                        Ablauf der Widerrufsfrist absenden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        https://www.riffreporter.de/widerrufsrecht/
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail)
                        eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln. Zur Wahrung der
                        Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
                        Ablauf der Widerrufsfrist absenden.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Folgen des Widerrufs</strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Wenn Sie diesen Vertrag als Verbraucher widerrufen, haben wir Ihnen alle Zahlungen, die wir von
                        Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
                        sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
                        günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen
                        ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns
                        eingegangen ist.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
                        Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes
                        vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können
                        die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den
                        Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
                        Zeitpunkt ist
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben, handelt es sich um Waren, diese unverzüglich und in jedem Fall spätestens binnen
                        vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an
                        uns,
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        RiffReporter eG, Buchtstraße 13, 28195 Bremen,{' '}
                        <a href="mailto:info@riffreporter.de">info@riffreporter.de</a>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist
                        von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie
                        müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen
                        zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen
                        Umgang mit ihnen zurückzuführen ist.
                    </Typography>
                    <h3>VIII. Gewährleistung und Haftung</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Die RiffReporter eG übernimmt keine Gewähr dafür, dass die zur Verfügung gestellten Inhalte
                        inhaltlich richtig oder für einen bestimmten Zweck geeignet sind. Insoweit ist jegliche Haftung
                        der RiffReporter eG ausgeschlossen.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Die RiffReporter eG haftet nicht für solche Schäden und/oder die Kosten der
                        Rechtsverteidigung, die dem Kunden und/oder den weiteren Nutzern der Inhalte dadurch entstehen,
                        dass Dritte insbesondere, aber nicht nur, wettbewerbs-, presse- oder urheberrechtliche Ansprüche
                        oder Schutzrechte ausschließlich gegenüber dem Kunden und/oder den weiteren Nutzern der Inhalte
                        geltend machen. Soweit die RiffReporter eG, der Kunde und/oder die weiteren Nutzer jeweils
                        alleine, gemeinsam oder nebeneinander insbesondere, aber nicht nur, wettbewerbs-, presse- oder
                        urheberrechtlich in Anspruch genommen werden, trägt jeder von ihnen seinen Schaden(-santeil)
                        und/oder seine Rechtsverteidigungskosten (-quote) selbst.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Für alle übrigen Schäden, gleich woraus diese resultieren, haftet die RiffReporter eG
                        unbeschränkt bei Vorsatz oder grober Fahrlässigkeit der RiffReporter eG, ihrer gesetzlichen
                        Vertreter, Angestellten oder sonstigen Erfüllungsgehilfen. Dies gilt entsprechend im Falle der
                        Verletzung vor- oder nebenvertraglicher Pflichten, bei unerlaubter Handlung, bei Mangel- und
                        Mangelfolgeschäden sowie bei Verzug und Unmöglichkeit.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.) Bei leichter Fahrlässigkeit haftet die RiffReporter eG nur, soweit wesentliche
                        Vertragspflichten, also Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
                        überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf
                        („Kardinalpflichten“) verletzt werden und nur insoweit, als die Schäden vorhersehbar waren. Die
                        Haftung ist in diesen Fällen der Höhe nach begrenzt auf EUR 10.000 pro Schadensfall. Diese
                        Haftungsausschlüsse bzw. -beschränkungen gelten nicht bei Schäden aufgrund des Fehlens
                        abgegebener Zusicherungen, bei einer Haftung nach dem Produkthaftungsgesetz sowie bei Schäden
                        aufgrund der Verletzung des Lebens, des Körpers oder der Gesundheit.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Die vorstehend aufgeführten Haftungsausschlüsse bzw. -beschränkungen gelten gleichermaßen
                        bei Ansprüchen des Kunden und/oder der weiteren Nutzer gegenüber den gesetzlichen Vertretern der
                        RiffReporter eG sowie deren leitenden Angestellten und sonstigen Erfüllungsgehilfen.
                    </Typography>
                    <h3>IX. Sonstige Bestimmungen</h3>
                    <Typography variant="body1" gutterBottom>
                        1.) Ergänzend kommen die rechtlichen Grenzen, die sich aus dem Urheberrecht und allen sonstigen
                        anwendbaren gesetzlichen Vorschriften ergeben, zur Anwendung.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        2.) Die Nutzung der Inhalte erfolgt ausschließlich auf Grundlage dieser Geschäftsbedingungen,
                        sofern es keine andere schriftliche Vereinbarung mit dem Kunden gibt. Gibt es diese, so gelten
                        Regelungen die AGB weiter für das Geschäftsverhältnis, sofern sie nicht anders geregelt sind.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.) Sondervereinbarungen gelten nur bei schriftlicher Bestätigung durch die RiffReporter eG.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.) Der mit dem Kunden zustande gekommene Vertrag unterliegt ausschließlich deutschem Recht
                        unter Ausschluss des UN-Kaufrechts.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.) Ist der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts oder
                        öffentlich-rechtliches Sondervermögen, so ist ausschließlicher Gerichtsstand der Sitz der
                        RiffReporter eG Berlin.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        6.) Sollte eine Klausel dieses Vertrages unwirksam sein, so berührt dies die Wirksamkeit der
                        übrigen Klauseln nicht. Entsprechendes gilt für etwaige Lücken im Vertragstext.
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{marginTop: 30}}>
                        Stand: 20.06.2023
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Tanja Krämer und Stefan Johannesberg
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Vorstand
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Sie haben Fragen zu diesen AGB? Melden Sie sich gerne bei{' '}
                        <a href="mailto:christian.schwaegerl@riffreporter.de">christian.schwaegerl@riffreporter.de</a>
                    </Typography>
                </Grid>
            </Grid>
        </StyledContainerWrapper>
    );
};
