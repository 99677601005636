import {createTheme} from '@mui/material/styles';

export const colors = {
    white: '#ffffff',
    black: '#000000',
    gray20: '#333333',
    gray50: '#777777',
    gray80: '#CCCCCC',
    gray95: '#F4F5F7',
    red30: '#752327',
    red50: '#BD3E44',
    red70: '#F4636A',
    red80: '#F99398',
    greenGray40: '#505863',
    greenGray60: '#939698',
    greenGray80: '#C3C6C8',
    turquoise50: '#488A91',
};

export const FONT_BODY = 'Raleway, Arial, sans-serif';

export const theme = createTheme({
    typography: {
        fontFamily: FONT_BODY,
        h1: {
            fontWeight: 'bold',
        },
        h6: {
            fontWeight: 'bold',
        },
        subtitle1: {
            fontWeight: 'bold',
        },
    },
    shape: {
        borderRadius: 2,
    },
    palette: {
        background: {
            default: colors.gray95,
        },
        text: {
            primary: colors.greenGray40,
            secondary: colors.greenGray60,
        },
        primary: {
            main: colors.turquoise50,
        },
        secondary: {
            main: colors.greenGray40,
            light: colors.gray95,
        },
        error: {
            main: colors.red70,
            light: colors.red80,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: FONT_BODY,
                    color: colors.gray20,
                    margin: 0,
                },
                a: {
                    textDecoration: 'none',
                    color: colors.turquoise50,
                },
                // hide focus outline on headline element after SPA navigation
                '[tabindex="-1"]:focus': {
                    outline: '0 !important',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    fontWeight: 'bold',
                },
                outlined: {
                    fontWeight: 'bold',
                },
                outlinedPrimary: {
                    fontWeight: 'bold',
                },
                outlinedSecondary: {
                    fontWeight: 'bold',
                },
            },
        },
    },
});
