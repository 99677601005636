import {useQuery} from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {$enum} from 'ts-enum-util';
import {GoToArticleButton} from '../../../assets/ArticleSearchIcons';
import {ROUTE_ARTICLE} from '../../../lib/constants';
import {loadMoreArticles} from '../../../lib/redux/actions/listArticleActions';
import {getAuthors, GQ_SEARCH_ARTICLE} from '../../../lib/services/polypublisher/article';
import {hasApolloErrors} from '../../../lib/utils/apollo';
import {formatDateString, formatDateTimeString} from '../../../lib/utils/formatString';
import {err, log} from '../../../lib/utils/logger';
import {colors, theme} from '../../../styles/theme';
import {Article, ArticleEdge, LocaleEnum, ResortEnum} from '../../../types/graphqlTypes';
import {AppState, ArticleSearchState} from '../../../types/redux';
import {Preloader} from '../../Preloader/Preloader';
import {getSelectedLicenseKeys} from '../../shoppingCart/AddToCartDialog/AddToCartDialog';

const StyledError = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 200,
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    padding: theme.spacing(2),
}));

const ArticleItem: React.FC<{articleData?: Article; searchFilterLanguage: string}> = ({
    articleData,
    searchFilterLanguage,
}) => {
    const {t} = useTranslation();
    const price = articleData?.prices?.slice().shift();
    const authors = getAuthors(articleData);

    return articleData ? (
        <Link to={`${ROUTE_ARTICLE}/${searchFilterLanguage}/${articleData.id}`} key={articleData.id}>
            <Paper
                sx={{
                    padding: theme.spacing(4),
                    marginBottom: theme.spacing(2),
                }}
            >
                <GoToArticleButton
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(3),
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                    }}
                />
                <Grid container sx={{pb: theme.spacing(2)}}>
                    <Grid item md={12}>
                        <Typography variant="h6">
                            {articleData.preview?.headline ?? articleData.meta?.title ?? articleData.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {articleData.preview?.copy ?? articleData.meta?.description ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {authors.isMultiple ? t('article.authors') : t('article.author')}
                        </Typography>
                        <Typography variant="body1">{authors.namesFormatted ?? 'unbekannt'}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.characters')}
                        </Typography>
                        <Typography variant="body1">{articleData?.characters ?? t('article.noCharLength')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.datePublication')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.publication?.datePublication) ??
                                t('article.noDatePublication')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateModification')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.dateModified ?? articleData?.publication?.datePublication) ??
                                t('article.noModification')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.resort')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.resorts
                                ?.map(r => {
                                    // return !r?.doNotList ? r?.title : null
                                    return r?.title;
                                })
                                ?.join(', ') ?? t('article.noResort')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.vgwort')}
                        </Typography>
                        {articleData?.owner?.vgwort &&
                        articleData?.owner?.vgwort?.number &&
                        !articleData?.owner?.vgwort?.excluded ? (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.lastname
                                    ? articleData?.owner?.vgwort?.firstname + ' ' + articleData?.owner?.vgwort?.lastname
                                    : articleData?.owner?.fullName}
                                : {articleData?.owner?.vgwort?.number}
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.excluded
                                    ? t('article.vgwortExcluded')
                                    : t('article.vgwortNoData')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateEmbargo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.dateEmbargo ? (
                                <strong style={{color: colors.red70}}>
                                    {formatDateTimeString(articleData?.publication?.dateEmbargo)}
                                </strong>
                            ) : (
                                t('article.noDateEmbargo')
                            )}
                        </Typography>
                        {articleData?.publication?.allowOwnPrePublication && (
                            <Typography variant="body1">{t('article.allowOwnPrePublication')}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationInfo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.infoPublication
                                ? articleData?.publication?.infoPublication.replace(/\r\n|\r|\n/g, ', ')
                                : t('article.publicationInfoNoData')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationOther')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.otherPublication
                                ? articleData?.publication?.otherPublication.replace(/\r\n|\r|\n/g, ', ')
                                : t('article.publicationOtherNoData')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Grid item xs={6} md={4} lg={3}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.price')}
                        </Typography>
                        <Typography variant="subtitle1" color={'primary'}>
                            {price?.priceFormatted ?? t('article.noPrice')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={8} lg={9}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.priceFactor')}
                        </Typography>
                        {price?.priceFactor === null ? (
                            <Typography variant="body1">{t('article.priceFactorFixed')}</Typography>
                        ) : price?.priceFactor === 1 ? (
                            <Typography variant="body1">{t('article.priceFactorStandard')}</Typography>
                        ) : (
                            <Typography variant="subtitle1" color={'primary'}>
                                {(price?.priceFactor ?? 1) * 100 > 100
                                    ? '+ ' + ((price?.priceFactor ?? 1) * 100 - 100) + '%'
                                    : '- ' + (100 - (price?.priceFactor ?? 1) * 100) + '%'}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Link>
    ) : null;
};

const filterResort = (input: any) => {
    return $enum(ResortEnum).getValues().includes(input) ? input : null;
};

const InternalArticleSearchResults: React.FC = () => {
    const articleCount = useSelector<AppState, number>(state => state.articleCount);
    const searchTerms = useSelector<AppState, ArticleSearchState>(state => state.search);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    log('InternalArticleSearchResults', searchTerms);

    const {loading, data, error} = useQuery(GQ_SEARCH_ARTICLE, {
        variables: {
            term: searchTerms.searchTerm,
            dateSince: searchTerms.dateFrom,
            dateUntil: searchTerms.dateTo,
            charactersMin: searchTerms.charactersMin,
            charactersMax: searchTerms.charactersMax,
            resort: filterResort(searchTerms.resort),
            licenses: getSelectedLicenseKeys(searchTerms.licenses ?? []),
            orderType: searchTerms.orderType,
            orderDirection: searchTerms.orderDirection,
            first: articleCount,
            locale: searchTerms.localeEnum,
            useBlacklist: searchTerms.useBlacklist,
            useBlacklistType: searchTerms.useBlacklistType,
        },
    });

    if (hasApolloErrors(error)) {
        err('ArticleSearchResults error', error);
    }

    const edges: ArticleEdge[] = data?.search?.edges as ArticleEdge[];
    const totalCount: number = data?.search?.totalCount ?? 0;

    const searchFilterLanguage = searchTerms.localeEnum === LocaleEnum.DeDe ? 'de' : 'en';

    return (
        <>
            {loading && <Preloader minHeight={300} />}
            {error && <StyledError>{error?.message}</StyledError>}
            {!loading &&
                !error &&
                (totalCount > 0 ? (
                    <>
                        <Grid container item xs={12} justifyContent="space-between">
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                sx={{
                                    paddingLeft: theme.spacing(4),
                                    marginTop: theme.spacing(4),
                                }}
                            >
                                {totalCount} {t('articleSearch.articlesFound')}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{
                                    paddingRight: theme.spacing(4),
                                    marginTop: theme.spacing(4),
                                }}
                            >
                                {t('articleSearch.pricesDescription')}
                            </Typography>
                        </Grid>
                        {(edges as ArticleEdge[])?.map(el => (
                            <ArticleItem
                                articleData={el?.node as Article}
                                key={el?.node?.id}
                                searchFilterLanguage={searchFilterLanguage}
                            />
                        ))}
                        <Grid container item xs={12} justifyContent="center">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => dispatch(loadMoreArticles() as any)}
                                disabled={totalCount <= articleCount}
                            >
                                {t('articleSearch.loadMore')}
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <Typography
                        variant="h5"
                        color="textSecondary"
                        sx={{
                            padding: theme.spacing(5, 3),
                            textAlign: 'center',
                        }}
                    >
                        {t('articleSearch.noArticlesFound')}
                    </Typography>
                ))}
        </>
    );
};

export const ArticleSearchResults: React.FC = () => {
    const searchState = useSelector<AppState, ArticleSearchState>(state => state.search);
    const {t} = useTranslation();

    return searchState.hasError ? (
        <StyledError>{t('articleSearch.formError')}</StyledError>
    ) : (
        <InternalArticleSearchResults />
    );
};
