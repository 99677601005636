import isBefore from 'date-fns/isBefore';

interface VatStruct {
    label: string; // display
    validFrom: number; // unix timestamp
    value: number; // calc
}

const vatRates: VatStruct[] = [
    {
        label: '7%',
        validFrom: 0,
        value: 0.07,
    },
    {
        label: '5%',
        validFrom: 1593561600000,
        value: 0.05,
    },
    {
        label: '7%',
        validFrom: 1609459200000,
        value: 0.07,
    },
];

/**
 * Returns the correct VAT rate for a given date. If no date is provided, the current point in time is used
 * @param iso8601String
 */
export const getVat = (iso8601String?: string | null): VatStruct => {
    const date = iso8601String ? new Date(iso8601String) : new Date();
    let vat: VatStruct = vatRates[0];

    vatRates.forEach(item => {
        if (isBefore(date, new Date(item.validFrom))) {
            return vat;
        }
        vat = item;
    });

    return vat;
};
