import {useQuery} from '@apollo/client';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {format} from 'date-fns-tz';
import de from 'date-fns/locale/de';
import {useFormik} from 'formik';
import debounce from 'just-debounce-it';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {$enum} from 'ts-enum-util';
import * as Yup from 'yup';
import {resetLoadMoreArticles} from '../../../lib/redux/actions/listArticleActions';
import {setSearchError, setSearchTerms} from '../../../lib/redux/actions/searchArticleActions';
import {GQ_RETRIEVE_RESORTS} from '../../../lib/services/polypublisher/resort';
import {useMatomoTracking} from '../../../lib/tracking/matomo';
import {hasFormErrors} from '../../../lib/utils/form';
import {formatNumber} from '../../../lib/utils/formatString';
import {error, log} from '../../../lib/utils/logger';
import {colors, theme} from '../../../styles/theme';
import {
    LocaleEnum,
    OrderDirectionEnum,
    OrderTypeEnum,
    PublicationLicenseEnum,
    ResortEdge,
    ResortEnum,
} from '../../../types/graphqlTypes';
import {AppState, ArticleSearchState, RESORT_ALL} from '../../../types/redux';
import {hasSelectedLicense, LicenseSelection} from '../../shoppingCart/AddToCartDialog/AddToCartDialog';
import {RangeSlider} from '../RangeSlider/RangeSlider';

const StyledSpacer = styled(Box)(({theme}) => ({
    paddingLeft: theme.spacing(4),
    borderLeft: 'solid',
    borderLeftColor: colors.greenGray80,
    borderLeftWidth: 1,
}));

interface ResortOption {
    key: string;
    title?: string | null;
}

export const ArticleSearchForm: React.FC = () => {
    const searchTerms = useSelector<AppState, ArticleSearchState>(state => state.search);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {trackSearch} = useMatomoTracking();
    const [availableResorts, setAvailableResorts] = useState<ResortOption[]>([]);
    // retrieve resort items for resort titles
    const {data: resortData} = useQuery(GQ_RETRIEVE_RESORTS, {
        variables: {},
    });

    // build resort options from resort query
    useEffect(() => {
        const wildcardOption: ResortOption = {key: RESORT_ALL, title: t('article.all')};
        const options: ResortOption[] = [wildcardOption]
            .concat(
                (resortData?.resorts?.edges as ResortEdge[])
                    ?.filter(r => !r?.node?.doNotList && r?.node?.key) // use valid resorts only
                    .map(r => {
                        // create ResortOption
                        return {
                            key: r?.node?.key ?? '', // key is required
                            title: r?.node?.title, // title if available
                        };
                    }),
            )
            .filter(o => !!o); // filter empty elements
        // available resorts: 'ALL' + all other resorts except 'NONE'
        setAvailableResorts(options);
    }, [resortData]);

    // form submit handler, set search params
    const onSubmit = (values: ArticleSearchState) => {
        log('handleSubmit values', values);
        trackSearch({
            keyword: JSON.stringify(values),
            category: 'polymarket-search',
        });
        dispatch(setSearchTerms(values) as any);
        dispatch(resetLoadMoreArticles() as any);
    };

    const validationSchema = Yup.object({
        licenses: Yup.array().test(
            'licensesNotEmpty',
            t('addToCartDialog.mustSelectALicense') ?? '',
            hasSelectedLicense,
        ),
    });

    // formik form controllers
    const {handleSubmit, handleChange, values, errors, setFieldValue, submitForm} = useFormik({
        initialValues: searchTerms,
        validationSchema,
        onSubmit,
    });

    // wait for 300ms of inaction before submitting
    const debouncedSubmit = useCallback(
        debounce(() => submitForm(), 300),
        [submitForm],
    );

    // onChange handler and form submit
    const handleChangeAndSubmit = (
        event:
            | ChangeEvent
            | SelectChangeEvent<'ALL' | ResortEnum | OrderTypeEnum | OrderDirectionEnum | LocaleEnum | string | null>,
    ) => {
        handleChange(event);
        debouncedSubmit();
    };

    // manually set field value and form submit
    const setFieldValueAndSubmit = (field: string, value: any, shouldValidate?: boolean | undefined) => {
        setFieldValue(field, value, shouldValidate);
        debouncedSubmit();
    };

    // when form validation fails, tell results page to show the error message
    useEffect(() => {
        if (hasFormErrors(errors)) {
            error('errors', errors);
            dispatch(setSearchError() as any);
        }
    }, [errors]);

    const availableLanguages = [LocaleEnum.DeDe, LocaleEnum.EnGb];

    const characterMarks = [
        {
            value: 0,
            label: formatNumber(0),
        },
        {
            value: 100000,
            label: formatNumber(100000),
        },
    ];

    function characterValueLabel(value: number) {
        return `${formatNumber(value)}`;
    }

    function getLicenseTitle(k: string): string {
        switch (k) {
            case PublicationLicenseEnum.Print:
                return t('license.print');
            case PublicationLicenseEnum.WebPaywall:
                return t('license.webPaywall');
            case PublicationLicenseEnum.WebFree:
                return t('license.webFree');
            case PublicationLicenseEnum.Radio:
                return t('license.radio');
            case PublicationLicenseEnum.Tv:
                return t('license.tv');
            default:
                return k;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <StyledSpacer>
                            <TextField
                                onChange={event => {
                                    handleChangeAndSubmit(event);
                                }}
                                value={values.searchTerm}
                                name="searchTerm"
                                label={t('articleSearch.searchTerm')}
                                fullWidth={true}
                                type={'text'}
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            />
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <StyledSpacer>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        disabled: false,
                                        error: false,
                                        fullWidth: true,
                                    },
                                }}
                                value={values.dateFrom ? new Date(values.dateFrom) : undefined}
                                label={t('articleSearch.dateFrom')}
                                onChange={date => {
                                    const dateIsoString = date ? date.toISOString() : null;
                                    if (dateIsoString !== null) {
                                        const dateFormatted = format(new Date(dateIsoString), 'yyyy-MM-dd');
                                        setFieldValueAndSubmit('dateFrom', dateFormatted);
                                    }
                                }}
                                format={'dd.MM.yyyy'}
                            />
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <DatePicker
                            slotProps={{
                                textField: {
                                    disabled: false,
                                    error: false,
                                    fullWidth: true,
                                },
                            }}
                            value={values.dateTo ? new Date(values.dateTo) : undefined}
                            label={t('articleSearch.dateTo')}
                            onChange={date => {
                                const dateIsoString = date ? date.toISOString() : null;
                                if (dateIsoString !== null) {
                                    const dateFormatted = format(new Date(dateIsoString), 'yyyy-MM-dd');
                                    setFieldValueAndSubmit('dateTo', dateFormatted);
                                }
                            }}
                            format={'dd.MM.yyyy'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSpacer>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{mx: 2}}>
                                    <Typography
                                        variant={'caption'}
                                        sx={{
                                            display: 'block',
                                            position: 'relative',
                                            top: theme.spacing(-2),
                                            height: 0,
                                        }}
                                    >
                                        {t('articleSearch.chars')}
                                    </Typography>
                                    <RangeSlider
                                        setFieldValue={setFieldValueAndSubmit}
                                        valueName={['charactersMin', 'charactersMax']}
                                        value={[values.charactersMin ?? 0, values.charactersMax ?? 100000]}
                                        valueRange={[0, 100000]}
                                        marks={characterMarks}
                                        step={1000}
                                        valueLabelDisplay="on"
                                        getAriaLabel={index =>
                                            index === 0 ? t('articleSearch.charsMin') : t('articleSearch.charsMax')
                                        }
                                        valueLabelFormat={value => <div>{characterValueLabel(value)}</div>}
                                        sx={{marginTop: theme.spacing(3)}}
                                    />
                                </Grid>
                            </Grid>
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledSpacer>
                            <FormControl component="fieldset" error={!!errors.resort} sx={{width: '100%'}}>
                                <InputLabel id="demo-mutiple-checkbox-label">{t('article.resort')}</InputLabel>
                                <Select
                                    name={'resort'}
                                    value={availableResorts.length ? values.resort : ''}
                                    label={t('article.resort')}
                                    onChange={event => {
                                        handleChangeAndSubmit(event);
                                    }}
                                    disabled={!availableResorts}
                                >
                                    {availableResorts.map(r => (
                                        <MenuItem key={r.key} value={r.key}>
                                            {r.title ?? r.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.resort && (
                                    <FormHelperText>
                                        <>{errors.resort}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledSpacer>
                            <FormControl component="fieldset" error={!!errors.licenses} sx={{width: '100%'}}>
                                <FormLabel component="legend">{t('article.publicationLicense')}</FormLabel>
                                <FormGroup
                                    sx={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    {values.licenses?.map((lcs: LicenseSelection, i: number) => {
                                        return (
                                            <FormControlLabel
                                                key={lcs.key ?? i}
                                                control={
                                                    <Checkbox
                                                        checked={lcs.selected}
                                                        onChange={event => {
                                                            handleChangeAndSubmit(event);
                                                        }}
                                                        name={`licenses[${i}].selected`}
                                                    />
                                                }
                                                label={lcs.title ? lcs.title : getLicenseTitle(lcs.key ?? '')}
                                                sx={{color: errors.licenses ? colors.red70 : 'inherit'}}
                                            />
                                        );
                                    })}
                                </FormGroup>
                                {!!errors.licenses && (
                                    <FormHelperText>
                                        <>{errors.licenses}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <StyledSpacer>
                            <FormControl component="fieldset" error={!!errors.orderType} sx={{width: '100%'}}>
                                <InputLabel>{t('orderType.title')}</InputLabel>
                                <Select
                                    name={'orderType'}
                                    value={values.orderType}
                                    label={t('orderType.title')}
                                    onChange={event => {
                                        handleChangeAndSubmit(event);
                                    }}
                                >
                                    {$enum(OrderTypeEnum)
                                        .getValues()
                                        .filter(o => o !== OrderTypeEnum.Id && o !== OrderTypeEnum.Push)
                                        .map(o => (
                                            <MenuItem key={o} value={o}>
                                                {t(`orderType.${o}`)}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {!!errors.orderType && (
                                    <FormHelperText>
                                        <>{errors.orderType}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </StyledSpacer>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <StyledSpacer>
                            <FormControl component="fieldset" error={!!errors.orderDirection} sx={{width: '100%'}}>
                                <InputLabel>{t('orderDirection.title')}</InputLabel>
                                <Select
                                    name={'orderDirection'}
                                    value={values.orderDirection}
                                    label={t('orderDirection.title')}
                                    onChange={event => {
                                        handleChangeAndSubmit(event);
                                    }}
                                >
                                    {$enum(OrderDirectionEnum)
                                        .getValues()
                                        .map(o => (
                                            <MenuItem key={o} value={o}>
                                                {t(`orderDirection.${o}`)}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {!!errors.orderDirection && (
                                    <FormHelperText>
                                        <>{errors.orderDirection}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </StyledSpacer>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <StyledSpacer>
                            <FormControl component="fieldset" error={!!errors.localeEnum} sx={{width: '100%'}}>
                                <InputLabel>{t('article.language')}</InputLabel>
                                <Select
                                    name={'localeEnum'}
                                    value={values.localeEnum}
                                    label={t('article.language')}
                                    onChange={event => {
                                        handleChangeAndSubmit(event);
                                    }}
                                >
                                    {availableLanguages.map(lang => (
                                        <MenuItem key={lang} value={lang}>
                                            {t(`localeEnum.${lang}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {!!errors.localeEnum && (
                                    <FormHelperText>
                                        <>{errors.localeEnum}</>
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </StyledSpacer>
                    </Grid>

                    {/*<Grid item xs={12} sm={12}>*/}
                    {/*    <Button variant="contained" color="primary" type="submit">*/}
                    {/*        {t('action.search')}*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                </Grid>
            </form>
        </LocalizationProvider>
    );
};
