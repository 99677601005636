import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {colors, theme} from '../../../styles/theme';
import {ParagraphOembed as DataType} from '../../../types/graphqlTypes';

interface ParagraphOembedProps {
    data: DataType;
}

export const ParagraphOembed: React.FC<ParagraphOembedProps> = ({data}) => {
    const {marketing, funding, oembed} = data;
    const {t} = useTranslation();

    if (marketing == true || funding == true) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                fontSize: 18,
                lineHeight: 1.8,
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                <Box
                    sx={{
                        backgroundColor: colors.turquoise50,
                        color: colors.white,
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        '& a': {
                            color: colors.white,
                            textDecoration: 'underline',
                        },
                    }}
                >
                    <strong>{t('article.oembed', {provider: oembed?.provider})}</strong>
                    <br />
                    <a href={oembed?.url} target={'_blank'} rel="noreferrer noopener">
                        {oembed?.url}
                    </a>
                </Box>
            </Grid>
        </Grid>
    );
};
