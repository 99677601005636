import {AppNotification, AppNotificationState, AppNotificationType} from '../../../types/redux';
import {NOTIFICATION_ADD, NOTIFICATION_REMOVE, NOTIFICATION_RESET} from '../actions/notificationActions';

const initialState: AppNotificationState = {
    notifications: [],
    lastId: 0,
};

type NotificationActionProps =
    | {
          type: typeof NOTIFICATION_ADD;
          payload: {message: string; type: AppNotificationType};
      }
    | {
          type: typeof NOTIFICATION_REMOVE;
          payload: number;
      }
    | {
          type: typeof NOTIFICATION_RESET;
      };

export const notificationReducer = (
    state: AppNotificationState = initialState,
    action: NotificationActionProps,
): AppNotificationState => {
    switch (action.type) {
        case NOTIFICATION_ADD: {
            const notif: AppNotification = {id: state.lastId + 1, ...action.payload};
            return {...state, lastId: notif.id, notifications: [...state.notifications, notif]};
        }
        case NOTIFICATION_REMOVE:
            return {...state, notifications: state.notifications.filter(n => n.id !== action.payload)};
        case NOTIFICATION_RESET:
            return initialState;
        default:
            return state;
    }
};
