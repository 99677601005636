import {useQuery} from '@apollo/client';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {styled, useTheme} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import {
    CartIcon,
    FAQIcon,
    HistoryIcon,
    LogoutIcon,
    MenuIcon,
    SearchIcon,
    UserIcon,
} from '../../assets/AppNavigationIcons';
import logo from '../../assets/logo.svg';
import {ROUTE_ARTICLE_SEARCH, ROUTE_FAQ, ROUTE_PURCHASES, ROUTE_SHOPPING_CART, ROUTE_USER} from '../../lib/constants';
import {logoutUser} from '../../lib/redux/actions/userActions';
import {GQ_RETRIEVE_SHOPPING_CART} from '../../lib/services/polypublisher/shoppingCart';
import {colors, FONT_BODY, theme} from '../../styles/theme';
import {AuthenticationRoleEnum, ShoppingCart} from '../../types/graphqlTypes';
import {AppState, UserState, UserStatus} from '../../types/redux';

const drawerWidth = 86;

const StyledBadge = styled(Badge)(() => ({
    fontFamily: FONT_BODY,
    fontWeight: 'bold',
}));

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    cursor: 'pointer',
}));

const InternalAppBar: React.FC<{handleDrawerToggle(): void}> = ({handleDrawerToggle}) => {
    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                [theme.breakpoints.up('sm')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
                backgroundColor: colors.gray95,
            }}
        >
            <Toolbar
                sx={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingTop: theme.spacing(2.5),
                }}
            >
                <Grid justifyContent="space-between" container>
                    <Grid item>
                        <MenuIcon
                            onClick={handleDrawerToggle}
                            color={'secondary'}
                            sx={{
                                marginRight: theme.spacing(2),
                                [theme.breakpoints.up('sm')]: {
                                    display: 'none',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <img src={logo} alt="" height="24" width="24" />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const ShoppingCartNavItem: React.FC<{iconColor: 'primary' | 'disabled'}> = ({iconColor}) => {
    const {data} = useQuery(GQ_RETRIEVE_SHOPPING_CART, {
        variables: {
            ignoreCache: true,
            role: [AuthenticationRoleEnum.Owner],
        },
    });
    const shoppingCart = data?.user?.openShoppingCart as ShoppingCart;
    const cartItemCount = shoppingCart?.cartItems?.length ?? 0;

    return (
        <StyledBadge badgeContent={cartItemCount} invisible={cartItemCount === 0} overlap="rectangular" color="primary">
            <CartIcon color={iconColor} />
        </StyledBadge>
    );
};

const DrawerContent: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    // const user = useSelector<AppState, UserState>(state => state.user);

    const getIconColor = (currentPath: string, route: string) => {
        return currentPath.indexOf(route) >= 0 ? 'primary' : 'disabled';
    };

    const handleLogout = () => {
        dispatch(logoutUser() as any);
    };

    return (
        <List>
            <ListItem>
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    }}
                >
                    <img src={logo} alt="RiffReporter Logo" height="24" width="24" />
                </ListItemIcon>
            </ListItem>
            <>
                <NavLink to={ROUTE_ARTICLE_SEARCH} key={ROUTE_ARTICLE_SEARCH}>
                    <ListItem>
                        <StyledListItemIcon>
                            <SearchIcon color={getIconColor(location.pathname, ROUTE_ARTICLE_SEARCH)} />
                        </StyledListItemIcon>
                    </ListItem>
                </NavLink>
                <NavLink to={ROUTE_SHOPPING_CART} key={ROUTE_SHOPPING_CART}>
                    <ListItem>
                        <StyledListItemIcon>
                            <ShoppingCartNavItem iconColor={getIconColor(location.pathname, ROUTE_SHOPPING_CART)} />
                        </StyledListItemIcon>
                    </ListItem>
                </NavLink>
                <NavLink to={ROUTE_PURCHASES} key={ROUTE_PURCHASES}>
                    <ListItem>
                        <StyledListItemIcon>
                            <HistoryIcon color={getIconColor(location.pathname, ROUTE_PURCHASES)} />
                        </StyledListItemIcon>
                    </ListItem>
                </NavLink>
                <NavLink to={ROUTE_FAQ} key={ROUTE_FAQ}>
                    <ListItem>
                        <StyledListItemIcon>
                            <FAQIcon color={getIconColor(location.pathname, ROUTE_FAQ)} />
                        </StyledListItemIcon>
                    </ListItem>
                </NavLink>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: theme.spacing(2),
                    }}
                >
                    <NavLink to={ROUTE_USER} key={ROUTE_USER}>
                        <ListItem>
                            <StyledListItemIcon>
                                <UserIcon color={getIconColor(location.pathname, ROUTE_USER)} />
                            </StyledListItemIcon>
                        </ListItem>
                    </NavLink>
                    <ListItem key="logout" onClick={handleLogout}>
                        <StyledListItemIcon>
                            <LogoutIcon color="primary" />
                        </StyledListItemIcon>
                    </ListItem>
                </Box>
            </>
        </List>
    );
};

export const AppNavigation: React.FC = () => {
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const user = useSelector<AppState, UserState>(state => state.user);
    const isLoggedIn = user.status === UserStatus.LOGGED_IN;
    const location = useLocation();

    // close drawer on navigation
    useEffect(() => {
        setMobileOpen(false);
    }, [location.pathname]);

    return (
        <>
            {isLoggedIn && (
                <>
                    {/* mobile app bar */}
                    <Hidden smUp implementation="js">
                        <InternalAppBar handleDrawerToggle={handleDrawerToggle} />
                    </Hidden>
                    <Box
                        component={'nav'}
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                width: drawerWidth,
                                flexShrink: 0,
                            },
                        }}
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        {/* mobile drawer */}
                        <Hidden smUp implementation="js">
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                sx={{
                                    width: drawerWidth,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                <DrawerContent />
                            </Drawer>
                        </Hidden>
                        {/* desktop drawer */}
                        <Hidden smDown implementation="js">
                            <Drawer
                                variant="permanent"
                                open
                                sx={{
                                    width: drawerWidth,
                                }}
                            >
                                <DrawerContent />
                            </Drawer>
                        </Hidden>
                    </Box>
                </>
            )}
        </>
    );
};
