import {ArticleSearchState} from '../../../types/redux';

export const SEARCH_SET_TERMS = 'SEARCH_SET_TERMS';
export const SEARCH_RESET_TERMS = 'SEARCH_RESET_TERMS';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const setSearchTerms = (searchTerms: ArticleSearchState): Record<string, unknown> => {
    return {
        type: SEARCH_SET_TERMS,
        payload: searchTerms,
    };
};

export const resetSearchTerms = (): Record<string, unknown> => {
    return {
        type: SEARCH_RESET_TERMS,
    };
};

export const setSearchError = (): Record<string, unknown> => {
    return {
        type: SEARCH_ERROR,
    };
};
