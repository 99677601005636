import Grid from '@mui/material/Grid';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import logo from '../../assets/logo.svg';
import {ROUTE_HOME, ROUTE_LOGIN} from '../../lib/constants';
import {logoutUser, USER_LOGOUT} from '../../lib/redux/actions/userActions';
import {StyledButton} from '../../styles/notAuthenticatedUser';
import {AppState, UserState, UserStatus} from '../../types/redux';

export const LogoutPage: React.FC = () => {
    const user: UserState = useSelector<AppState, UserState>(state => state.user);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        // willful navigation to this page by a logged-in user results in logout
        if (user.status === UserStatus.LOGGED_IN) {
            dispatch(logoutUser(USER_LOGOUT) as any);
        }
    }, [user]);

    const title =
        user.status === UserStatus.SESSION_EXPIRED ? t('logout.sessionExpired.title') : t('logout.manual.title');
    const subtitle =
        user.status === UserStatus.SESSION_EXPIRED ? t('logout.sessionExpired.subtitle') : t('logout.manual.subtitle');

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
                minHeight: '90vh',
                textAlign: 'center',
            }}
        >
            <Grid item xs={10} sm={8} lg={6}>
                <Grid container justifyContent={'center'}>
                    <NavLink to={ROUTE_HOME}>
                        <img src={logo} alt="RiffReporter Logo" height="48" width="48" />
                    </NavLink>
                    <Grid item xs={12}>
                        <h1>{title}</h1>
                        <h2>{subtitle}</h2>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledButton
                            component={Link}
                            to={ROUTE_LOGIN}
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                        >
                            {t('logout.login')}
                        </StyledButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
