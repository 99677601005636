import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import React from 'react';

type ButtonProps = {
    component?: React.ElementType;
    to?: string;
};
export const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
    marginTop: theme.spacing(1),
    maxWidth: 180,
}));
