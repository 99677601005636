import gql from 'graphql-tag';
import {Article, ContributorFunctionEnum} from '../../../types/graphqlTypes';

export const GQ_RETRIEVE_ARTICLE_DETAIL = gql`
    query retrieveArticle($id: ID!, $locale: LocaleEnum) {
        article(id: $id, locale: $locale) {
            id
            title
            encryption
            locale
            # Autor -> function === WRITER
            owner {
                id
                fullName
                vgwort {
                    number
                    firstname
                    lastname
                    excluded
                    rightsTransferred
                }
            }
            contributors {
                user {
                    id
                    fullName
                }
                custom
                function
            }
            # Resorts
            resorts {
                key
                title
                doNotList
            }
            # Verfügbarkeit nach Publisher - später
            # Verfügbarkeit nach Geographie - raus
            # Veröffentlichungsdatum
            publication {
                platform
                datePublication
                dateDepublication
                dateEmbargo
                allowOwnPrePublication
                doNotList
                infoPublication
                otherPublication
            }
            # Länge
            characters
            # Datum der letzten Aktualisierung
            dateModified
            # Notiz zur letzten Änderung
            noteModified
            # Preis - günstigsten Preis für aktuelle Filter anzeigen ("ab 123€")
            prices {
                price
                priceFormatted
                priceFactor
                rightFirstPublicationAvailable
                rightExclusivePublicationAvailable
                rightFirstPublicationSelected
                rightExclusivePublicationSelected
                note
                # Nutzungsrecht
                licenses {
                    id
                    key
                    title
                }
                licensesSelected {
                    id
                    key
                    title
                }
            }
            # Paragraphs
            paragraphs {
                variant
                display
                classes
                type
                sequence
                scramble
                ... on ParagraphHero {
                    headline
                    subline
                    lead
                }
                ... on ParagraphHeaderVideo {
                    headline
                    subline
                }
                ... on ParagraphText {
                    marketing
                    funding
                    html
                    text
                }
                ... on ParagraphOembed {
                    marketing
                    funding
                    oembed {
                        url
                        provider
                    }
                }
                ... on ParagraphVoiceflow {
                    marketing
                    funding
                    url
                }
                ... on ParagraphQuote {
                    marketing
                    funding
                    quote
                    caption
                    cite
                }
                ... on ParagraphAccordion {
                    marketing
                    funding
                    items {
                        sequence
                        title
                        paragraphs {
                            type
                            ... on ParagraphText {
                                html
                                text
                            }
                        }
                    }
                }
                ... on ParagraphTldr {
                    marketing
                    funding
                    paragraphs {
                        type
                        ... on ParagraphText {
                            html
                            text
                        }
                    }
                }
                ... on ParagraphFixedBackground {
                    marketing
                    funding
                    paragraphs {
                        type
                        ... on ParagraphText {
                            html
                            text
                        }
                    }
                }
                ... on ParagraphMultiMedia {
                    marketing
                    funding
                    items {
                        sequence
                        paragraphs {
                            type
                            ... on ParagraphText {
                                html
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GQ_RETRIEVE_ARTICLE_PRICE_DETAILS = gql`
    query retrieveArticlePrice(
        $id: ID!
        $firstPublication: Boolean
        $exclusivePublication: Boolean
        $licenses: [PublicationLicenseEnum]
    ) {
        article(id: $id) {
            id
            encryption
            prices(
                firstPublication: $firstPublication
                exclusivePublication: $exclusivePublication
                licenses: $licenses
            ) {
                price
                priceFormatted
                rightFirstPublicationAvailable
                rightExclusivePublicationAvailable
                rightFirstPublicationSelected
                rightExclusivePublicationSelected
                note
                # Nutzungsrecht
                licenses {
                    id
                    key
                    title
                }
                licensesSelected {
                    id
                    key
                    title
                }
            }
        }
    }
`;

export const GQ_SEARCH_ARTICLE = gql`
    query searchArticle(
        $term: SearchTerm
        $dateSince: Iso8601
        $dateUntil: Iso8601
        $charactersMin: Int
        $charactersMax: Int
        $resort: ResortEnum
        $licenses: [PublicationLicenseEnum]
        $orderType: OrderTypeEnum
        $orderDirection: OrderDirectionEnum
        $first: Int
        $locale: LocaleEnum
        $useBlacklist: Boolean
        $useBlacklistType: String
    ) {
        search(
            term: $term
            dateSince: $dateSince
            dateUntil: $dateUntil
            charactersMin: $charactersMin
            charactersMax: $charactersMax
            resort: $resort
            licenses: $licenses
            orderType: $orderType
            orderDirection: $orderDirection
            first: $first
            locale: $locale
            useBlacklist: $useBlacklist
            useBlacklistType: $useBlacklistType
        ) {
            totalCount
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    locale
                    title
                    characters
                    encryption
                    resorts {
                        key
                        title
                        doNotList
                    }
                    publication {
                        platform
                        datePublication
                        dateDepublication
                        dateEmbargo
                        allowOwnPrePublication
                        doNotList
                        infoPublication
                        otherPublication
                    }
                    prices {
                        priceFormatted
                        priceFactor
                    }
                    meta {
                        title
                        description
                    }
                    preview {
                        headline
                        copy
                    }
                    tags {
                        id
                        title
                    }
                    owner {
                        id
                        fullName
                        vgwort {
                            number
                            firstname
                            lastname
                            excluded
                            rightsTransferred
                        }
                    }
                    contributors {
                        user {
                            id
                            fullName
                        }
                        custom
                        function
                    }
                }
            }
        }
    }
`;

interface AuthorDisplayData {
    names: string[];
    namesFormatted: string;
    isMultiple: boolean;
}

/**
 * Returns the author(s) of an article, which are the 'Writer' contributors
 * @param articleData
 */
export const getAuthors = (articleData?: Article): AuthorDisplayData => {
    const names =
        articleData?.contributors
            ?.filter((c: any) => c?.function === ContributorFunctionEnum.Writer && (c?.user?.fullName || c?.custom))
            .map(c => c?.user?.fullName ?? c?.custom ?? '') ?? [];

    return {
        names,
        namesFormatted: names.join(', '),
        isMultiple: names.length > 1,
    };
};
