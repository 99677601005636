import {ObservableQuery} from '@apollo/client';
import gql from 'graphql-tag';
import {WhoAmI} from '../../../types/graphqlTypes';
import {AppState, DispatchThunkAny, UserStatus} from '../../../types/redux';
import {logoutUser, USER_SESSION_EXPIRED} from '../../redux/actions/userActions';
import {err, log} from '../../utils/logger';
import {getApolloClient} from './initClient';

export const GM_LOGIN_USER = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password)
    }
`;

export const GM_APPLY_FOR_PUBLISHER = gql`
    mutation applyForPublisherAccount($name: String!, $email: Email!, $note: String!, $formName: String!) {
        user {
            requestCallback(name: $name, email: $email, note: $note, formName: $formName)
        }
    }
`;
export const GM_LOGOUT_USER = gql`
    mutation logout {
        logout
    }
`;

export const GQ_USER_WHOAMI = gql`
    query whoAmI {
        whoAmI {
            user
            role
            state
            fullName
            authHeader
            authCookie
        }
    }
`;

/**
 * Attempts login with username and password. Returns authToken or null
 * @param username
 * @param password
 */
export const loginWithCredentials = async (username: string, password: string): Promise<any> => {
    // login via credentials, retrieve token
    return getApolloClient()
        .mutate({
            mutation: GM_LOGIN_USER,
            variables: {username: username, password: password},
        })
        .then(response => response.data?.login);
    // no catch, throws errors
};

/**
 * Perform the logout mutation
 */
declare global {
    interface Window {
        logout: any;
    }
}
export const logout = async (): Promise<any> => {
    return getApolloClient().mutate({mutation: GM_LOGOUT_USER});
};

/**
 * Fetch the user status data
 */
export const fetchUserStatus = async (): Promise<WhoAmI> => {
    // login via credentials, retrieve token
    return getApolloClient()
        .query({
            query: GQ_USER_WHOAMI,
        })
        .then(response => response.data?.whoAmI)
        .catch(reason => err('fetchUserData API error', reason));
};

let userSubscription: ObservableQuery | null = null;

/**
 * Subscribe to periodically fetching the user status.
 * @param dispatch
 * @param getState
 * @param pollInterval
 */
export const startCheckUserStatusSubscription = (
    dispatch: DispatchThunkAny,
    getState: () => AppState,
    pollInterval = 50000,
): void => {
    userSubscription = getApolloClient().watchQuery({
        query: GQ_USER_WHOAMI,
        pollInterval,
    });
    userSubscription.subscribe({
        next: ({data}) => {
            // ignore logged out state
            if (!(getState().user?.status === UserStatus.LOGGED_IN)) {
                log('not logged in yet, skip check user status', data?.whoAmI);
                return;
            }
            // check if user IDs are equal
            if (!data?.whoAmI?.user || data?.whoAmI?.user !== getState().user?.userId) {
                err('user status polling: mismatch', data?.whoAmI?.user, getState().user?.userId);
                dispatch(logoutUser(USER_SESSION_EXPIRED));
            } else {
                log('user status polling: all good', data?.whoAmI);
            }
        },
    });
};

export const stopCheckUserStatusSubscription = (): void => {
    if (userSubscription) {
        userSubscription.stopPolling();
    }
    userSubscription = null;
};

export const applyForPublisherAccount = async (
    name: string,
    email: string,
    note: string,
    formName: string,
): Promise<any> => {
    return getApolloClient()
        .mutate({
            mutation: GM_APPLY_FOR_PUBLISHER,
            variables: {name, email, note, formName},
        })
        .then(response => response.data);
    // no catch, throws errors
};
