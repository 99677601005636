import {ApolloClient, ApolloLink, createHttpLink, DefaultOptions} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {onError} from '@apollo/client/link/error';
import graphqlFragmentTypes from '../../../types/graphqlFragmentTypes.json';
import {LOCAL_KEY_TOKEN} from '../../constants';
import {addNotification} from '../../redux/actions/notificationActions';
import {logoutUser, USER_SESSION_EXPIRED} from '../../redux/actions/userActions';
import {getStore} from '../../redux/initStore';
import {error, log} from '../../utils/logger';
import {loadLocalData} from '../../utils/storage';

let apolloClient: ApolloClient<any>;

const link = createHttpLink({
    uri: process.env.REACT_APP_POLYPUBLISHER_GRAPH_URL,
    // cookie authentication:
    // credentials: 'same-origin',
    // ensures the cookies are sent on each request (only if server is on different domain CORS):
    // credentials: 'include',
});

const defaultOptions: DefaultOptions = {
    // // disable caching
    // watchQuery: {
    //     fetchPolicy: 'no-cache',
    //     errorPolicy: 'ignore',
    // },
    // query: {
    //     fetchPolicy: 'no-cache',
    //     errorPolicy: 'all',
    // },
    query: {
        errorPolicy: 'all',
    },
};

function createApolloClient() {
    const possibleTypes = graphqlFragmentTypes.possibleTypes;
    const store = getStore();
    const cache = new InMemoryCache({
        possibleTypes,
        typePolicies: {
            Image: {
                fields: {
                    src: {
                        // Short for always preferring incoming to existing data.
                        merge: false,
                    },
                },
            },
        },
    });

    // add jwt token to every request:
    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                Authorization: loadLocalData(LOCAL_KEY_TOKEN) || null,
                PolyPlatform: 'POLYPUBLISHER',
            },
        });
        return forward(operation);
    });

    // afterware for handling auth errors
    const errorLink = onError(({graphQLErrors, networkError}) => {
        error('errorLink', graphQLErrors, networkError);
        // authentication error -> logout
        if (graphQLErrors?.filter(e => e.message === 'Token invalid').length) {
            log('errorLink logout');
            if (store) {
                store.dispatch(logoutUser(USER_SESSION_EXPIRED) as any);
            } else {
                // no store available for some reason. Make sure we can always dispatch the logout action
                error('cannot handle onError auth error, no dispatch provided');
            }
        } else if (!graphQLErrors?.length) {
            store.dispatch(
                addNotification(
                    'Ein Netzwerkfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.',
                    'error',
                ),
            );
        }
    });

    return new ApolloClient({
        cache,
        // link,
        // link: concat(authMiddleware, link),
        link: errorLink.concat(authMiddleware).concat(link),
        defaultOptions: defaultOptions,
    });
}

/**
 * Returns existing apollo client or creates new one
 * @param initialState
 */
export function initializeApollo(initialState = null): ApolloClient<any> {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // get hydrated here
    if (initialState) {
        _apolloClient.cache.restore(initialState);
    }

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

export const getApolloClient = (): ApolloClient<any> => {
    if (!apolloClient) {
        return initializeApollo();
    } else {
        return apolloClient;
    }
};
