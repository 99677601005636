import {formatInTimeZone} from 'date-fns-tz';
import de from 'date-fns/locale/de';

/**
 * Transforms date strings from YYYY-MM-DD to DD.MM.YYYY
 * @param iso8601String
 */
export const formatDateString = (iso8601String: string | null): string | null => {
    return iso8601String
        ? formatInTimeZone(new Date(iso8601String), 'Europe/Berlin', 'dd.MM.yyyy', {locale: de})
        : null;
};

/**
 * Transforms date strings from YYYY-MM-DD to HH:MM
 * @param iso8601String
 */
export const formatDateTimeString = (iso8601String: string | null): string | null => {
    return iso8601String
        ? formatInTimeZone(new Date(iso8601String), 'Europe/Berlin', 'dd.MM.yyyy, HH:mm (z)', {locale: de})
        : null;
};

/**
 * Currency formatting like 1.234.567,89€ Expects cent amount like '100' => 1€
 * @param centAmount
 */
export const formatPrice = (centAmount: number): string => {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(centAmount / 100);
};

/**
 * Number formatting like 100000 => 100.000
 * @param number
 */
export const formatNumber = (number: number): string => {
    return new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(number);
};

/**
 * Decodes and splits a global ID into its parts
 * @param globalId
 */
export const decodeGlobalId = (globalId?: string | null): {type: string; id: number} | null => {
    const parts = window.atob(globalId ?? '')?.split(':');

    if (parts?.length === 2) {
        return {
            type: parts[0],
            id: parseInt(parts[1]),
        };
    }

    return null;
};

/**
 * Encodes type and ID to a global ID
 * @param type
 * @param id
 */
export const encodeGlobalId = (type: string, id: number): string | null => {
    return window.btoa(`${type}:${id}`);
};
