import React from 'react';
import {useTranslation} from 'react-i18next';
import {ArticleSearchForm} from '../../components/articleSearch/ArticleSearchForm/ArticleSearchForm';
import {ArticleSearchResults} from '../../components/articleSearch/ArticleSearchResults/ArticleSearchResults';

export const ArticleSearchPage: React.FC = () => {
    const {t} = useTranslation();

    return (
        <>
            <h1>{t('articleSearch.title')}</h1>
            <ArticleSearchForm />
            <ArticleSearchResults />
        </>
    );
};
