import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export function GoToArticleButton(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={24} {...props}>
            <path fill="#488A91" d="M0 0h24v24H0z" />
            <path fill="#FFF" d="M8.924 8.476l1.414-1.414 5.077 5.076-5.077 5.077-1.414-1.414 3.662-3.662z" />
        </SvgIcon>
    );
}
