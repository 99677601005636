import {isDebugEnabled} from './environment';

// tslint:disable-next-line:no-any
export const debug = (message?: any, ...optionalParams: any[]): void => {
    if (isDebugEnabled()) {
        // tslint:disable-next-line:no-console
        console.debug(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const log = (message?: any, ...optionalParams: any[]): void => {
    if (isDebugEnabled()) {
        // tslint:disable-next-line:no-console
        console.log(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const warn = (message?: any, ...optionalParams: any[]): void => {
    if (isDebugEnabled()) {
        // tslint:disable-next-line:no-console
        console.warn(message, ...optionalParams);
    }
};

// tslint:disable-next-line:no-any
export const error = (message?: any, ...optionalParams: any[]): void => {
    if (isDebugEnabled()) {
        // tslint:disable-next-line:no-console
        console.error(message, ...optionalParams);
    }
};

export const err = error;
