import {SESSION_KEY_DEBUG} from '../constants';
import {loadLocalData, saveLocalData} from './storage';

let isDebug = false;

/**
 * True if global window object is defined
 */
export const isInBrowser = (): boolean => typeof window !== 'undefined';

/**
 * True if debug mode is enabled
 */
export const isDebugEnabled = (): boolean => isDebug;

/**
 * Environment-sensitive debugging. Non-production envs have debug enabled per default,
 * in production it can be enabled by adding a session storage parameter debug = true
 */
export const initDebugMode = (): void => {
    const debugSetting = loadLocalData(SESSION_KEY_DEBUG);
    if (typeof debugSetting === 'undefined' && process.env.NODE_ENV !== 'production') {
        saveLocalData(SESSION_KEY_DEBUG, true);
    }
    isDebug = isInBrowser() && !!loadLocalData(SESSION_KEY_DEBUG);
};
