import {FormikErrors} from 'formik';

/**
 * Returns true if errors occurred
 * @param formikErrorsObj
 */
export const hasFormErrors = (formikErrorsObj?: FormikErrors<any> | null): boolean => {
    return !!formikErrorsObj && Object.keys(formikErrorsObj).length > 0;
};

/**
 * Retrieve an error message from a formik error object. Can handle nested structures.
 * @param errors
 * @param path the path to the field e.g. 'user.address.city'
 */
export const getFormError = (errors?: FormikErrors<any> | null, path?: string): string | null => {
    if (!errors || !path) {
        return null;
    }
    // traverse object
    let currentEl: any = errors;
    path.split('.').map(p => (currentEl = currentEl ? currentEl[p] : null));

    return currentEl as string;
};
