import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyledDialogButton} from '../../styles/dialog';

export const ConfirmDialog: React.FC<{
    open: boolean;
    handleConfirm(): void;
    handleCancel(): void;
    title?: string;
    message: string;
}> = ({open, handleCancel, handleConfirm, title, message}) => {
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={handleCancel}>
            {!!title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleCancel} color="primary" variant="outlined">
                    {t('action.cancel')}
                </StyledDialogButton>
                <StyledDialogButton onClick={handleConfirm} color="primary" variant="contained" autoFocus>
                    {t('action.confirm')}
                </StyledDialogButton>
            </DialogActions>
        </Dialog>
    );
};
