import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export function BackArrowIcon(props: SvgIconProps): React.ReactElement<SvgIconProps> {
    return (
        <SvgIcon width={24} height={21} {...props}>
            <path d="M13.28 18.67L5.33 10.74a.643.643 0 0 1 0-.91l7.95-7.93a.643.643 0 0 1 .911 0l1.05 1.05a.636.636 0 0 1-.01.917l-5.202 5.025h13.328c.354 0 .643.29.643.643v1.5c0 .353-.29.643-.643.643H10.03l5.201 5.02a.645.645 0 0 1 .011.915l-1.05 1.05a.639.639 0 0 1-.91.006zM2.786 19.929V.643A.645.645 0 0 0 2.143 0h-1.5A.645.645 0 0 0 0 .643v19.286c0 .353.29.642.643.642h1.5c.353 0 .643-.289.643-.642z" />
        </SvgIcon>
    );
}
