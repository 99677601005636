import {useQuery} from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ArticleDownloadIcon, ArticlePreviewIcon} from '../../assets/ShoppingCartIcons';
import {ArticleDownloadDialog} from '../../components/articleDetail/ArticleDownloadDialog/ArticleDownloadDialog';
import {Preloader} from '../../components/Preloader/Preloader';
import {ROUTE_ARTICLE} from '../../lib/constants';
import {getAuthors, GQ_RETRIEVE_ARTICLE_DETAIL} from '../../lib/services/polypublisher/article';
import {GQ_RETRIEVE_PURCHASES_B2B} from '../../lib/services/polypublisher/purchase';
import {useMatomoTracking} from '../../lib/tracking/matomo';
import {formatDateString, formatDateTimeString} from '../../lib/utils/formatString';
import {useDescendingPaginatedConnectionQuery} from '../../lib/utils/paginatedConnection';
import {colors, theme} from '../../styles/theme';
import {
    Article,
    LocaleEnum,
    PurchaseB2B,
    PurchaseB2BConnection,
    PurchaseB2BEdge,
    Query,
} from '../../types/graphqlTypes';

const PurchaseItem: React.FC<{
    purchase: PurchaseB2B;
}> = ({purchase}) => {
    const {t} = useTranslation();
    const {data: articleDataRaw} = useQuery(GQ_RETRIEVE_ARTICLE_DETAIL, {
        variables: {id: purchase.articleId || '-1'},
    });
    const articleData = articleDataRaw?.article as Article;
    const currentArticleId = purchase?.articleId || '-1';
    const [isDownloadDialogVisible, setDownloadDialogVisible] = useState(false);
    const {trackEvent} = useMatomoTracking();
    const authors = getAuthors(articleData);

    const handleToggleDownloadDialog = () => {
        if (!isDownloadDialogVisible) {
            trackEvent({
                category: 'polymarket-purchases',
                action: 'click-event',
                name: 'Open Download Dialog',
            });
        }
        setDownloadDialogVisible(!isDownloadDialogVisible);
    };

    return articleData ? (
        <>
            <Paper
                sx={{
                    padding: theme.spacing(4),
                    marginBottom: theme.spacing(2),
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant="h6">
                            {articleData.preview?.headline ?? articleData.meta?.title ?? articleData.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {articleData.preview?.copy ?? articleData.meta?.description ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    spacing={2}
                >
                    <Grid item xs={12} sx={{marginTop: theme.spacing(2)}}>
                        <Typography variant="subtitle1">{t('purchase.purchaseProcessDetails')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('purchase.purchaseDate')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(purchase?.datePurchased) ?? t('article.unknown')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('purchase.purchaseNumber')}
                        </Typography>
                        <Typography variant="body1">{purchase?.purchaseNumber}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('purchase.price')}
                        </Typography>
                        <Typography variant="subtitle1" color={'primary'}>
                            {purchase.priceFormatted}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    spacing={2}
                >
                    <Grid item xs={12} sx={{marginTop: theme.spacing(2)}}>
                        <Typography variant="subtitle1">{t('purchase.purchaseLicenseDetails')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationLicense')}
                        </Typography>
                        <Typography variant="body1">
                            {purchase.licenses?.map(l => l?.title).join(', ') ?? 'keine Nutzungsrechte'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.specialPublicationRights')}
                        </Typography>
                        <Typography variant="body1" sx={{display: 'flex'}}>
                            <span>
                                {purchase.exclusivePublication
                                    ? t('article.exclusivePublication')
                                    : purchase.firstPublication
                                      ? t('article.firstPublication')
                                      : t('article.none')}
                            </span>
                            {/*<InlineInfoOverlay title={t('article.specialPublicationRights')} content={'lorem ipsum'} />*/}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{marginTop: theme.spacing(2)}}>
                        <Typography variant="subtitle1">{t('article.articleDetails')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {authors.isMultiple ? t('article.authors') : t('article.author')}
                        </Typography>
                        <Typography variant="body1">{authors.namesFormatted ?? 'unbekannt'}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.characters')}
                        </Typography>
                        <Typography variant="body1">{articleData?.characters ?? t('article.noCharLength')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.datePublication')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.publication?.datePublication) ??
                                t('article.noDatePublication')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateModification')}
                        </Typography>
                        <Typography variant="body1">
                            {formatDateString(articleData?.dateModified ?? articleData?.publication?.datePublication) ??
                                t('article.noModification')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.resort')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.resorts
                                ?.map(r => {
                                    // return !r?.doNotList ? r?.title : null
                                    return r?.title;
                                })
                                ?.join(', ') ?? t('article.noResort')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.vgwort')}
                        </Typography>
                        {articleData?.owner?.vgwort &&
                        articleData?.owner?.vgwort?.number &&
                        !articleData?.owner?.vgwort?.excluded ? (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.lastname
                                    ? articleData?.owner?.vgwort?.firstname + ' ' + articleData?.owner?.vgwort?.lastname
                                    : articleData?.owner?.fullName}
                                : {articleData?.owner?.vgwort?.number}
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                {articleData?.owner?.vgwort?.excluded
                                    ? t('article.vgwortExcluded')
                                    : t('article.vgwortNoData')}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.dateEmbargo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.dateEmbargo ? (
                                <strong style={{color: colors.red70}}>
                                    {formatDateTimeString(articleData?.publication?.dateEmbargo)}
                                </strong>
                            ) : (
                                t('article.noDateEmbargo')
                            )}
                        </Typography>
                        {articleData?.publication?.allowOwnPrePublication && (
                            <Typography variant="body1">{t('article.allowOwnPrePublication')}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationInfo')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.infoPublication ?? t('article.publicationInfoNoData')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{pb: theme.spacing(2)}}>
                        <Typography variant="body1" color="textSecondary">
                            {t('article.publicationOther')}
                        </Typography>
                        <Typography variant="body1">
                            {articleData?.publication?.otherPublication ?? t('article.publicationOtherNoData')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} style={{marginTop: 30}}>
                    <Tooltip title={t('shoppingCart.articlePreview') as string}>
                        <IconButton
                            component={Link}
                            to={`${ROUTE_ARTICLE}/${articleData.locale === LocaleEnum.DeDe ? 'de' : 'en'}/${
                                purchase.articleId
                            }`}
                            color={'primary'}
                            size="large"
                        >
                            <ArticlePreviewIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('action.download') as string}>
                        <IconButton onClick={handleToggleDownloadDialog} color={'primary'} size="large">
                            <ArticleDownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Paper>
            <ArticleDownloadDialog
                open={isDownloadDialogVisible}
                articleId={currentArticleId}
                handleClose={handleToggleDownloadDialog}
            />
        </>
    ) : null;
};

export const PurchasesPage: React.FC = () => {
    const {t} = useTranslation();

    const getConnectionFn = (data: Query) => {
        return data?.purchases?.purchasesB2B as PurchaseB2BConnection;
    };

    const {loading, error, edges, isForwardEnabled, isBackwardEnabled, handleForward, handleBackward} =
        useDescendingPaginatedConnectionQuery(
            GQ_RETRIEVE_PURCHASES_B2B,
            {
                fetchPolicy: 'no-cache', // TODO fix cache invalidation (fetchPolicy) and remove option
                variables: {ignoreCache: true},
            },
            getConnectionFn,
        );

    return error ? null : (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <h1>{t('purchase.title')}</h1>
                </Grid>
                <Grid item xs={12}>
                    {loading && <Preloader minHeight={200} />}
                    {(edges as PurchaseB2BEdge[])?.map(
                        edge => !!edge?.node && <PurchaseItem purchase={edge.node} key={edge.node.id} />,
                    )}
                    {!loading && !(edges as PurchaseB2BEdge[])?.length && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            sx={{
                                padding: theme.spacing(5, 3),
                                textAlign: 'center',
                            }}
                        >
                            {t('purchase.empty')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Button variant="outlined" color="primary" onClick={handleForward} disabled={!isForwardEnabled}>
                    {t('action.forward')}
                </Button>
                <Button variant="outlined" color="primary" onClick={handleBackward} disabled={!isBackwardEnabled}>
                    {t('action.back')}
                </Button>
            </Grid>
        </>
    );
};
