import {AppNotificationType} from '../../../types/redux';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET';

export const addNotification = (message: string, type: AppNotificationType) => {
    return {
        type: NOTIFICATION_ADD,
        payload: {message, type},
    };
};

export const removeNotification = (notificationId: number): Record<string, unknown> => {
    return {
        type: NOTIFICATION_REMOVE,
        payload: notificationId,
    };
};

export const resetNotifications = (): Record<string, unknown> => {
    return {
        type: NOTIFICATION_RESET,
    };
};
