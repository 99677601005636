import {ApolloProvider} from '@apollo/client';
import {CacheProvider} from '@emotion/react';
import {MatomoProvider} from '@jonkoops/matomo-tracker-react';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import React, {Suspense, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {I18nextProvider} from 'react-i18next';
import {Provider, ReactReduxContext, useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import {AppLayout} from './components/AppLayout/AppLayout';
import {Preloader} from './components/Preloader/Preloader';
import ProtectedRoute, {ProtectedRouteProps} from './components/ProtectedRoute/ProtectedRoute';
import i18n from './i18n';
import {
    ROUTE_AGB,
    ROUTE_APPLICATION,
    ROUTE_ARTICLE,
    ROUTE_ARTICLE_SEARCH,
    ROUTE_FAQ,
    ROUTE_HOME,
    ROUTE_IMPRINT,
    ROUTE_LOGIN,
    ROUTE_LOGOUT,
    ROUTE_PURCHASES,
    ROUTE_SHOPPING_CART,
    ROUTE_USER,
    ROUTE_USERS,
    SESSION_KEY_APP_STATE,
} from './lib/constants';
import {resetNotifications} from './lib/redux/actions/notificationActions';
import {autologinUser, initUser} from './lib/redux/actions/userActions';
import {getStore} from './lib/redux/initStore';
import {getApolloClient} from './lib/services/polypublisher/initClient';
import {logout} from './lib/services/polypublisher/user';
import {initMatomoTracking, useMatomoTracking} from './lib/tracking/matomo';
import {initDebugMode, isDebugEnabled} from './lib/utils/environment';
import {log} from './lib/utils/logger';
import {loadSessionData} from './lib/utils/storage';
import {AgbPage} from './pages/AgbPage/AgbPage';
import {ApplicationPage} from './pages/ApplicationPage/ApplicationPage';
import {ArticleDetailPage} from './pages/ArticleDetailPage/ArticleDetailPage';
import {ArticleSearchPage} from './pages/ArticleSearchPage/ArticleSearchPage';
import {FaqPage} from './pages/FaqPage/FaqPage';
import {HomePage} from './pages/HomePage/HomePage';
import {ImprintPage} from './pages/ImprintPage/ImprintPage';
import {LoginPage} from './pages/LoginPage/LoginPage';
import {LogoutPage} from './pages/LogoutPage/LogoutPage';
import {PurchasesPage} from './pages/PurchasesPage/PurchasesPage';
import {ShoppingCartPage} from './pages/ShoppingCartPage/ShoppingCartPage';
import {UserPublisherProfilePage} from './pages/UserPage/UserPage';
import createEmotionCache from './styles/createEmotionCache';
import {theme} from './styles/theme';
import {AppState, UserState, UserStatus} from './types/redux';

// lazy load pages
// TODO does not work – error missing chunks on article view (why?)
// const AgbPage = lazy(() => import('./pages/AgbPage/AgbPage').then(({AgbPage}) => ({default: AgbPage})));
// const ApplicationPage = lazy(() =>
//     import('./pages/ApplicationPage/ApplicationPage').then(({ApplicationPage}) => ({default: ApplicationPage})),
// );
// // const ArticleDetailPage = lazy(() =>
// //     import('./pages/ArticleDetailPage/ArticleDetailPage').then(({ArticleDetailPage}) => ({default: ArticleDetailPage})),
// // );
// const ArticleSearchPage = lazy(() =>
//     import('./pages/ArticleSearchPage/ArticleSearchPage').then(({ArticleSearchPage}) => ({default: ArticleSearchPage})),
// );
// const FaqPage = lazy(() => import('./pages/FaqPage/FaqPage').then(({FaqPage}) => ({default: FaqPage})));
// const HomePage = lazy(() => import('./pages/HomePage/HomePage').then(({HomePage}) => ({default: HomePage})));
// const ImprintPage = lazy(() =>
//     import('./pages/ImprintPage/ImprintPage').then(({ImprintPage}) => ({default: ImprintPage})),
// );
// const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage').then(({LoginPage}) => ({default: LoginPage})));
// const LogoutPage = lazy(() => import('./pages/LogoutPage/LogoutPage').then(({LogoutPage}) => ({default: LogoutPage})));
// const PurchasesPage = lazy(() =>
//     import('./pages/PurchasesPage/PurchasesPage').then(({PurchasesPage}) => ({default: PurchasesPage})),
// );
// const ShoppingCartPage = lazy(() =>
//     import('./pages/ShoppingCartPage/ShoppingCartPage').then(({ShoppingCartPage}) => ({default: ShoppingCartPage})),
// );
// const UserPublisherProfilePage = lazy(() =>
//     import('./pages/UserPage/UserPage').then(({UserPublisherProfilePage}) => ({default: UserPublisherProfilePage})),
// );

initDebugMode();
const store = getStore(loadSessionData(SESSION_KEY_APP_STATE));
const apolloClient = getApolloClient();
const matomoInstance = initMatomoTracking();

// use window.logout() in browser console to force logout
if (isDebugEnabled()) {
    window.logout = logout;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const InternalApp: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    // get user state
    const user = useSelector<AppState, UserState>(state => state.user);

    // run once on initialization
    useEffect(() => {
        // reset notifications
        dispatch(resetNotifications() as any);

        // attempt autologin if user isn't logged in already (via persisted redux state)
        if (user.status !== UserStatus.LOGGED_IN) {
            log('attempt autologin on initialization');
            dispatch(autologinUser(location) as any);
        } else {
            dispatch(initUser() as any);
        }
    }, []);

    // loading status
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (user.status === UserStatus.LOGIN_IN_PROGRESS) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [user.status]);

    // protected route props
    const protectedRouteProps: ProtectedRouteProps = {
        isAuthenticated: user.status === UserStatus.LOGGED_IN,
        authenticationPath: ROUTE_LOGIN,
    };

    // track page view with each pathname change
    const {trackPageView} = useMatomoTracking();
    useEffect(() => {
        trackPageView({href: location.pathname});
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <meta charSet="utf-8" />
                <title>PolyPublisher Marketplace</title>
            </Helmet>
            <AppLayout>
                {isLoading ? (
                    <Preloader />
                ) : (
                    <Suspense fallback={<Preloader />}>
                        <Routes>
                            <Route path={ROUTE_LOGIN} element={<LoginPage />} />
                            <Route path={ROUTE_LOGOUT} element={<LogoutPage />} />
                            <Route path={ROUTE_APPLICATION} element={<ApplicationPage />} />
                            <Route path={ROUTE_AGB} element={<AgbPage />} />
                            <Route path={ROUTE_IMPRINT} element={<ImprintPage />} />
                            <Route path={'/'} element={<ProtectedRoute {...protectedRouteProps} />}>
                                <Route path={ROUTE_SHOPPING_CART} element={<ShoppingCartPage />} />
                                <Route path={ROUTE_PURCHASES} element={<PurchasesPage />} />
                                <Route path={`${ROUTE_ARTICLE}/:locale/:articleId`} element={<ArticleDetailPage />} />
                                <Route path={ROUTE_ARTICLE_SEARCH} element={<ArticleSearchPage />} />
                                <Route path={`${ROUTE_USERS}/:userId`} element={<UserPublisherProfilePage />} />
                                <Route path={ROUTE_USER} element={<UserPublisherProfilePage />} />
                                <Route path={ROUTE_FAQ} element={<FaqPage />} />
                                <Route path={ROUTE_HOME} element={<HomePage />} />
                            </Route>
                        </Routes>
                    </Suspense>
                )}
            </AppLayout>
        </>
    );
};

export const App: React.FC = () => (
    <CacheProvider value={clientSideEmotionCache}>
        <Provider store={store} context={ReactReduxContext}>
            <BrowserRouter>
                <ApolloProvider client={apolloClient}>
                    <MatomoProvider value={matomoInstance}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <I18nextProvider i18n={i18n}>
                                    <InternalApp />
                                </I18nextProvider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </MatomoProvider>
                </ApolloProvider>
            </BrowserRouter>
        </Provider>
    </CacheProvider>
);
