import Box from '@mui/material/Box';
import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTE_AGB, ROUTE_IMPRINT} from '../../lib/constants';
import {theme} from '../../styles/theme';

export const AppFooter: React.FC = () => {
    return (
        <Box
            sx={{
                height: 80,
                marginTop: theme.spacing(3),
                padding: theme.spacing(3),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& a': {
                    padding: theme.spacing(2),
                },
            }}
        >
            <Link to={ROUTE_IMPRINT}>{'Impressum'}</Link> | <Link to={ROUTE_AGB}>{'AGB'}</Link>
        </Box>
    );
};
