import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, NavLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/logo.svg';
import {ROUTE_APPLICATION, ROUTE_ARTICLE_SEARCH, ROUTE_HOME, ROUTE_LOGIN} from '../../lib/constants';
import {loginUser} from '../../lib/redux/actions/userActions';
import {StyledButton} from '../../styles/notAuthenticatedUser';
import {colors, theme} from '../../styles/theme';
import {AppState, UserState, UserStatus} from '../../types/redux';

interface LoginValues {
    username: string;
    password: string;
}

const initialLoginValues: LoginValues = {
    username: '',
    password: '',
};

const validationSchema = Yup.object({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

export const LoginPage: React.FC = () => {
    const user: UserState = useSelector<AppState, UserState>(state => state.user);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();

    // form submit handler
    const onSubmit = (values: LoginValues) => {
        dispatch(loginUser(values.username, values.password) as any);
    };

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues: initialLoginValues,
        validationSchema,
        onSubmit,
        validateOnBlur: false,
    });

    // hide errorMessage on user input
    const wrappedHandleChange = (eventOrPath: any) => {
        setErrorMessage('');
        return handleChange(eventOrPath);
    };

    // redirect to search after successful login
    useEffect(() => {
        if (user.status === UserStatus.LOGGED_IN) {
            if (user.redirectLocation?.pathname === ROUTE_LOGIN) {
                navigate({pathname: ROUTE_ARTICLE_SEARCH});
            } else {
                navigate(user.redirectLocation ?? {pathname: ROUTE_ARTICLE_SEARCH});
            }
        } else if (user.status === UserStatus.LOGIN_FAILED) {
            setErrorMessage(user.errorMessage ?? '');
        }
    }, [user]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: '90vh',
                    textAlign: 'center',
                }}
            >
                <Grid item xs={12} sm={8} lg={6}>
                    <NavLink to={ROUTE_HOME}>
                        <img src={logo} alt="RiffReporter Logo" height="48" width="48" />
                    </NavLink>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <h1>{t('login.title')}</h1>
                            <h2>{t('login.subtitle')}</h2>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{padding: theme.spacing(1, 1, 1, 0)}}>
                            <TextField
                                onChange={wrappedHandleChange}
                                error={!!errors.username}
                                value={values.username}
                                name="username"
                                label={t('login.username')}
                                type={'text'}
                                autoComplete={'username'}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{padding: theme.spacing(1, 1, 1, 0)}}>
                            <TextField
                                onChange={wrappedHandleChange}
                                error={!!errors.password}
                                value={values.password}
                                name="password"
                                label={t('login.password')}
                                type="password"
                                autoComplete={'current-password'}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{padding: theme.spacing(1, 1, 1, 0)}}>
                            <StyledButton type="submit" variant="contained" color="primary" fullWidth={true}>
                                {t('login.login')}
                            </StyledButton>
                        </Grid>
                        <Grid item xs={12} sx={{padding: theme.spacing(1, 1, 1, 0)}}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: colors.red70,
                                    fontWeight: 'bold',
                                }}
                            >
                                {errorMessage}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    textDecoration: 'underline',
                                    margin: theme.spacing(2, 2, 2, 0),
                                }}
                            >
                                <a
                                    href="mailto:info@riffreporter.de?subject=Passwort%20vergessen"
                                    color="secondary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('login.forgotPassword')}
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{padding: theme.spacing(1, 1, 1, 0)}}>
                            <h2>{t('login.notRegisteredYet')}</h2>
                            <Typography variant="body1">
                                <Link
                                    color="primary"
                                    component={RouterLink}
                                    to={ROUTE_APPLICATION}
                                    sx={{
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        margin: theme.spacing(2, 1),
                                    }}
                                >
                                    {t('login.userApplication')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
