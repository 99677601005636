import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {colors, theme} from '../../../styles/theme';
import {ParagraphQuote as DataType} from '../../../types/graphqlTypes';

interface ParagraphQuoteProps {
    data: DataType;
}

export const ParagraphQuote: React.FC<ParagraphQuoteProps> = ({data}) => {
    const {marketing, funding, quote, caption, cite} = data;

    if (marketing == true || funding == true) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                lineHeight: 1.8,
                fontSize: '1.2rem',
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                <Box
                    sx={{
                        paddingTop: theme.spacing(3),
                        paddingBottom: theme.spacing(3),
                        '& blockquote': {
                            fontSize: 22,
                            lineHeight: 2.111,
                            letterSpacing: '.05rem',
                            margin: 0,
                            padding: theme.spacing(2),
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            textAlign: 'center',
                            backgroundImage: `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg"><path fill="rgba(0,0,0,0.05)" d="M38.797 135C59.524 135 76 121.181 76 102.047c0-20.728-14.881-29.232-24.979-30.295-7.44-.532-11.16-4.783-11.16-11.161C39.86 38.268 64.307 10.098 73.342 0h-24.98C40.393 8.504 0 48.898 0 92.48 0 116.398 15.944 135 38.797 135zm98.532 0C157.524 135 174 121.181 174 102.047c0-20.728-14.35-29.232-24.979-30.295-6.91-.532-11.16-4.783-11.16-11.161 0-22.323 24.447-50.493 33.482-60.591h-24.98C138.393 8.504 98 48.898 98 92.48c0 23.918 15.944 42.52 39.329 42.52z" /></svg>')`,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                margin: '0 auto',
                                width: '50%',
                                paddingBottom: theme.spacing(1),
                                color: colors.turquoise50,
                                borderColor: colors.turquoise50,
                                borderTop: '5px solid',
                            },
                        },
                        '& figcaption': {
                            fontSize: 14,
                            textAlign: 'center',
                        },
                    }}
                >
                    {cite
                        ? quote && <blockquote cite={cite ?? ''} dangerouslySetInnerHTML={{__html: quote}} />
                        : quote && <blockquote dangerouslySetInnerHTML={{__html: quote}} />}
                    {caption && <figcaption dangerouslySetInnerHTML={{__html: caption}} />}
                </Box>
            </Grid>
        </Grid>
    );
};
