import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {removeNotification} from '../../lib/redux/actions/notificationActions';
import {theme} from '../../styles/theme';
import {AppNotification, AppNotificationState, AppState} from '../../types/redux';

export const Notification: FC<AppNotification> = ({id, message, type}) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(removeNotification(id) as any);
    };

    return (
        <Alert
            onClose={handleClose}
            severity={type}
            sx={{
                color: '#fff',
                backgroundColor: '#4b4b4d',
                borderRadius: 0,
                marginBottom: theme.spacing(2),
            }}
        >
            {message}
        </Alert>
    );
};

export const NotificationList: FC = () => {
    const notification = useSelector<AppState, AppNotificationState>(state => state.notification);

    return notification.notifications.length ? (
        <Box sx={{position: 'absolute', width: '100%', top: 0, left: 0, zIndex: 3000, padding: theme.spacing(3)}}>
            {notification.notifications.map((n, i) => (
                <Notification {...n} key={i} />
            ))}
        </Box>
    ) : null;
};
