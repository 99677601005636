import {MutationTuple, useMutation} from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {
    GM_UPDATE_USER_PUBLISHER_PROFILE,
    GQ_RETRIEVE_USER_PUBLISHER_PROFILE,
    UserPublisherProfileData,
    userPublisherProfileToMutationInput,
} from '../../lib/services/polypublisher/userPublisherProfile';
import {useMatomoTracking} from '../../lib/tracking/matomo';
import {getFormError, hasFormErrors} from '../../lib/utils/form';
import {err, log} from '../../lib/utils/logger';
import {StyledDialogButton} from '../../styles/dialog';
import {colors} from '../../styles/theme';
import {AuthenticationRoleEnum, Mutation, UserMutationUpdateArgs} from '../../types/graphqlTypes';
import {ProgressOverlay, ProgressState} from '../ProgressOverlay/ProgressOverlay';

interface EditCartItemFormProps {
    open: boolean;
    initialValues: UserPublisherProfileData;
    handleClose(): void;
}

export const UserEditDialog: React.FC<EditCartItemFormProps> = ({open, handleClose, initialValues}) => {
    const {t} = useTranslation();
    const [isBillingAddressEnabled, setBillingAddressEnabled] = React.useState(!!initialValues.billingAddress);
    const [updateProfile] = useMutation<MutationTuple<Mutation, UserMutationUpdateArgs>>(
        GM_UPDATE_USER_PUBLISHER_PROFILE,
    );
    const [isUpdateDone, setUpdateDone] = useState(false);
    const {trackEvent} = useMatomoTracking();

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('formValidation.required') ?? ''),
        lastName: Yup.string().required(t('formValidation.required') ?? ''),
        email: Yup.object().shape({
            email: Yup.string()
                .email()
                .required(t('formValidation.required') ?? '')
                .nullable(),
        }),
        billingEmail: Yup.object().nullable().default(null).shape({
            email: Yup.string().email().nullable(),
        }),
        website: Yup.object()
            .nullable()
            .default(null)
            .shape({
                url: Yup.string()
                    .url()
                    .required(t('formValidation.required') ?? '')
                    .nullable(),
            }),
        telephone: Yup.object().shape({
            value: Yup.string()
                .required(t('formValidation.required') ?? '')
                .nullable(),
        }),
        postalAddress: Yup.object({
            line1: Yup.string().required(t('formValidation.required') ?? ''),
            postcode: Yup.string().required(t('formValidation.required') ?? ''),
            city: Yup.string().required(t('formValidation.required') ?? ''),
        }).required(t('formValidation.required') ?? ''),
        billingAddress: Yup.object()
            .nullable()
            .default(null)
            .shape({
                line1: Yup.string().required(t('formValidation.required') ?? ''),
                postcode: Yup.string().required(t('formValidation.required') ?? ''),
                city: Yup.string().required(t('formValidation.required') ?? ''),
            }),
    });

    // form submit handler, send gql mutation
    const onSubmit = async (values: UserPublisherProfileData) => {
        log('handleSubmit values', values, isBillingAddressEnabled);
        trackEvent({
            category: 'polymarket-userprofile',
            action: 'click-event',
            name: 'Edit user profile',
        });
        updateProfile({
            variables: userPublisherProfileToMutationInput(values, isBillingAddressEnabled),
            refetchQueries: [
                {
                    query: GQ_RETRIEVE_USER_PUBLISHER_PROFILE,
                    variables: {
                        ignoreCache: true,
                        role: [AuthenticationRoleEnum.Owner],
                    },
                },
            ],
            // awaitRefetchQueries: true,
        })
            .then(res => {
                log('updateUserItem success', res);
                setUpdateDone(true);
                setTimeout(handleClose, 1500);
            })
            .catch(error => {
                err('UserPage update profile error', error);
            });
    };

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    // enable / disable billing address
    const toggleShowBillingAddress = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setBillingAddressEnabled(event.target.checked);
        values.billingAddress = values.postalAddress;
        handleChange(event);
    };

    if (hasFormErrors(errors)) {
        log('InternalForm errors', errors);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{t('userEditDialog.title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.firstName ?? ''}
                                name="firstName"
                                label={t('userPublisherProfile.firstName')}
                                fullWidth={true}
                                type={'text'}
                            />
                            {errors.firstName ? (
                                <Typography variant="body1" sx={{color: colors.red70}}>
                                    {errors.firstName}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.lastName ?? ''}
                                name="lastName"
                                label={t('userPublisherProfile.lastName')}
                                type={'text'}
                                fullWidth={true}
                            />
                            {errors.lastName ? (
                                <Typography variant="body1" sx={{color: colors.red70}}>
                                    {errors.lastName}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.email?.email ?? ''}
                                name="email.email"
                                label={t('userPublisherProfile.email')}
                                fullWidth={true}
                                type={'email'}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'email.email')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.billingEmail?.email ?? ''}
                                name="billingEmail.email"
                                label={t('userPublisherProfile.billingEmail')}
                                fullWidth={true}
                                type={'email'}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'billingEmail.email')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.website?.url ?? ''}
                                name="website.url"
                                label={t('userPublisherProfile.website')}
                                fullWidth={true}
                                type={'text'}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'website.url')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.telephone?.value ?? ''}
                                name="telephone.value"
                                label={t('userPublisherProfile.telephone')}
                                type={'phone'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'telephone.value')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>{t('userPublisherProfile.address')}</h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.postalAddress?.line1 ?? ''}
                                name="postalAddress.line1"
                                label={t('userPublisherProfile.line1')}
                                type={'text'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'postalAddress.line1')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.postalAddress?.line2 ?? ''}
                                name="postalAddress.line2"
                                label={t('userPublisherProfile.line2')}
                                type={'text'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'postalAddress.line2')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.postalAddress?.line3 ?? ''}
                                name="postalAddress.line3"
                                label={t('userPublisherProfile.line3')}
                                type={'text'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'postalAddress.line3')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.postalAddress?.postcode ?? ''}
                                name="postalAddress.postcode"
                                label={t('userPublisherProfile.postcode')}
                                type={'text'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'postalAddress.postcode')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={handleChange}
                                value={values.postalAddress?.city ?? ''}
                                name="postalAddress.city"
                                label={t('userPublisherProfile.city')}
                                type={'text'}
                                fullWidth={true}
                            />
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {getFormError(errors, 'postalAddress.city')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isBillingAddressEnabled}
                                        onChange={toggleShowBillingAddress}
                                        value="primary"
                                        color="primary"
                                        name="isBillingAddressEnabled"
                                    />
                                }
                                label={t('userPublisherProfile.billingAddress')}
                            />
                        </Grid>
                    </Grid>

                    {isBillingAddressEnabled ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3>{t('userPublisherProfile.billingAddress')}</h3>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handleChange}
                                        value={values.billingAddress?.line1 ?? ''}
                                        name="billingAddress.line1"
                                        label={t('userPublisherProfile.line1')}
                                        type={'text'}
                                        fullWidth={true}
                                    />
                                    <Typography variant="body1" sx={{color: colors.red70}}>
                                        {getFormError(errors, 'billingAddress.line1')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handleChange}
                                        value={values.billingAddress?.line2 ?? ''}
                                        name="billingAddress.line2"
                                        label={t('userPublisherProfile.line2')}
                                        type={'text'}
                                        fullWidth={true}
                                    />
                                    <Typography variant="body1" sx={{color: colors.red70}}>
                                        {getFormError(errors, 'billingAddress.line2')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handleChange}
                                        value={values.billingAddress?.line3 ?? ''}
                                        name="billingAddress.line3"
                                        label={t('userPublisherProfile.line3')}
                                        type={'text'}
                                        fullWidth={true}
                                    />
                                    <Typography variant="body1" sx={{color: colors.red70}}>
                                        {getFormError(errors, 'billingAddress.line3')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handleChange}
                                        value={values.billingAddress?.postcode ?? ''}
                                        name="billingAddress.postcode"
                                        label={t('userPublisherProfile.postcode')}
                                        type={'text'}
                                        fullWidth={true}
                                    />
                                    <Typography variant="body1" sx={{color: colors.red70}}>
                                        {getFormError(errors, 'billingAddress.postcode')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        onChange={handleChange}
                                        value={values.billingAddress?.city ?? ''}
                                        name="billingAddress.city"
                                        label={t('userPublisherProfile.city')}
                                        type={'text'}
                                        fullWidth={true}
                                    />
                                    <Typography variant="body1" sx={{color: colors.red70}}>
                                        {getFormError(errors, 'billingAddress.city')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleClose} color="primary" variant="outlined" autoFocus>
                        {t('action.close')}
                    </StyledDialogButton>
                    <StyledDialogButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={hasFormErrors(errors)}
                    >
                        {t('userPublisherProfile.save')}
                    </StyledDialogButton>
                </DialogActions>
                <ProgressOverlay
                    isOpen={isUpdateDone}
                    handleClick={handleClose}
                    message={t('userPublisherProfile.profileUpdated')}
                    state={ProgressState.SUCCESS}
                />
            </form>
        </Dialog>
    );
};
