import {configureStore} from '@reduxjs/toolkit';
import {Store} from 'redux';
import {AppState} from '../../types/redux';
import {listArticleReducer} from './reducers/listArticleReducer';
import {notificationReducer} from './reducers/notificationReducer';
import {searchArticleReducer} from './reducers/searchArticleReducer';
import {userReducer} from './reducers/userReducer';

let store: Store | null = null;

export function initStore(preloadedState?: AppState): Store {
    return configureStore({
        reducer: {
            articleCount: listArticleReducer,
            search: searchArticleReducer as any,
            user: userReducer,
            notification: notificationReducer,
        },
        preloadedState,
    });
}

export const getStore = (preloadedState?: AppState): Store => {
    if (!store) {
        store = initStore(preloadedState);
    }

    return store;
};
