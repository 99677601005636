import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import {StyledContainerWrapper} from '../../styles/simplePage';

const StyledTypography = styled(Typography)(({theme}) => ({
    lineHeight: 1.8,
    marginBottom: theme.spacing(3),
}));

export const ImprintPage: React.FC = () => {
    return (
        <StyledContainerWrapper>
            <Grid container>
                <Grid item xs={12} md={10} lg={8}>
                    <h2>Impressum</h2>
                    <StyledTypography variant="body1" gutterBottom>
                        Angaben gemäß § 5 TMG
                        <br />
                        <br />
                        RiffReporter – die Genossenschaft für freien Journalismus eG
                        <br />
                        Buchtstraße 13
                        <br />
                        28195 Bremen
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Vertreten durch:</strong>
                        <br />
                        Tanja Krämer (Vorstand)
                        <br />
                        Stefan Johannesberg (Vorstand)
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Aufsichtsrat:</strong>
                        <br />
                        Rainer Kurlemann (Vorsitzender)
                        <br />
                        Peter Spork (stellv. Vorsitzender)
                        <br />
                        Christiane Schulzki-Haddouti
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        Gerichtsstand Bremen
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Handelsregister:</strong>
                        <br />
                        Amtsgericht Bremen
                        <br />
                        GnR 440 HB
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Umsatzsteuer-ID:</strong>
                        <br />
                        DE314217878
                        <br />
                        <br />
                        <strong>
                            Mitglied im Prüfungsverband der Deutschen Verkehrs-, Dienstleistungs- und
                            Konsumgenossenschaften e.V., Gotenstraße 17, 20097 Hamburg
                        </strong>
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Kontakt:</strong>
                        <br />
                        Telefon: +49 (0) 421 2435 9394
                        <br />
                        E-Mail: <a href="mailto:info@riffreporter.de">info@riffreporter.de</a>
                    </StyledTypography>
                    <StyledTypography variant="body1" gutterBottom>
                        <strong>Verbraucherstreitbeilegung</strong>
                        <br />
                        Wir sind dazu bereit, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                        teilzunehmen.
                        <br />
                        <br />
                        Angaben zu Anschrift und Webseite der Verbraucherschlichtungsstelle:
                        <br />
                        Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.
                        <br />
                        Straßburger Str. 8
                        <br />
                        77694 Kehl
                        <br />
                        <br />
                        <a href="https://www.verbraucher-schlichter.de" rel="noreferrer noopener" target="_blank">
                            www.verbraucher-schlichter.de
                        </a>
                        <br />
                        <br />
                        Wir erklären, an einem Streitbeilegungsverfahren vor dieser Verbraucherschlichtungsstelle
                        teilzunehmen.
                        <br />
                        <br />
                        Hinweis auf EU-Streitschlichtung
                        <br />
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                        <br />
                        <a href="https://ec.europa.eu/consumers/odr/main" rel="noreferrer noopener" target="_blank">
                            ec.europa.eu/consumers/odr/main
                        </a>
                        <br />
                        <br />
                        Unsere E-Mail-Adresse finden sie oben im Impressum.
                    </StyledTypography>
                </Grid>
            </Grid>
        </StyledContainerWrapper>
    );
};
