import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {colors, theme} from '../../../styles/theme';
import {ParagraphAccordion as DataType, ParagraphText, ParagraphTypeEnum} from '../../../types/graphqlTypes';

interface ParagraphAccordionProps {
    data: DataType;
}

export const ParagraphAccordion: React.FC<ParagraphAccordionProps> = ({data}) => {
    const {marketing, funding, items} = data;
    const {t} = useTranslation();

    if (marketing == true || funding == true) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                fontSize: 18,
                lineHeight: 1.8,
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                <Box
                    sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: colors.turquoise50,
                        padding: theme.spacing(2),
                        paddingTop: theme.spacing(1),
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        '& h2': {
                            color: colors.turquoise50,
                        },
                        '& a': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    <p>
                        <em>
                            <strong>{t('article.accordionTitle')}</strong>
                        </em>
                    </p>
                    {items?.map((accordionItem, index) => {
                        const hasParagraphText = accordionItem?.paragraphs?.find(paragraph => {
                            return paragraph?.type === ParagraphTypeEnum.Text;
                        });
                        return hasParagraphText ? (
                            <Box key={`accordion${index}`}>
                                <strong>{accordionItem?.title}</strong>
                                {accordionItem?.paragraphs?.map((paragraph, paragraphIndex) => {
                                    return paragraph?.type === ParagraphTypeEnum.Text ? (
                                        <Box
                                            key={`accordion${index}-paragraph${paragraphIndex}`}
                                            dangerouslySetInnerHTML={{
                                                __html: (paragraph as ParagraphText)?.html,
                                            }}
                                            sx={{
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        />
                                    ) : null;
                                })}
                            </Box>
                        ) : null;
                    })}
                </Box>
            </Grid>
        </Grid>
    );
};
