import React from 'react';
import {createRoot} from 'react-dom/client';
import {App} from './App';
import './index.css';

const container = document.getElementById('root');
if (!container) {
    throw Error('Root element is missing!');
}
const root = createRoot(container);

root.render(<App />);
