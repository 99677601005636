import {Action} from 'redux';
import {LIST_ARTICLES_DECREMENT, LIST_ARTICLES_INCREMENT, LIST_ARTICLES_RESET} from '../actions/listArticleActions';

export const listArticleReducer = (state = 10, action: Action): number => {
    switch (action.type) {
        case LIST_ARTICLES_INCREMENT:
            return state + 10;
        case LIST_ARTICLES_DECREMENT:
            return state - 10;
        case LIST_ARTICLES_RESET:
            return 10;
        default:
            return state;
    }
};
