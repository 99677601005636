import Slider, {SliderProps} from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import React from 'react';
import {colors} from '../../../styles/theme';

interface RangeSliderProps extends SliderProps {
    setFieldValue(field: string, value: any, shouldValidate?: boolean | undefined): any;
    value: number | number[];
    valueRange: number[];
    valueName: string[];
}

const StyledSlider = styled(Slider)(({theme}) => ({
    color: colors.turquoise50,
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 28,
        width: 28,
        backgroundColor: colors.white,
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            '@media (hover: none)': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -2,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? colors.white : colors.black,
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));

export const RangeSlider: React.FC<RangeSliderProps> = ({
    value = [20, 40],
    valueRange = [0, 100],
    setFieldValue,
    valueName,
    ...sliderProps
}) => {
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        valueName.forEach((n, i) => setFieldValue(n, Array.isArray(newValue) ? newValue[i] : newValue));
    };

    return (
        <StyledSlider
            {...sliderProps}
            value={value}
            min={valueRange[0]}
            max={valueRange[1]}
            onChange={handleSliderChange}
        />
    );
};
