import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import React from 'react';
import {colors, theme} from '../../styles/theme';

export enum ProgressState {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface ProgressOverlayProps {
    state: ProgressState;
    isOpen?: boolean;
    message?: string;
    icon?: JSX.Element;
    handleClick?(): void;
}

export const ProgressOverlay: React.FC<ProgressOverlayProps> = ({
    isOpen = false,
    state,
    message,
    icon,
    handleClick,
}) => {
    const getIcon = (state: ProgressState, icon?: JSX.Element) => {
        if (icon) return icon;

        switch (state) {
            case ProgressState.LOADING:
                return (
                    <CircularProgress
                        sx={{
                            width: 36,
                            height: 36,
                            margin: 10,
                        }}
                    />
                );
            case ProgressState.SUCCESS:
                return (
                    <DoneIcon
                        sx={{
                            width: 36,
                            height: 36,
                            margin: 10,
                        }}
                    />
                );
            case ProgressState.ERROR:
                return (
                    <WarningIcon
                        sx={{
                            width: 36,
                            height: 36,
                            margin: 10,
                        }}
                    />
                );
        }
    };

    return (
        <Fade in={isOpen}>
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: colors.white,
                    cursor: handleClick ? 'pointer' : 'default',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: theme.spacing(2),
                    }}
                >
                    {getIcon(state, icon)}
                    {!!message && <Typography>{message}</Typography>}
                </Box>
            </Box>
        </Fade>
    );
};
