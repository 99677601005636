import {ApolloError, MutationTuple, useApolloClient, useMutation, useQuery} from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {GQ_RETRIEVE_ARTICLE_PRICE_DETAILS} from '../../../lib/services/polypublisher/article';
import {GM_ADD_TO_SHOPPING_CART, GQ_RETRIEVE_SHOPPING_CART} from '../../../lib/services/polypublisher/shoppingCart';
import {useMatomoTracking} from '../../../lib/tracking/matomo';
import {decodeGlobalId} from '../../../lib/utils/formatString';
import {error, log} from '../../../lib/utils/logger';
import {StyledDialogButton, StyledFormLabel} from '../../../styles/dialog';
import {colors, theme} from '../../../styles/theme';
import {
    Article,
    ArticlePrice,
    AuthenticationRoleEnum,
    License,
    Mutation,
    MutationAddToShoppingCartArgs,
    Resort,
} from '../../../types/graphqlTypes';
import {Preloader} from '../../Preloader/Preloader';
import {ProgressOverlay, ProgressState} from '../../ProgressOverlay/ProgressOverlay';

/**
 * Returns true if at least one license is selected
 * @param licenses
 */
export const hasSelectedLicense = (licenses: LicenseSelection[] | undefined): boolean =>
    licenses === undefined ? false : licenses.filter(l => l.selected).length > 0;

/**
 * Returns an array of those license keys that are selected
 * @param licenses
 */
export const getSelectedLicenseKeys = (licenses: LicenseSelection[]): (string | null | undefined)[] =>
    licenses.filter(l => l.selected).map(l => l.key);

/**
 * Formatted undefined price
 */
export const PRICE_UNDEFINED = '--,-- €';

export interface LicenseSelection extends License {
    selected: boolean;
}

export interface ResortSelection extends Resort {
    selected: boolean;
}

interface FormValues {
    shoppingCartId: string;
    articleId: string;
    licenses: LicenseSelection[];
    firstPublicationAvailable: boolean;
    exclusivePublicationAvailable: boolean;
    specialPublication: 'first' | 'exclusive' | 'none';
}

interface AddToCartFormProps {
    initialValues: FormValues;
    articleData: Article;
    handleClose(): void;
}

interface AddToCartDialogProps {
    open: boolean;
    articleData: Article;
    handleClose(): void;
}

const AddToCartForm: React.FC<AddToCartFormProps> = ({initialValues, articleData, handleClose}) => {
    const {t} = useTranslation();
    const client = useApolloClient();
    const [priceFormatted, setPriceFormatted] = useState(PRICE_UNDEFINED);
    const [addToCart] = useMutation<MutationTuple<Mutation, MutationAddToShoppingCartArgs>>(GM_ADD_TO_SHOPPING_CART);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [isArticleAdded, setArticleAdded] = useState(false);
    const {trackEvent} = useMatomoTracking();

    // submit handler - add item to cart
    const onSubmit = (values: FormValues) => {
        trackEvent({
            category: 'polymarket-shoppingcart',
            action: 'click-event',
            name: 'Add article to shopping cart',
            value: decodeGlobalId(values.articleId)?.id,
        });
        addToCart({
            variables: {
                shoppingCartId: values.shoppingCartId,
                articleId: values.articleId,
                licenses: getSelectedLicenseKeys(values.licenses),
                firstPublication: values.specialPublication === 'first',
                exclusivePublication: values.specialPublication === 'exclusive',
            },
            refetchQueries: [
                {
                    query: GQ_RETRIEVE_SHOPPING_CART,
                    variables: {
                        ignoreCache: true,
                        role: [AuthenticationRoleEnum.Owner],
                    },
                },
            ], // update shopping cart
        })
            .then(res => {
                log('addToCart success', res);
                setArticleAdded(true);
                setTimeout(handleClose, 1500);
            })
            .catch((err: ApolloError) => {
                error('addToCart error', err);
                setSubmitError(err.message);
            });
    };

    // validation schema
    const validationSchema = Yup.object({
        licenses: Yup.array().test(
            'licensesNotEmpty',
            t('addToCartDialog.mustSelectALicense') ?? '',
            hasSelectedLicense,
        ),
    });

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    // form change handler - update price
    useEffect(() => {
        if (!hasSelectedLicense(values.licenses)) {
            setPriceFormatted(PRICE_UNDEFINED);
        } else {
            client
                .query({
                    query: GQ_RETRIEVE_ARTICLE_PRICE_DETAILS,
                    variables: {
                        id: values.articleId,
                        firstPublication: values.specialPublication === 'first',
                        exclusivePublication: values.specialPublication === 'exclusive',
                        licenses: getSelectedLicenseKeys(values.licenses),
                    },
                    fetchPolicy: 'no-cache', // always refetch price from backend
                })
                .then(res => {
                    const newPrice = res.data?.article?.prices?.slice().shift();
                    setPriceFormatted(newPrice?.priceFormatted);
                    setSubmitError(null);
                })
                .catch((err: ApolloError) => {
                    error('update price error', err);
                    setSubmitError(err.message);
                });
        }
    }, [values]);

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle>{t('addToCartDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('addToCartDialog.description', {title: articleData.title})}</DialogContentText>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            error={!!errors.licenses}
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <StyledFormLabel component="legend">{t('article.publicationLicense')}</StyledFormLabel>
                            <FormGroup>
                                {values.licenses.map((lcs: LicenseSelection, i: number) => {
                                    return (
                                        <FormControlLabel
                                            key={lcs.key ?? i}
                                            control={
                                                <Checkbox
                                                    checked={lcs.selected}
                                                    onChange={handleChange}
                                                    name={`licenses[${i}].selected`}
                                                />
                                            }
                                            label={lcs.title}
                                            sx={{color: errors.licenses ? colors.red70 : 'inherit'}}
                                        />
                                    );
                                })}
                            </FormGroup>
                            {!!errors.licenses && (
                                <FormHelperText>
                                    <>{errors.licenses}</>
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <StyledFormLabel component="legend">
                                {t('article.specialPublicationRights')}
                            </StyledFormLabel>
                            <RadioGroup
                                aria-label="specialPublication"
                                name="specialPublication"
                                value={values.specialPublication}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="none" control={<Radio />} label={t('article.none')} />
                                <FormControlLabel
                                    value="first"
                                    control={<Radio />}
                                    label={t('article.firstPublication')}
                                    disabled={!values.firstPublicationAvailable}
                                />
                                <FormControlLabel
                                    value="exclusive"
                                    control={<Radio />}
                                    label={t('article.exclusivePublication')}
                                    disabled={!values.exclusivePublicationAvailable}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {!!submitError && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {submitError}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {t('article.price')}
                        </Typography>
                        <Typography variant="subtitle1" color={'primary'}>
                            {priceFormatted}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleClose} color="secondary" variant="outlined" autoFocus>
                    {t('action.cancel')}
                </StyledDialogButton>
                <StyledDialogButton type="submit" color="primary" variant="contained" autoFocus>
                    {t('action.confirm')}
                </StyledDialogButton>
            </DialogActions>
            <ProgressOverlay
                isOpen={isArticleAdded}
                state={ProgressState.SUCCESS}
                handleClick={handleClose}
                message={t('addToCartDialog.articleAdded')}
            />
        </form>
    );
};

export const AddToCartDialog: React.FC<AddToCartDialogProps> = ({open, articleData, handleClose}) => {
    const [skip, setSkip] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState<FormValues | null>(null);
    // load shopping cart
    const {data: shoppingCartData} = useQuery(GQ_RETRIEVE_SHOPPING_CART, {
        variables: {
            ignoreCache: true,
            role: [AuthenticationRoleEnum.Owner],
        },
    });
    // load article price details
    const {data: articlePriceData} = useQuery(GQ_RETRIEVE_ARTICLE_PRICE_DETAILS, {
        variables: {
            id: articleData.id || '-1',
            firstPublication: false,
            exclusivePublication: false,
        },
        skip, // retrieve data only once on dialog initialization
    });

    // set initial price and options
    React.useEffect(() => {
        // only set initialValues when data has been loaded
        if (articlePriceData && shoppingCartData) {
            setSkip(true);
            const shoppingCartId = shoppingCartData?.user?.openShoppingCart?.id ?? '-1';
            const priceB2B = articlePriceData?.article?.prices?.slice().shift() as ArticlePrice;
            const pubLicensesAvailable = priceB2B?.licenses ?? [];
            const pubLicensesSelected = priceB2B?.licensesSelected ?? [];
            setInitialValues({
                shoppingCartId: shoppingCartId,
                articleId: articleData.id,
                licenses: pubLicensesAvailable.map(availableLcs => {
                    return {
                        ...(availableLcs ?? {id: ''}),
                        selected: !!pubLicensesSelected.filter(selectedLcs => selectedLcs?.key === availableLcs?.key)
                            .length,
                    };
                }),
                firstPublicationAvailable: priceB2B?.rightFirstPublicationAvailable ?? false,
                exclusivePublicationAvailable: priceB2B?.rightExclusivePublicationAvailable ?? false,
                specialPublication: 'none',
            });
        }
    }, [articlePriceData, shoppingCartData]);

    return (
        <Dialog open={open} onClose={handleClose}>
            {initialValues ? (
                <AddToCartForm articleData={articleData} initialValues={initialValues} handleClose={handleClose} />
            ) : (
                <Preloader />
            )}
        </Dialog>
    );
};
