import {Location} from 'history';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {LicenseSelection} from '../components/shoppingCart/AddToCartDialog/AddToCartDialog';
import {LocaleEnum, OrderDirectionEnum, OrderTypeEnum, ResortEnum} from './graphqlTypes';

export type DispatchThunkAny = ThunkDispatch<Promise<any>, void, Action<string>>;

export enum UserStatus {
    ANONYMOUS = 'ANONYMOUS',
    LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
}
export interface UserState {
    authToken: unknown;
    status: UserStatus;
    errorMessage: string | null;
    userId: string | null;
    redirectLocation: Location | null;
}

export interface ArticleSearchAction {
    type: string;
    payload: {
        searchTerm: string;
        dateSince: string;
        dateUntil: string;
        charactersMin: number;
        charactersMax: number;
        resort: ResortEnum | 'ALL';
        localeEnum: LocaleEnum;
        useBlacklist: boolean;
        useBlacklistType: string;
    };
}

export const RESORT_ALL = 'ALL';

export interface ArticleSearchState {
    hasError: boolean;
    searchTerm?: string | null;
    dateFrom?: string | null;
    dateTo?: string | null;
    charactersMin?: number | null;
    charactersMax?: number | null;
    licenses?: LicenseSelection[] | null;
    resort?: ResortEnum | null | typeof RESORT_ALL;
    orderType?: OrderTypeEnum | null;
    orderDirection?: OrderDirectionEnum | null;
    localeEnum: LocaleEnum;
    useBlacklist: boolean;
    useBlacklistType: string;
}

export type AppNotificationType = 'success' | 'info' | 'warning' | 'error';

export interface AppNotification {
    id: number;
    message: string;
    type: AppNotificationType;
}

export interface AppNotificationState {
    notifications: AppNotification[];
    lastId: number;
}

export interface AppState {
    articleCount: number;
    search: ArticleSearchState;
    user: UserState;
    notification: AppNotificationState;
}

export interface AppStore {
    dispatch(action: any): any;
    getState(): AppState;
}
