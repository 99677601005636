import {ApolloError, MutationTuple, useApolloClient, useMutation, useQuery} from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {GQ_RETRIEVE_ARTICLE_PRICE_DETAILS} from '../../../lib/services/polypublisher/article';
import {
    GM_UPDATE_SHOPPING_CART_ITEM,
    GQ_RETRIEVE_SHOPPING_CART,
} from '../../../lib/services/polypublisher/shoppingCart';
import {useMatomoTracking} from '../../../lib/tracking/matomo';
import {decodeGlobalId} from '../../../lib/utils/formatString';
import {error, log} from '../../../lib/utils/logger';
import {StyledDialogButton, StyledFormLabel} from '../../../styles/dialog';
import {colors, theme} from '../../../styles/theme';
import {
    ArticlePrice,
    AuthenticationRoleEnum,
    Mutation,
    MutationUpdateShoppingCartItemArgs,
    ShoppingCartItem,
} from '../../../types/graphqlTypes';
import {Preloader} from '../../Preloader/Preloader';
import {ProgressOverlay, ProgressState} from '../../ProgressOverlay/ProgressOverlay';
import {
    getSelectedLicenseKeys,
    hasSelectedLicense,
    LicenseSelection,
    PRICE_UNDEFINED,
} from '../AddToCartDialog/AddToCartDialog';

interface FormValues {
    licenses: LicenseSelection[];
    firstPublicationAvailable: boolean;
    exclusivePublicationAvailable: boolean;
    specialPublication: 'first' | 'exclusive' | 'none';
}

interface EditCartItemFormProps {
    articleId: string;
    articleTitle: string;
    shoppingCartItemId: string;
    initialValues: FormValues;
    initialPrice: string;
    handleClose(): void;
}

interface EditCartItemDialogProps {
    shoppingCartId: string;
    articleId: string;
    articleTitle: string;
    cartItemData: ShoppingCartItem;
    open: boolean;
    handleClose(): void;
}

const EditCartItemForm: React.FC<EditCartItemFormProps> = ({
    initialValues,
    initialPrice,
    articleId,
    articleTitle,
    shoppingCartItemId,
    handleClose,
}) => {
    const {t} = useTranslation();
    const [priceFormatted, setPriceFormatted] = useState(initialPrice);
    const [isArticleUpdated, setArticleUpdated] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const client = useApolloClient();
    const [updateCartItem] =
        useMutation<MutationTuple<Mutation, MutationUpdateShoppingCartItemArgs>>(GM_UPDATE_SHOPPING_CART_ITEM);
    const {trackEvent} = useMatomoTracking();

    // submit handler - add item to cart
    const onSubmit = (values: FormValues) => {
        trackEvent({
            category: 'polymarket-shoppingcart',
            action: 'click-event',
            name: 'Edit article in shopping cart',
            value: decodeGlobalId(articleId)?.id,
        });
        updateCartItem({
            variables: {
                shoppingCartItemId: shoppingCartItemId,
                licenses: getSelectedLicenseKeys(values.licenses),
                firstPublication: values.specialPublication === 'first',
                exclusivePublication: values.specialPublication === 'exclusive',
            },
            refetchQueries: [
                {
                    query: GQ_RETRIEVE_SHOPPING_CART,
                    variables: {
                        ignoreCache: true,
                        role: [AuthenticationRoleEnum.Owner],
                    },
                },
            ],
        })
            .then(res => {
                log('updateCartItem success', res);
                setArticleUpdated(true);
                setTimeout(handleClose, 1500);
            })
            .catch((err: ApolloError) => {
                error('updateCartItem error', err);
                setSubmitError(err.message);
            });
    };

    // validation schema
    const validationSchema = Yup.object({
        licenses: Yup.array().test(
            'licensesNotEmpty',
            t('addToCartDialog.mustSelectALicense') ?? '',
            hasSelectedLicense,
        ),
    });

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        // enableReinitialize: true
    });

    // form change handler - update price
    useEffect(() => {
        if (!hasSelectedLicense(values.licenses)) {
            setPriceFormatted(PRICE_UNDEFINED);
        } else {
            client
                .query({
                    query: GQ_RETRIEVE_ARTICLE_PRICE_DETAILS,
                    variables: {
                        id: articleId,
                        firstPublication: values.specialPublication === 'first',
                        exclusivePublication: values.specialPublication === 'exclusive',
                        licenses: getSelectedLicenseKeys(values.licenses),
                    },
                    fetchPolicy: 'no-cache', // always refetch price from backend
                })
                .then(res => {
                    const newPrice = res.data?.article?.prices?.slice().shift();
                    log('update price', newPrice);
                    setPriceFormatted(newPrice?.priceFormatted);
                    setSubmitError(null);
                })
                .catch((err: ApolloError) => {
                    error('update price error', err);
                    setSubmitError(err.message);
                });
        }
    }, [values]);

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle>{t('editCartItemDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('editCartItemDialog.description', {title: articleTitle})}</DialogContentText>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl
                            component="fieldset"
                            error={!!errors.licenses}
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <StyledFormLabel component="legend">{t('article.publicationLicense')}</StyledFormLabel>
                            <FormGroup>
                                {values.licenses.map((lcs: LicenseSelection, i: number) => {
                                    return (
                                        <FormControlLabel
                                            key={lcs.key ?? i}
                                            control={
                                                <Checkbox
                                                    checked={lcs.selected}
                                                    onChange={handleChange}
                                                    name={`licenses[${i}].selected`}
                                                />
                                            }
                                            label={lcs.title}
                                            sx={{color: errors.licenses ? colors.red70 : 'inherit'}}
                                        />
                                    );
                                })}
                            </FormGroup>
                            {!!errors.licenses && (
                                <FormHelperText>
                                    <>{errors.licenses}</>
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            component="fieldset"
                            sx={{
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <StyledFormLabel component="legend">
                                {t('article.specialPublicationRights')}
                            </StyledFormLabel>
                            <RadioGroup
                                aria-label="specialPublication"
                                name="specialPublication"
                                value={values.specialPublication}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="none" control={<Radio />} label={t('article.none')} />
                                <FormControlLabel
                                    value="first"
                                    control={<Radio />}
                                    label={t('article.firstPublication')}
                                    disabled={!values.firstPublicationAvailable}
                                />
                                <FormControlLabel
                                    value="exclusive"
                                    control={<Radio />}
                                    label={t('article.exclusivePublication')}
                                    disabled={!values.exclusivePublicationAvailable}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {!!submitError && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{color: colors.red70}}>
                                {submitError}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{textAlign: 'right'}}>
                        <Typography variant="subtitle1" color="textSecondary">
                            {t('article.price')}
                        </Typography>
                        <Typography variant="subtitle1" color={'primary'}>
                            {priceFormatted}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={handleClose} color="secondary" variant="outlined" autoFocus>
                    {t('action.cancel')}
                </StyledDialogButton>
                <StyledDialogButton type="submit" color="primary" variant="contained" autoFocus>
                    {t('action.confirm')}
                </StyledDialogButton>
            </DialogActions>
            <ProgressOverlay
                isOpen={isArticleUpdated}
                state={ProgressState.SUCCESS}
                handleClick={handleClose}
                message={t('editCartItemDialog.articleUpdated')}
            />
        </form>
    );
};

export const EditCartItemDialog: React.FC<EditCartItemDialogProps> = ({
    articleId,
    articleTitle,
    cartItemData,
    open,
    handleClose,
}) => {
    const [initialValues, setInitialValues] = React.useState<FormValues | null>(null);
    const [skip, setSkip] = React.useState(false);
    const {data: articlePriceData} = useQuery(GQ_RETRIEVE_ARTICLE_PRICE_DETAILS, {
        variables: {
            id: articleId,
        },
        fetchPolicy: 'no-cache', // always refetch price from backend
        skip, // fetch only once on initialization
    });

    // fill form with existing cart item data, but create initial values only once on dialog initialization
    useEffect(() => {
        log('EditCartItemDialog', articlePriceData, cartItemData);
        if (articlePriceData && cartItemData) {
            setSkip(true);
            const priceB2B = articlePriceData?.article?.prices?.slice().shift() as ArticlePrice;
            const pubLicensesAvailable = priceB2B?.licenses ?? [];
            const pubLicensesSelected = cartItemData?.licenses ?? [];
            setInitialValues({
                licenses: pubLicensesAvailable.map(availableLcs => {
                    return {
                        ...(availableLcs ?? {id: ''}),
                        selected: !!pubLicensesSelected.filter(selectedLcs => selectedLcs?.key === availableLcs?.key)
                            .length,
                    };
                }),
                firstPublicationAvailable: priceB2B?.rightFirstPublicationAvailable ?? false,
                exclusivePublicationAvailable: priceB2B?.rightExclusivePublicationAvailable ?? false,
                specialPublication: cartItemData.exclusivePublication
                    ? 'exclusive'
                    : cartItemData.firstPublication
                      ? 'first'
                      : 'none',
            });
        }
    }, [articlePriceData, cartItemData]);

    return (
        <Dialog open={open} onClose={handleClose}>
            {initialValues ? (
                <EditCartItemForm
                    initialPrice={cartItemData.priceFormatted ?? PRICE_UNDEFINED}
                    shoppingCartItemId={cartItemData.id}
                    articleId={articleId}
                    articleTitle={articleTitle}
                    initialValues={initialValues}
                    handleClose={handleClose}
                />
            ) : (
                <Preloader />
            )}
        </Dialog>
    );
};
