import gql from 'graphql-tag';
import {
    AddressTypeEnum,
    UpdateUserInput,
    User,
    UserAddress,
    UserContact,
    UserEmail,
    UserLink,
    UserLinkSubtypeEnum,
    UserLinkTypeEnum,
} from '../../../types/graphqlTypes';

const USER_PUBLISHER_PROFILE_FIELDS = `
    id
    firstName
    lastName
    fullName
    contacts(only_main: true, only_public: false) {
        id
        value
    }
    emails(only_main: false, only_public: false) {
        id
        email
        isMain
    }
    links {
        id
        url
        type
    }
    addresses(only_public: false) {
        id
        line1
        line2
        line3
        postcode
        city
        country
        type
    }
`;

export const GQ_RETRIEVE_USER_PUBLISHER_PROFILE = gql`
    query retrieveViewerUser($ignoreCache: Boolean, $role: [AuthenticationRoleEnum]) {
        user(ignoreCache: $ignoreCache, role: $role) {
            ${USER_PUBLISHER_PROFILE_FIELDS}
        }
    }
`;

export const GM_UPDATE_USER_PUBLISHER_PROFILE = gql`
    mutation updateUser(
        $userId: ID!
        $input: UpdateUserInput!
    ) {
        user {
            update(
                userId: $userId
                input: $input
            ) {
                ${USER_PUBLISHER_PROFILE_FIELDS}
            }
        }
    }
`;

export interface UserPublisherProfileData {
    userId?: string;
    firstName?: string | null;
    lastName?: string | null;
    telephone?: UserContact | null;
    email?: UserEmail | null;
    billingEmail?: UserEmail | null;
    website?: UserLink | null;
    postalAddress?: UserAddress | null;
    billingAddress?: UserAddress | null;
}

export const queryDataToUserPublisherProfile = (data: any): UserPublisherProfileData | null => {
    const user: User = data?.user;

    if (!user) {
        return null;
    }

    return {
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        telephone: user.contacts?.slice().shift() as UserContact,
        email: user.emails?.filter(e => !!e?.isMain)?.shift() as UserEmail,
        billingEmail: user.emails?.filter(e => !e?.isMain)?.shift() as UserEmail,
        website: user.links?.filter(l => l?.type?.includes(UserLinkTypeEnum.Business)).shift() as UserLink,
        postalAddress: user.addresses
            ?.filter(a => a?.type === AddressTypeEnum.Postal /* || a?.type === AddressTypeEnum.None*/)
            .shift() as UserAddress,
        billingAddress: user.addresses?.filter(a => a?.type === AddressTypeEnum.Billing).shift() as UserAddress,
    };
};

export const userPublisherProfileToMutationInput = (
    data: UserPublisherProfileData,
    isBillingAddressEnabled = false,
): any => {
    // separate fields
    const {userId, firstName, lastName, telephone, email, billingEmail, website, postalAddress, billingAddress} = data;

    // valid contacts
    const filterContactFields = (telephone?: UserContact | null) => {
        return telephone
            ? {
                  contactId: telephone?.id,
                  number: telephone?.value !== '' ? telephone?.value : null,
                  isMain: true,
                  isPublic: false,
              }
            : undefined;
    };

    // valid emails
    const filterEmailFields = (email?: UserEmail | null, isMain = false, isPublic = false) => {
        return email
            ? {
                  emailId: email?.id,
                  email: email?.email !== '' ? email?.email : null,
                  isMain: isMain,
                  isPublic: isPublic,
              }
            : undefined;
    };

    // valid links
    const filterLinkFields = (website?: UserLink | null) => {
        return website
            ? {
                  linkId: website?.id,
                  url: website?.url !== '' ? website?.url : null,
                  type: UserLinkTypeEnum.Business,
                  subtype: UserLinkSubtypeEnum.Undefined,
              }
            : undefined;
    };

    // helper function to return valid address or undefined
    const filterAddressFields = (addressType: AddressTypeEnum, address?: UserAddress | null, addressEnabled = true) => {
        return address && addressEnabled
            ? {
                  line1: address.line1,
                  line2: address.line2,
                  line3: address.line3,
                  postcode: address.postcode,
                  city: address.city,
                  country: address.country,
                  type: addressType,
                  isPublic: false,
              }
            : {
                  line1: null,
                  line2: null,
                  line3: null,
                  postcode: null,
                  city: null,
                  country: null,
                  type: addressType,
                  isPublic: false,
              };
    };

    return {
        userId: userId,
        input: {
            firstName: firstName ?? null,
            lastName: lastName ?? null,
            contacts: {
                telephone: filterContactFields(telephone),
            },
            emails: {
                email: filterEmailFields(email, true, false),
                billingEmail: filterEmailFields(billingEmail, false, false),
            },
            links: {
                website: filterLinkFields(website),
            },
            addresses: {
                postalAddress: filterAddressFields(AddressTypeEnum.Postal, postalAddress, true),
                billingAddress: filterAddressFields(
                    AddressTypeEnum.Billing,
                    billingAddress,
                    isBillingAddressEnabled ?? false,
                ),
            },
        },
    } as UpdateUserInput;
};
