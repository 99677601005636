import Grid from '@mui/material/Grid';
import React from 'react';
import {theme} from '../../../styles/theme';
import {ParagraphHero as DataType} from '../../../types/graphqlTypes';

interface ParagraphHeaderVideoProps {
    data: DataType;
}

export const ParagraphHeaderVideo: React.FC<ParagraphHeaderVideoProps> = ({data}) => {
    const {headline, subline} = data;

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                {headline && (
                    <h1
                        style={{
                            fontSize: 32,
                            lineHeight: 1.6,
                            fontWeight: 'bold',
                        }}
                    >
                        {headline}
                    </h1>
                )}
                {subline && (
                    <h2
                        style={{
                            fontSize: 22,
                            lineHeight: 1.5,
                            fontWeight: 'bold',
                        }}
                    >
                        {subline}
                    </h2>
                )}
            </Grid>
        </Grid>
    );
};
