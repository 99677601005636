import {useQuery} from '@apollo/client';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EditIcon} from '../../assets/ShoppingCartIcons';
import {Preloader} from '../../components/Preloader/Preloader';
import {UserEditDialog} from '../../components/UserEditDialog/UserEditDialog';
import {
    GQ_RETRIEVE_USER_PUBLISHER_PROFILE,
    queryDataToUserPublisherProfile,
    UserPublisherProfileData,
} from '../../lib/services/polypublisher/userPublisherProfile';
import {StyledContainerWrapper} from '../../styles/simplePage';
import {theme} from '../../styles/theme';
import {AuthenticationRoleEnum} from '../../types/graphqlTypes';

const ContentRow: React.FC<{title: string; value: string}> = ({title, value}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
                <Typography variant="body1" color="textSecondary">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <Typography variant="body1">{value}</Typography>
            </Grid>
        </Grid>
    );
};

const UserPublisherProfileContent: React.FC<{initialValues: UserPublisherProfileData}> = ({initialValues: iv}) => {
    const {t} = useTranslation();
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);

    return (
        <>
            <h1>{t('userPublisherProfile.title')}</h1>
            <ContentRow title={t('userPublisherProfile.firstName')} value={iv.firstName ?? ''} />
            <ContentRow title={t('userPublisherProfile.lastName')} value={iv.lastName ?? ''} />
            <ContentRow title={t('userPublisherProfile.email')} value={iv.email?.email ?? ''} />
            <ContentRow title={t('userPublisherProfile.billingEmail')} value={iv.billingEmail?.email ?? ''} />
            <ContentRow title={t('userPublisherProfile.website')} value={iv.website?.url ?? ''} />
            <ContentRow title={t('userPublisherProfile.telephone')} value={iv.telephone?.value ?? ''} />
            <h3>{t('userPublisherProfile.address')}</h3>
            <ContentRow title={t('userPublisherProfile.line1')} value={iv.postalAddress?.line1 ?? ''} />
            <ContentRow title={t('userPublisherProfile.line2')} value={iv.postalAddress?.line2 ?? ''} />
            <ContentRow title={t('userPublisherProfile.line3')} value={iv.postalAddress?.line3 ?? ''} />
            <ContentRow title={t('userPublisherProfile.postcode')} value={iv.postalAddress?.postcode ?? ''} />
            <ContentRow title={t('userPublisherProfile.city')} value={iv.postalAddress?.city ?? ''} />
            {iv.billingAddress && (
                <>
                    <h3>{t('userPublisherProfile.billingAddress')}</h3>
                    <ContentRow title={t('userPublisherProfile.line1')} value={iv.billingAddress?.line1 ?? ''} />
                    <ContentRow title={t('userPublisherProfile.line2')} value={iv.billingAddress?.line2 ?? ''} />
                    <ContentRow title={t('userPublisherProfile.line3')} value={iv.billingAddress?.line3 ?? ''} />
                    <ContentRow title={t('userPublisherProfile.postcode')} value={iv.billingAddress?.postcode ?? ''} />
                    <ContentRow title={t('userPublisherProfile.city')} value={iv.billingAddress?.city ?? ''} />
                </>
            )}
            <Grid container justifyContent={'center'} sx={{marginTop: theme.spacing(4)}}>
                <Tooltip title={t('action.edit') as string}>
                    <IconButton onClick={() => setEditDialogOpen(true)} color={'primary'} size="large">
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            {isEditDialogOpen && (
                <UserEditDialog
                    open={isEditDialogOpen}
                    handleClose={() => setEditDialogOpen(false)}
                    initialValues={iv}
                />
            )}
        </>
    );
};

export const UserPublisherProfilePage: React.FC = () => {
    const {loading, data, error} = useQuery(GQ_RETRIEVE_USER_PUBLISHER_PROFILE, {
        variables: {
            ignoreCache: true,
            role: [AuthenticationRoleEnum.Owner],
        },
    });
    const userData = queryDataToUserPublisherProfile(data);

    return (
        <StyledContainerWrapper maxWidth={800}>
            {loading && <Preloader />}
            {error && <div>{error?.message}</div>}
            {!!userData && <UserPublisherProfileContent initialValues={userData} />}
        </StyledContainerWrapper>
    );
};
