import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import {ParagraphText as DataType} from '../../../types/graphqlTypes';

interface ParagraphTextProps {
    data: DataType;
}

export const ParagraphText: React.FC<ParagraphTextProps> = ({data}) => {
    const {marketing, funding, html} = data;

    if (marketing == true || funding == true) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent={'center'}
            sx={{
                fontSize: 18,
                lineHeight: 1.8,
                wordBreak: 'break-word',
            }}
        >
            <Grid item xs={12} md={9} lg={6}>
                {html && (
                    <Box
                        dangerouslySetInnerHTML={{__html: html}}
                        sx={{
                            '& a': {
                                textDecoration: 'underline',
                            },
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};
