import {ApolloClient, useApolloClient} from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {useFormik} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {ROUTE_AGB} from '../../../lib/constants';
import {GQ_RETRIEVE_ARTICLE_DETAIL} from '../../../lib/services/polypublisher/article';
import {formatPrice} from '../../../lib/utils/formatString';
import {err} from '../../../lib/utils/logger';
import {getVat} from '../../../lib/utils/vat';
import {colors, theme} from '../../../styles/theme';
import {Article, Query, ShoppingCart} from '../../../types/graphqlTypes';

interface CheckoutDialogProps {
    open: boolean;
    handleClose(): void;
    handleCheckout(): void;
    cartData: ShoppingCart;
}

const StyledTableCell = styled(TableCell)(() => ({
    border: 0,
}));

interface ItemTableDataRow {
    itemId: string;
    title: string | null;
    licenses: string | null;
    price: string | null;
}

interface ItemTableData {
    rows?: ItemTableDataRow[];
}

function createItemTableDataRow(
    itemId: string,
    title: string | null,
    licenses: string | null,
    price: string | null,
): ItemTableDataRow {
    return {itemId, title, licenses, price};
}

/**
 * create structured table data from cart items
 * @param client
 * @param cartData
 */
function createItemTableData(client: ApolloClient<any>, cartData: ShoppingCart): ItemTableData {
    const rows = cartData?.cartItems?.map(item => {
        let cachedQueryData: Query | null = null;
        try {
            // read article data from cache
            cachedQueryData = client.readQuery({
                query: GQ_RETRIEVE_ARTICLE_DETAIL,
                variables: {id: item?.articleId || '-1'},
            });
        } catch (error: any) {
            // data not in cache
            err(error.message);
        }
        const articleData: Article | null | undefined = cachedQueryData?.article;
        const licenses = item?.licenses?.map(l => l?.key) ?? [];
        if (item?.exclusivePublication) {
            licenses.push('EXCLUSIVE');
        } else if (item?.firstPublication) {
            licenses.push('FIRST');
        }
        return createItemTableDataRow(
            item?.id ?? '',
            articleData?.title ?? '',
            licenses.join(', '),
            item?.priceFormatted ?? '',
        );
    });

    return {rows: rows?.reverse()};
}

/**
 * Table displaying cart items
 * @param itemTableData
 * @constructor
 */
const ItemTable: React.FC<{itemTableData: ItemTableData}> = ({itemTableData}) => {
    return (
        <TableContainer sx={{margin: theme.spacing(2, 0)}}>
            <Table aria-label="checkout items">
                <TableHead>
                    <TableRow>
                        <TableCell>Titel</TableCell>
                        <TableCell>Lizenzen</TableCell>
                        <TableCell align="right">Preis</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {itemTableData.rows?.map(row => (
                        <TableRow key={row.itemId}>
                            <TableCell component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell>{row.licenses}</TableCell>
                            <TableCell align="right">{row.price}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

/**
 * Table displaying price summary details
 * @param netPrice
 * @param grossPrice
 * @param vat
 * @constructor
 */
const PriceSummaryTable: React.FC<{netPrice: string; grossPrice: string; vat: string}> = ({
    netPrice,
    grossPrice,
    vat,
}) => {
    const {t} = useTranslation();
    const vatRate = getVat();

    return (
        <TableContainer sx={{margin: theme.spacing(2, 0)}}>
            <Table aria-label="checkout price" size="small">
                <TableBody>
                    <TableRow key="netPrice">
                        <StyledTableCell>{t('shoppingCart.netPrice')}</StyledTableCell>
                        <StyledTableCell align="right">{netPrice}</StyledTableCell>
                    </TableRow>
                    <TableRow key="vat">
                        <StyledTableCell>{t('shoppingCart.vat', {vatRate: vatRate.label})}</StyledTableCell>
                        <StyledTableCell align="right">{vat}</StyledTableCell>
                    </TableRow>
                    <TableRow key="grossPrice">
                        <StyledTableCell sx={{fontWeight: 'bold'}}>{t('shoppingCart.grossPrice')}</StyledTableCell>
                        <StyledTableCell align="right" sx={{fontWeight: 'bold'}}>
                            {grossPrice}
                        </StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

interface FormValues {
    termsOfService: boolean;
}

/**
 * The checkout confirmation dialog
 * @param open
 * @param handleClose
 * @param handleCheckout
 * @param cartData
 * @constructor
 */
export const CheckoutDialog: React.FC<CheckoutDialogProps> = ({open, handleClose, handleCheckout, cartData}) => {
    const {t} = useTranslation();
    const client = useApolloClient();
    const itemData = createItemTableData(client, cartData);
    const netPrice = cartData.cartItems?.reduce((acc, item) => acc + (item?.price ?? 0), 0) ?? 0;
    const vatRate = getVat();
    const vat = netPrice * vatRate.value;
    const grossPrice = netPrice + vat;

    const initialValues: FormValues = {
        termsOfService: false,
    };

    // validation schema
    const validationSchema = Yup.object({
        termsOfService: Yup.bool().oneOf([true], t('formValidation.required') ?? ''),
    });

    // formik form controllers
    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues,
        onSubmit: handleCheckout,
        validationSchema,
    });

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{t('shoppingCart.confirmCheckoutTitle')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('shoppingCart.confirmCheckoutMessage1')}</Typography>
                    <ItemTable itemTableData={itemData} />
                    <PriceSummaryTable
                        netPrice={formatPrice(netPrice)}
                        grossPrice={formatPrice(grossPrice)}
                        vat={formatPrice(vat)}
                    />
                    <Box>
                        <FormControlLabel
                            key={'termsOfService'}
                            control={
                                <Checkbox
                                    checked={values.termsOfService}
                                    onChange={handleChange}
                                    name={'termsOfService'}
                                />
                            }
                            label={
                                <p>
                                    Hiermit bestätige ich, dass ich die{' '}
                                    <a href={ROUTE_AGB} target="_blank" rel="noopener noreferrer">
                                        AGB
                                    </a>{' '}
                                    gelesen und akzeptiert habe.
                                </p>
                            }
                            sx={{color: errors.termsOfService ? colors.red70 : 'inherit'}}
                        />
                        {!!errors.termsOfService && (
                            <FormHelperText sx={{colors: colors.red70}}>{errors.termsOfService}</FormHelperText>
                        )}
                    </Box>
                    <Box sx={{margin: theme.spacing(2, 0)}}>
                        <Typography>{t('shoppingCart.confirmCheckoutMessage2')}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                        sx={{margin: theme.spacing(0, 2, 2, 0)}}
                    >
                        {t('action.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        autoFocus
                        sx={{margin: theme.spacing(0, 2, 2, 0)}}
                    >
                        {t('action.checkoutNow')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
