import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface PreloaderProps {
    minWidth?: number | string;
    minHeight?: number | string;
}

export const Preloader: React.FC<PreloaderProps> = ({minWidth = 50, minHeight = 50}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                minWidth: minWidth,
                minHeight: minHeight,
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 0,
                padding: 20,
            }}
        >
            <CircularProgress />
        </Box>
    );
};
