import {useQuery} from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {ReactNode, useState} from 'react';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {BackArrowIcon} from '../../assets/ArticleDetailIcons';
import {ArticleDownloadIcon} from '../../assets/ShoppingCartIcons';
import {ArticleDownloadDialog} from '../../components/articleDetail/ArticleDownloadDialog/ArticleDownloadDialog';
import {ParagraphAccordion} from '../../components/articleDetail/ParagraphAccordion/ParagraphAccordion';
import {ParagraphFixedBackground} from '../../components/articleDetail/ParagraphFixedBackground/ParagraphFixedBackground';
import {ParagraphHeaderVideo} from '../../components/articleDetail/ParagraphHeaderVideo/ParagraphHeaderVideo';
import {ParagraphHero} from '../../components/articleDetail/ParagraphHero/ParagraphHero';
import {ParagraphMultiMedia} from '../../components/articleDetail/ParagraphMultiMedia/ParagraphMultiMedia';
import {ParagraphOembed} from '../../components/articleDetail/ParagraphOembed/ParagraphOembed';
import {ParagraphVoiceflow} from '../../components/articleDetail/ParagraphVoiceflow/ParagraphVoiceflow';
import {ParagraphQuote} from '../../components/articleDetail/ParagraphQuote/ParagraphQuote';
import {ParagraphText} from '../../components/articleDetail/ParagraphText/ParagraphText';
import {ParagraphTldr} from '../../components/articleDetail/ParagraphTldr/ParagraphTldr';
import {Preloader} from '../../components/Preloader/Preloader';
import {AddToCartDialog} from '../../components/shoppingCart/AddToCartDialog/AddToCartDialog';
import {ROUTE_SHOPPING_CART} from '../../lib/constants';
import {getAuthors, GQ_RETRIEVE_ARTICLE_DETAIL} from '../../lib/services/polypublisher/article';
import {GQ_RETRIEVE_SHOPPING_CART} from '../../lib/services/polypublisher/shoppingCart';
import {useMatomoTracking} from '../../lib/tracking/matomo';
import {formatDateString, formatDateTimeString} from '../../lib/utils/formatString';
import {colors, theme} from '../../styles/theme';
import {
    Article,
    AuthenticationRoleEnum,
    LocaleEnum,
    Paragraph,
    ParagraphTypeEnum,
    ShoppingCartItem,
} from '../../types/graphqlTypes';

const getRenderedParagraph = (data: Paragraph | null, key: number): ReactNode | null => {
    if (data) {
        switch (data.type) {
            case ParagraphTypeEnum.Hero:
                return <ParagraphHero data={data} key={key} />;
            case ParagraphTypeEnum.Text:
                return <ParagraphText data={data} key={key} />;
            case ParagraphTypeEnum.Oembed:
                return <ParagraphOembed data={data} key={key} />;
            case ParagraphTypeEnum.Voiceflow:
                return <ParagraphVoiceflow data={data} key={key} />;
            case ParagraphTypeEnum.Quote:
                return <ParagraphQuote data={data} key={key} />;
            case ParagraphTypeEnum.Accordion:
                return <ParagraphAccordion data={data} key={key} />;
            case ParagraphTypeEnum.Tldr:
                return <ParagraphTldr data={data} key={key} />;
            case ParagraphTypeEnum.FixedBackground:
                return <ParagraphFixedBackground data={data as any} key={key} />;
            case ParagraphTypeEnum.MultiMedia:
                return <ParagraphMultiMedia data={data} key={key} />;
            case ParagraphTypeEnum.HeaderVideo:
                return <ParagraphHeaderVideo data={data} key={key} />;
        }
    }
    return null;
};

const ArticleBuyButton: React.FC<{articleId: string; handleAddToCart(): void; handleGoToCart(): void}> = ({
    articleId,
    handleAddToCart,
    handleGoToCart,
}) => {
    const {t} = useTranslation();
    const {data} = useQuery(GQ_RETRIEVE_SHOPPING_CART, {
        variables: {
            ignoreCache: true,
            role: [AuthenticationRoleEnum.Owner],
        },
    });
    const cartItems = data?.user?.openShoppingCart.cartItems as ShoppingCartItem[];

    const isInShoppingCart = cartItems?.filter(item => item.articleId === articleId).length > 0;

    return isInShoppingCart ? (
        <Button variant="contained" color="primary" onClick={handleGoToCart} sx={{marginRight: theme.spacing(2)}}>
            {t('action.goToCart')}
        </Button>
    ) : (
        <Button variant="contained" color="primary" onClick={handleAddToCart} sx={{marginRight: theme.spacing(2)}}>
            {t('action.addToCart')}
        </Button>
    );
};
const InternalArticleView: React.FC<{articleData: Article}> = ({articleData}) => {
    const {t} = useTranslation();
    const price = articleData?.prices?.slice().shift();
    const [isDownloadDialogVisible, setDownloadDialogVisible] = useState(false);
    const [isAddToCartDialogVisible, setAddToCartDialogVisible] = useState(false);
    const navigate = useNavigate();
    const {trackEvent} = useMatomoTracking();
    const authors = getAuthors(articleData);

    const handleToggleDownloadDialog = () => {
        if (!isDownloadDialogVisible) {
            trackEvent({
                category: 'polymarket-articledetail',
                action: 'click-event',
                name: 'Open Download Dialog',
            });
        }
        setDownloadDialogVisible(!isDownloadDialogVisible);
    };

    const handleToggleAddToCartDialog = () => {
        if (!isAddToCartDialogVisible) {
            trackEvent({
                category: 'polymarket-article',
                action: 'click-event',
                name: 'Open AddToCart Dialog',
            });
        }
        setAddToCartDialogVisible(!isAddToCartDialogVisible);
    };

    const handleGoToCart = () => {
        navigate(ROUTE_SHOPPING_CART);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{paddingBottom: theme.spacing(3)}}>
                    <Link
                        onClick={() => navigate(-1)}
                        sx={{
                            cursor: 'pointer',
                            verticalAlign: 'sub',
                            paddingRight: theme.spacing(2),
                            textTransform: 'uppercase',
                            '& span': {
                                paddingLeft: theme.spacing(2),
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        <BackArrowIcon sx={{verticalAlign: 'bottom'}} />
                        <span>{t('action.back')}</span>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {authors.isMultiple ? t('article.authors') : t('article.author')}
                            </Typography>
                            <Typography variant="body1">{authors.namesFormatted ?? 'unbekannt'}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.characters')}
                            </Typography>
                            <Typography variant="body1">
                                {articleData?.characters ?? t('article.noCharLength')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.datePublication')}
                            </Typography>
                            <Typography variant="body1">
                                {formatDateString(articleData?.publication?.datePublication) ??
                                    t('article.noDatePublication')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.dateModification')}
                            </Typography>
                            <Typography variant="body1">
                                {formatDateString(
                                    articleData?.dateModified ?? articleData?.publication?.datePublication,
                                ) ?? t('article.noModification')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.resort')}
                            </Typography>
                            <Typography variant="body1">
                                {articleData?.resorts
                                    ?.map(r => {
                                        // return !r?.doNotList ? r?.title : null
                                        return r?.title;
                                    })
                                    ?.join(', ') ?? t('article.noResort')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.vgwort')}
                            </Typography>
                            {articleData?.owner?.vgwort &&
                            articleData?.owner?.vgwort?.number &&
                            !articleData?.owner?.vgwort?.excluded ? (
                                <Typography variant="body1">
                                    {articleData?.owner?.vgwort?.lastname
                                        ? articleData?.owner?.vgwort?.firstname +
                                          ' ' +
                                          articleData?.owner?.vgwort?.lastname
                                        : articleData?.owner?.fullName}
                                    : {articleData?.owner?.vgwort?.number}
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    {articleData?.owner?.vgwort?.excluded
                                        ? t('article.vgwortExcluded')
                                        : t('article.vgwortNoData')}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.dateEmbargo')}
                            </Typography>
                            <Typography variant="body1">
                                {articleData?.publication?.dateEmbargo ? (
                                    <strong style={{color: colors.red70}}>
                                        {formatDateTimeString(articleData?.publication?.dateEmbargo)}
                                    </strong>
                                ) : (
                                    t('article.noDateEmbargo')
                                )}
                            </Typography>
                            {articleData?.publication?.allowOwnPrePublication && (
                                <Typography variant="body1">{t('article.allowOwnPrePublication')}</Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.publicationInfo')}
                            </Typography>
                            <Typography variant="body1">
                                {articleData?.publication?.infoPublication ?? t('article.publicationInfoNoData')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{paddingBottom: theme.spacing(2)}}>
                            <Typography variant="body1" color="textSecondary">
                                {t('article.publicationOther')}
                            </Typography>
                            <Typography variant="body1">
                                {articleData?.publication?.otherPublication ?? t('article.publicationOtherNoData')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Grid item xs={12} md={4} lg={6} sx={{paddingBottom: theme.spacing(2)}}>
                    <Typography variant="body1" color="textSecondary">
                        {t('articleDetail.availablePublicationRights')}
                    </Typography>
                    <Typography variant="body1" style={{paddingRight: '0.5rem'}}>
                        {price?.licenses?.map(l => l?.title).join(', ') ??
                            t('articleDetail.noAvailablePublicationRights')}
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <Typography variant="body1" color="textSecondary">
                        {t('article.priceFactor')}
                    </Typography>
                    {price?.priceFactor === null ? (
                        <Typography variant="body1">{t('article.priceFactorFixed')}</Typography>
                    ) : price?.priceFactor === 1 ? (
                        <Typography variant="body1">{t('article.priceFactorStandard')}</Typography>
                    ) : (
                        <Typography variant="subtitle1" color={'primary'}>
                            {(price?.priceFactor ?? 1) * 100 > 100
                                ? '+ ' + ((price?.priceFactor ?? 1) * 100 - 100) + '%'
                                : '- ' + (100 - (price?.priceFactor ?? 1) * 100) + '%'}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} md={4} lg={3} sx={{paddingBottom: theme.spacing(2)}}>
                    <Typography variant="body1" color="textSecondary">
                        {t('article.price')}
                    </Typography>
                    <Typography variant="subtitle1" color={'primary'}>
                        {price?.priceFormatted ?? t('article.noPrice')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'center'}
                sx={{paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2)}}
            >
                <Tooltip title={t('action.download') as string}>
                    <IconButton onClick={handleToggleDownloadDialog} color={'primary'} size="large">
                        <ArticleDownloadIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid container justifyContent={'center'}>
                <ArticleBuyButton
                    articleId={articleData.id}
                    handleAddToCart={handleToggleAddToCartDialog}
                    handleGoToCart={handleGoToCart}
                />
            </Grid>
            <article>
                {articleData.paragraphs?.map((p: Paragraph | null, i: number) => getRenderedParagraph(p, i))}
            </article>
            <ArticleDownloadDialog
                open={isDownloadDialogVisible}
                articleId={articleData.id}
                handleClose={handleToggleDownloadDialog}
            />
            {isAddToCartDialogVisible && (
                <AddToCartDialog
                    open={isAddToCartDialogVisible}
                    articleData={articleData}
                    handleClose={handleToggleAddToCartDialog}
                />
            )}
        </>
    );
};

export const ArticleDetailPage: React.FC = () => {
    const {locale, articleId} = useParams<{locale: string; articleId: string}>();
    const localeEnum: LocaleEnum = locale === 'de' ? LocaleEnum.DeDe : LocaleEnum.EnGb;
    const {loading, data, error} = useQuery(GQ_RETRIEVE_ARTICLE_DETAIL, {
        variables: {
            id: articleId || '-1',
            locale: localeEnum,
        },
    });
    const articleData = data?.article;

    return (
        <>
            {loading && <Preloader />}
            {error && <div>{error?.message}</div>}
            {!!articleData && (
                <>
                    <Helmet>{<title>{articleData.title} | RiffReporter</title>}</Helmet>
                    <InternalArticleView articleData={articleData} />
                </>
            )}
        </>
    );
};
