import {ApolloError} from '@apollo/client';
import {Location} from 'history';
import {AuthenticationRoleEnum} from '../../../types/graphqlTypes';
import {AppState, DispatchThunkAny} from '../../../types/redux';
import {LOCAL_KEY_TOKEN, ROUTE_LOGOUT, SESSION_KEY_APP_STATE} from '../../constants';
import {getApolloClient} from '../../services/polypublisher/initClient';
import {
    applyForPublisherAccount,
    fetchUserStatus,
    loginWithCredentials,
    logout,
    startCheckUserStatusSubscription,
    stopCheckUserStatusSubscription,
} from '../../services/polypublisher/user';
import {err, log} from '../../utils/logger';
import {loadLocalData, removeLocalData, removeSessionData, saveLocalData} from '../../utils/storage';
import {addNotification} from './notificationActions';

export const USER_LOGIN_ATTEMPT = 'USER_LOGIN_ATTEMPT';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SESSION_EXPIRED = 'USER_SESSION_EXPIRED';

/**
 * Perform user login mutation with backend, store auth token, update application state,
 * @param username
 * @param password
 */
export function loginUser(username: string, password: string): (dispatch: DispatchThunkAny, getState: any) => void {
    return async (dispatch: DispatchThunkAny, getState: () => AppState) => {
        try {
            dispatch({
                type: USER_LOGIN_ATTEMPT,
            });
            // login via credentials, retrieve token
            const authToken = await loginWithCredentials(username, password);

            if (!authToken) {
                throw new Error('Login error: auth token missing.');
            }

            // save token
            saveLocalData(LOCAL_KEY_TOKEN, authToken);

            // reset apollo store
            await getApolloClient().resetStore();

            // get user information
            const {user: userId, role} = await fetchUserStatus();

            if (!userId) {
                throw new Error('Login error: user ID missing.');
            } else if (role === AuthenticationRoleEnum.Anonymous) {
                throw new Error('Login error: anonymous user.');
            }

            if (role) {
                if (
                    role === AuthenticationRoleEnum.Root ||
                    role === AuthenticationRoleEnum.Publisher ||
                    role === AuthenticationRoleEnum.Administrator
                ) {
                    // spread the good news
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: {authToken, userId},
                    });
                } else {
                    throw new Error('Login error: no access rights.');
                }
            }

            startCheckUserStatusSubscription(dispatch, getState);
        } catch (error) {
            err('Error while logging in', error);
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: (error as ApolloError).message,
            });
        }
    };
}

export function autologinUser(redirectLocation?: Location): (dispatch: DispatchThunkAny, getState: any) => void {
    return async (dispatch: DispatchThunkAny, getState: () => AppState) => {
        try {
            const authToken = loadLocalData(LOCAL_KEY_TOKEN);

            if (!authToken) {
                return;
            }

            dispatch({
                type: USER_LOGIN_ATTEMPT,
                payload: redirectLocation,
            });

            // reset apollo store
            await getApolloClient().resetStore();

            // get user information
            const userStatus = await fetchUserStatus();
            log('userStatus', userStatus);

            if (!userStatus.user) {
                log('autologin error: user ID missing.');
                return;
            } else if (userStatus.role === AuthenticationRoleEnum.Anonymous) {
                log('autologin error: anonymous user.');
                return;
            }

            if (userStatus.role) {
                if (
                    userStatus.role === AuthenticationRoleEnum.Root ||
                    userStatus.role === AuthenticationRoleEnum.Publisher ||
                    userStatus.role === AuthenticationRoleEnum.Administrator
                ) {
                    // spread the good news
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: {authToken, userId: userStatus.user},
                    });
                } else {
                    throw new Error('Login error: no access rights.');
                }
            }

            startCheckUserStatusSubscription(dispatch, getState);
        } catch (error) {
            err('Error while logging in', error);
            dispatch({
                type: USER_LOGIN_ERROR,
                payload: (error as ApolloError).message,
            });
        }
    };
}

/**
 * Check the user status on initialization (catch sessions saved in the browser but timed out in the backend)
 */
export function initUser(): (dispatch: DispatchThunkAny, getState: any) => void {
    return async (dispatch: DispatchThunkAny, getState: () => AppState) => {
        startCheckUserStatusSubscription(dispatch, getState);
    };
}

/**
 * Perform user logout mutation, reset application state and apollo cache
 */
export function logoutUser(
    logoutReason: typeof USER_LOGOUT | typeof USER_SESSION_EXPIRED = USER_LOGOUT,
): (dispatch: DispatchThunkAny, getState: any) => void {
    return async (dispatch: DispatchThunkAny) => {
        try {
            log('logoutUser');
            stopCheckUserStatusSubscription();
            if (logoutReason === USER_LOGOUT) {
                await logout();
            }
            removeLocalData(LOCAL_KEY_TOKEN);
            dispatch({
                type: logoutReason,
            });
            window.location.href = ROUTE_LOGOUT;
            removeSessionData(SESSION_KEY_APP_STATE);
        } catch (error) {
            err('Error while logging out', error);
        }
    };
}

export function applyForPublisher(
    name: string,
    email: string,
    publisherTypes: string[],
): (dispatch: DispatchThunkAny, getState: any) => Promise<boolean> {
    return async (dispatch: DispatchThunkAny) => {
        try {
            const note = publisherTypes.join(', ');
            const res = await applyForPublisherAccount(name, email, note, 'applyForPublisherAccount');
            return res.user.requestCallback;
        } catch (error) {
            err('Error while sending applyForPublisher form', error);
            dispatch(
                addNotification(
                    'Ein Anwendungsfehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.',
                    'error',
                ),
            );
        }

        return false;
    };
}
